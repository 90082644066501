import React from "react";
import {
    Field,
    Tag,
    TagPicker,
    TagPickerControl,
    TagPickerGroup,
    TagPickerInput,
    TagPickerList,
    TagPickerOption,
    TagPickerProps
} from "@fluentui/react-components";

import {TagPickerProp} from "./TagPickerProp";
import {observer} from "mobx-react-lite";

/**
 * Компонент выбора нескольких значений в Select
 * @constructor
 */
export const MultipleTagPicker: React.FC<TagPickerProp> = observer((props) => {

    // Установление вариантов выбора
    const tagPickerOptions = props.options.filter(
        (option) => !props.selected.includes(option)
    );

    // Обработчик изменения выбора
    const onOptionSelect: TagPickerProps["onOptionSelect"] = (_e, data) => {
        const newSelectedOptions = data.selectedOptions;
        if (props.onChange) {
            props.onChange(newSelectedOptions);
        }
    };

    return (
        <Field label={props.labelText} style={{maxWidth: 400}} size={"small"}>
            <TagPicker onOptionSelect={onOptionSelect} selectedOptions={props.selected}>
                <TagPickerControl>
                    <TagPickerGroup>
                        {props.selected.map((option) => (
                            <Tag
                                size={"small"}
                                key={option}
                                shape="rounded"
                                value={option}
                            >
                                {option}
                            </Tag>
                        ))}
                    </TagPickerGroup>
                    <TagPickerInput/>
                </TagPickerControl>
                <TagPickerList>
                    {tagPickerOptions.length > 0
                        ? tagPickerOptions.map((option) => (
                            <TagPickerOption value={option} key={option}>
                                {option}
                            </TagPickerOption>
                        ))
                        : "No options available"}
                </TagPickerList>
            </TagPicker>
        </Field>
    );
});

MultipleTagPicker.displayName = "MultipleTagPicker";

export default MultipleTagPicker;
