export enum BuildRequestStatesDto {
    /**
     *  Waiting for processing
     */
    Waiting,

    /**
     *  File processing now
     */
    Processing,

    /**
     *  File processing successfully
     */
    Done,

    /**
     File processing done with errors
     */
    Error,

    /**
     *  Nothing is chosen
     */
    None
}