import React from "react";
import {Layout} from "../../../containers/Layout";
import {FeatureList} from "../../../components/FeatureList";

/**
 * Страница отображения логов приложения
 * @constructor
 */
const FeaturesPage: React.FC = () => {
    return <Layout pageTittle={"Features"} anotherBackground={true}>
        <FeatureList/>
    </Layout>
}

/**
 * Имя отображаемое при отладке
 */
FeaturesPage.displayName = "FeaturesPage";
export default FeaturesPage;