import {AxiosService} from "./axios";
import {NoteCreateDto} from "../models/common";

/**
 * Сервис работы с заметками логов
 */
export class LogNoteService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Создание заметки к логу
     */
    async create(note: NoteCreateDto): Promise<void> {
        try {
            await this.axios.post<void>("/notes/add", note);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удаление заметки
     */
    async delete(noteId: number): Promise<void> {
        try {
            await this.axios.delete<void>(`/notes/delete/${noteId}`);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}