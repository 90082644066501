import React, {useEffect} from "react";
import {LoadingButtonProps} from "./LoadingButtonProps";
import {LoadingState} from "../../models";
import {Button, Spinner} from "@fluentui/react-components";
import {CheckmarkFilled, ErrorCircleRegular} from "@fluentui/react-icons";

/**
 * Кнопка с возможностью отображения статуса загрузки
 */
const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
    /**
     * Состояние загрузки
     */
    const [loadingState, setLoadingState] = React.useState<LoadingState>("initial");

    /**
     * Таймер для автоматического сброса состояния
     */
    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;

        if (loadingState === "loaded" || loadingState === "error") {
            timer = setTimeout(() => setLoadingState("initial"), props.resetTimeout || 3000);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [loadingState, props.resetTimeout]);

    /**
     * Обёртка для безопасного вызова обработчика клика и обновления состояния
     */
    const invokeOnClick = () => {
        setLoadingState("loading");
        if (props.onClick) {
            try {
                props.onClick();
                setLoadingState("loaded");
            } catch (error) {
                console.error("OnClick error:", error);
                setLoadingState("error");
            }
        } else {
            console.error("OnClick is empty");
            setLoadingState("error");
        }
    };

    /**
     * Выбор иконки согласно состоянию
     */
    const buttonIcon = () => {
        switch (loadingState) {
            case "initial":
                return props.icon;
            case "loading":
                return <Spinner size="tiny"/>;
            case "loaded":
                return <CheckmarkFilled/>;
            case "error":
                return <ErrorCircleRegular/>;
            default:
                return null;
        }
    };

    return (
        <Button
            icon={buttonIcon()}
            appearance={props.appearance}
            size={props.size}
            style={props.style}
            disabled={!props.enabled || loadingState === "loading"}
            onClick={invokeOnClick}
        >
            {props.buttonText}
        </Button>
    );
}

/**
 * Имя компонента отображаемое при отладке
 */
LoadingButton.displayName = "LoadingButton";

export default LoadingButton;