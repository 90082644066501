import React from "react";
import {Layout} from "../../../containers/Layout";
import {AppLogsList} from "../../../components/AppLogsList";

/**
 * Страница отображения логов приложения
 * @constructor
 */
const AppLogPage: React.FC = () => {
    return <Layout pageTittle={"App Logs"} anotherBackground={true}>
        <AppLogsList/>
    </Layout>
}

/**
 * Имя отображаемое при отладке
 */
AppLogPage.displayName = "AppLogPage";
export default AppLogPage;