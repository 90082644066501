import {AxiosService} from "./axios";
import {JsonProfilePageDto} from "../models/admin";
import {ADMIN_JSON_PROFILE_OPEN_URL, ADMIN_JSON_PROFILES_URL} from "../shared/constUrl";
import {JsonProfile} from "../models/common";

export class AdminJsonProfilesService {

    /**
     * Axios сервис
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список профилей
     */
    async getList(): Promise<JsonProfilePageDto> {
        try {
            const response = await this.axios.get("/admin/json-profiles");
            return Promise.resolve(response.data as JsonProfilePageDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Загрузка файла
     */
    async uploadFile(formData: FormData, userId: string): Promise<void> {
        try {
            await this.axios.uploadFile("/admin/json-profiles/upload/" + userId, formData);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запрос профиля пользователя
     * @param userId Идентификатор пользователя
     */
    async loadProfile(userId: string): Promise<JsonProfile> {
        try {
            const response = await this.axios.get<JsonProfile>(`${ADMIN_JSON_PROFILE_OPEN_URL}/${userId}`);
            return Promise.resolve(response.data as JsonProfile);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async saveProfile(userId: string, profile: JsonProfile): Promise<void> {
        try {
            await this.axios.post(`${ADMIN_JSON_PROFILES_URL}/update/${userId}`, profile);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
