import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    cardContainer: {
        minWidth: "200px",
        maxWidth: "400px",
        height: "fit-content",
        marginTop: "1px",
    },
    titleWithButton: {
        display: "flex",
        justifyContent: "space-between",
    },
    countryItem: {
        display: "flex",
        alignItems: "center",
        gap: '1em'
    }
});