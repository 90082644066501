import {AxiosService} from "./axios";
import {DictDto} from "../models/admin/adminDashboard/dictDto";
import {dictionaryDto} from "../models/admin";
import {BrowserWithTypeDto} from "../models/common/dictionaries/BrowserWithTypeDto";

export class DictService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Адрес для получения данных по словарям
     * @private
     */
    private baseUrlAdmin: string = '/admin/dictionaries';

    private baseUrl: string = '/dictionaries';


    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить словари в системе
     */
    async getDict(): Promise<DictDto[]> {
        try {
            const response = await this.axios.get<DictDto[]>(this.baseUrlAdmin)
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Получить список браузеров
     */
    async getBrowserDict(): Promise<BrowserWithTypeDto[]> {
        try {
            const response = await this.axios.get<BrowserWithTypeDto[]>(`${this.baseUrl}/browsers`);
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async addNewBrowser(publicName: string): Promise<dictionaryDto> {
        try {
            return {id: 99, publicName: "publicName"}
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}