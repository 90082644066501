// AvList.tsx
import React, {useEffect, useState} from "react";
import {Button, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow,} from "@fluentui/react-components";
import ActionDialog from "../ActionDialog";
import {useServices} from "../../hooks";

interface AvListProps {
    onClose: () => void; // колбэк, чтобы сказать AvStatusButton «я закрылась»
    brokerId: number;
}

/**
 * Компонент отображающий результаты сканирования
 */
const AvList: React.FC<AvListProps> = ({onClose, brokerId}) => {
    const [isOpen, setIsOpen] = useState(true);
    const [avResults, setAvResults] = useState<any[]>([]);
    const services = useServices();

    useEffect(() => {
        services.dataBrokerService.getAvResult(brokerId).then((data) => {
            setAvResults(data);
        });
    }, [services.dataBrokerService, brokerId]);

    // Закрытие диалога
    const handleClose = () => {
        setIsOpen(false);
        onClose();
    };

    // Сортируем массив перед выводом: сначала те, у которых есть детект
    // (isClean === false и detectMessage), затем все остальные.
    const sortedResults = [...avResults].sort((a, b) => {
        const aDetected = !a.isClean && a.detectMessage;
        const bDetected = !b.isClean && b.detectMessage;

        if (aDetected && !bDetected) {
            return -1; // a вверху
        }
        if (!aDetected && bDetected) {
            return 1;  // b вверху
        }
        return 0;      // равны, порядок не меняем
    });

    const dialogContent = (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>AV name</TableHeaderCell>
                    <TableHeaderCell>Result</TableHeaderCell>
                    <TableHeaderCell>DateTime</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {sortedResults.map((item) => {
                    const isDetected = !item.isClean && item.detectMessage;
                    return (
                        <TableRow
                            key={item.avName}
                            style={{
                                backgroundColor: isDetected ? "rgba(255, 0, 0, 0.1)" : "inherit",
                            }}
                        >
                            <TableCell>{item.avName}</TableCell>
                            <TableCell>
                                {isDetected ? item.detectMessage : "OK"}
                            </TableCell>
                            <TableCell>{item.scanDate}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );

    const dialogActions = (
        <Button appearance="primary" onClick={handleClose}>
            Close
        </Button>
    );

    return (
        <ActionDialog
            title="Antivirus scan result"
            isOpen={isOpen}
            onClose={handleClose}
            content={dialogContent}
            actions={dialogActions}
            trigger={null}
        />
    );
};

AvList.displayName = "AvList";
export default AvList;
