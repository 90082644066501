import {Card, CardHeader, PresenceBadge, Subtitle2} from "@fluentui/react-components";
import React, {useEffect, useState} from "react";
import useStyles from './ProfileSubscribeInfoStyles';
import {observer} from "mobx-react-lite";
import {useServices, useStore} from "../../hooks";
import {GetMessageByCode} from "../../utils";
import {SubscriptionInfoDto} from "../../models/common";
import {useToast} from "../../utils/ToastProvider";


const ProfileSubscribeInfo: React.FC = () => {
    const styles = useStyles();
    const services = useServices();
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfoDto>();
    const stores = useStore();
    const {showToast} = useToast();

    useEffect(() => {
        (async () => {
            if (!stores.userStore.isAdmin) {
                try {
                    const subscribeInfo = await services.subscriptionService.GetSubscriptionInfo();
                    setSubscriptionInfo(subscribeInfo.data);
                } catch (error: any) {
                    showToast('Error!', GetMessageByCode(error.code), 'error');
                }
            }
        })();
    }, [showToast, setSubscriptionInfo, stores.userStore, services.subscriptionService]);


    if (stores.userStore.isAdmin) {
        return (
            <Card className={styles.cardContainer}>
                <CardHeader
                    header={<Subtitle2>Subscription</Subtitle2>}>
                </CardHeader>
                <div>
                    <p>Subscription Status: <PresenceBadge size="small"/> <b>Active</b></p>
                    <b>You have administrator privileges.</b>
                </div>
            </Card>
        );
    } else {
        const dueDate = new Date(subscriptionInfo?.SubscriptionEndDateUtc ?? "");
        return (
            <Card className={styles.cardContainer}>
                <CardHeader
                    header={<Subtitle2>Subscription</Subtitle2>}>
                </CardHeader>
                <div>
                    <p>Subscription Status: <PresenceBadge size="small"/> <b>Active</b></p>
                    <p>Active until
                        : <b>{dueDate.toLocaleDateString()}</b> ({subscriptionInfo?.DaysTillExpiration} days)</p>
                    <p>Subscription plan : <b>Standard</b></p>
                </div>
            </Card>
        );
    }
}

/**
 * Имя отображаемое при отладке
 */
ProfileSubscribeInfo.displayName = "ProfileSubscribeInfo";

export default observer(ProfileSubscribeInfo);