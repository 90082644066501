// src/components/FeatureList.tsx

import React, {useEffect, useState} from "react";
import {Card, Checkbox, makeStyles, MessageBar, Spinner, Text,} from "@fluentui/react-components";
import {FeatureDto} from "../../models/admin/Features/FeatureDto";
import {useServices} from "../../hooks";

const useStyles = makeStyles({
    container: {
        padding: "20px",
        // Если нужно центрировать содержимое во время загрузки:
        display: "grid",
        placeItems: "center",
        minHeight: "200px", // или любой желаемый размер
    },
    featureList: {
        // Здесь можно оставить класс, если захотите
        // дополнительный отступ, иначе можно использовать
        // настройки Grid, см. ниже.
        marginTop: "16px",
    },
    featureCard: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        border: "1px solid #e1e1e1",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Добавление тени через CSS
    },
    featureName: {
        fontSize: "16px",
        fontWeight: "500",
    },
    // Если хотите, чтобы карточки занимали весь доступный
    // горизонтальный размер, можно добавить:
    gridContainer: {
        // Делаем сетку в один столбец с вертикальным интервалом
        // между элементами
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: "16px",
        // При необходимости можно добавить columnGap или другие настройки
    },
});

const FeatureList: React.FC = () => {
    const classes = useStyles();
    const services = useServices();
    const [features, setFeatures] = useState<FeatureDto[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchFeatures = async () => {
            try {
                const data = await services.adminFeatureService.getAll();
                setFeatures(data);
            } catch (err) {
                console.error(err);
                setError("Can't load feature list");
            } finally {
                setLoading(false);
            }
        };

        fetchFeatures();
    }, [services.adminFeatureService]);

    const handleToggle = async (featureName: string) => {
        // Найти фичу по имени
        const feature = features.find((f) => f.featureName === featureName);
        if (!feature) return;

        const updatedState = !feature.featureState;

        // Обновить локальное состояние
        setFeatures((prevFeatures) =>
            prevFeatures.map((f) =>
                f.featureName === featureName
                    ? {...f, featureState: updatedState}
                    : f
            )
        );

        try {
            // Отправить обновление на сервер
            await services.adminFeatureService.updateFeatureState(
                featureName,
                updatedState
            );
        } catch (err) {
            console.error(err);
            setError("Error during update feature state");
            // Вернуть предыдущее состояние в случае ошибки
            setFeatures((prevFeatures) =>
                prevFeatures.map((f) =>
                    f.featureName === featureName
                        ? {...f, featureState: !updatedState}
                        : f
                )
            );
        }
    };

    if (loading) {
        return (
            <div className={classes.container}>
                <Spinner label="Loading..."/>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            {error && <MessageBar intent="error">{error}</MessageBar>}

            {/*
        Вариант 1: используем класс с display: grid
        Если хотите управлять столбцами/строками напрямую:
      */}
            <div className={classes.gridContainer}>
                {features.map((feature) => (
                    <Card
                        key={feature.featureId}
                        className={classes.featureCard}
                        appearance="filled"
                    >
                        <Text className={classes.featureName}>{feature.featureName}</Text>
                        <Checkbox
                            checked={feature.featureState}
                            onChange={() => handleToggle(feature.featureName)}
                            label={feature.featureState ? "Enable" : "Disable"}
                        />
                    </Card>
                ))}
            </div>

            {/*
        Вариант 2: использовать готовый компонент Grid из Fluent UI v9.
        Обратите внимание на его пропсы (columns, columnGap, rowGap):
      */}
            {/*
      <Grid columns="1fr" rowGap="16px" className={classes.featureList}>
        {features.map((feature) => (
          <Card
            key={feature.featureId}
            className={classes.featureCard}
            appearance="filled"
          >
            <Text className={classes.featureName}>{feature.featureName}</Text>
            <Checkbox
              checked={feature.featureState}
              onChange={() => handleToggle(feature.featureName)}
              label={feature.featureState ? "Enable" : "Disable"}
            />
          </Card>
        ))}
      </Grid>
      */}
        </div>
    );
};

FeatureList.displayName = "FeaturesPage";

export default FeatureList;
