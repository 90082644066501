import React, {createContext, ReactNode, useCallback, useContext} from 'react';
import {Toast, ToastBody, Toaster, ToastTitle, useToastController} from "@fluentui/react-components";

// Типизация контекста для уведомлений
type ToastContextType = {
    showToast: (title: string, body: string, intent: 'error' | 'info' | 'success') => void;
};

// Создаем контекст с типизацией
const ToastContext = createContext<ToastContextType | null>(null);

type ToastProviderProps = {
    children: ReactNode; // Добавляем типизацию для children
};

// Провайдер контекста
export const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
    const {dispatchToast} = useToastController();

    // Функция для показа уведомления
    const showToast = useCallback((title: string, body: string, intent: 'error' | 'info' | 'success') => {
        dispatchToast(
            <Toast style={{minHeight: "50px", minWidth: "100px"}}>
                <ToastTitle>{title}</ToastTitle>
                <ToastBody>{body}</ToastBody>
            </Toast>,
            {intent}
        );
    }, [dispatchToast]);

    return (
        <ToastContext.Provider value={{showToast}}>
            {children} {/* children передается в провайдер */}
            <Toaster/>
        </ToastContext.Provider>
    );
};

// Хук для доступа к контексту
export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast должен использоваться внутри ToastProvider");
    }
    return context;
};