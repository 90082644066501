import {makeStyles, tokens} from "@fluentui/react-components";

export default makeStyles({
    TelegramNotificationsForm: {
        display: "flex",
        justifyContent: "center"
    },
    cardContainer: {
        minWidth: "400px",
        width: "100%",
        maxWidth: "90%",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#fff",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        position: "relative",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        "> div": {display: "flex", flexDirection: "column", gap: "2px"},
    },
    errorMessage: {
        color: "red",
    },
    status: {
        backgroundColor: tokens.colorBrandBackground,
        color: "#fff",
        padding: "5px 10px",
        borderRadius: "4px",
        fontSize: "14px",
        cursor: "pointer",
    },
    table: {
        width: '100%',
        marginTop: '20px',
    },
    settingButton: {
        margin: '0.3em',
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteButton: {
        margin: '0.3em',
        alignItems: 'center',
        backgroundColor: tokens.colorPaletteDarkOrangeForeground1,
        "&:hover": {
            backgroundColor: tokens.colorPaletteDarkOrangeForeground2, // Новый цвет при наведении
        },
    },
    checkbox: {
        // Применение кастомного стиля ползунка
        display: 'inline-block',
        width: '34px',
        height: '20px',
        position: 'relative',
        cursor: 'pointer',
        userSelect: 'none',
    },
    checkboxContainer: {
        display: 'inline-block',
        width: '34px',
        height: '20px',
        position: 'relative',
        cursor: 'pointer',
        userSelect: 'none',
    },
    checkboxInput: {
        display: 'none',
    },
    slider: {
        position: 'absolute',
        cursor: 'pointer',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: '#ccc',
        transition: '.4s',
        borderRadius: '34px',
    },
    sliderBefore: {
        position: 'absolute',
        content: '""',
        height: '14px',
        width: '14px',
        left: '3px',
        bottom: '3px',
        backgroundColor: 'white',
        transition: '.4s',
        borderRadius: '50%',
    },
    checkboxChecked: {
        backgroundColor: '#2196F3',
    },
    checkboxCheckedBefore: {
        transform: 'translateX(14px)',
    },
});