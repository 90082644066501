import React, {ReactElement} from "react";
import {LogSummaryProp} from "./LogSummaryProp";
import {Badge} from "@fluentui/react-components";
import {
    cookieColor,
    creditCardColor,
    extWalletsColor,
    messengerColor,
    passwordColor,
    softColor,
    walletsColor
} from "../../shared/constColors";
import useStyles from "../LogsList/LogsListStyle";
import {Cookies16Filled, KeyMultiple16Filled} from "@fluentui/react-icons";
import {SourceTypes} from "../../models/common/tags/";
import {v4 as uuidv4} from 'uuid';

/**
 * Компонент для отображения короткой информации о логе в виде бейджей
 * с возможностью перехода в некоторые разделы
 * @constructor
 */
const LogSummary: React.FC<LogSummaryProp> = (props) => {
    const styles = useStyles();
    const badgeSize = "medium";

    /**
     * Генерируем набор бейджей для отображения
     * @param props
     */
    const generateItems = (props: LogSummaryProp): ReactElement[] => {
        let items: React.ReactElement[] = [];

        if (props.isDuplicate) {
            items.push(
                <Badge size={badgeSize} key={"dup"} id={"dup"}
                       className={styles.badgeDuplicate}>!DUP!</Badge>
            );
        }
        if (props.walletCount > 0) {
            items.push(
                <Badge size={badgeSize} key={"WalletCount"} id={"WalletCount"}
                       style={{backgroundColor: walletsColor}}>Wallets: {props.walletCount}</Badge>
            );
        }
        if (props.extWalletCount > 0) {
            items.push(
                <Badge size={badgeSize} key={"ExtWalletCount"} id={"ExtWalletCount"}
                       style={{background: extWalletsColor}}>eWallet: {props.extWalletCount}</Badge>
            );
        }
        if (props.softCount > 0) {
            items.push(
                <Badge size={badgeSize} key={"softCount"} id={"softCount"}
                       style={{backgroundColor: softColor}}>Soft: {props.softCount}</Badge>
            );
        }
        if (props.creditCardsCount > 0) {
            items.push(
                <Badge size={badgeSize} key={"creditCards"} id={"creditCards"}
                       style={{background: creditCardColor}}>CC: {props.creditCardsCount}</Badge>
            );
        }
        if (props.cookiesCount > 0) {
            items.push(
                <Badge size={badgeSize} key={"cookieCount"} id={"cookieCount"}
                       style={{backgroundColor: cookieColor}}>Cookies: {props.cookiesCount}</Badge>
            );
        }
        if (props.passwordsCount > 0) {
            items.push(
                <Badge size={badgeSize} key={"passwordsCount"} id={"passwordsCount"}
                       style={{backgroundColor: passwordColor}}>Pwd: {props.passwordsCount}</Badge>
            );
        }

        if (props.messengersCount > 0) {
            items.push(
                <Badge size={badgeSize} key={"messengersCount"} id={"messengersCount"}
                       style={{backgroundColor: messengerColor}}>Messenger: {props.messengersCount}</Badge>
            );
        }

        if (props.tags && props.tags.length > 0) {
            props.tags.forEach((tag) => { // Changed from map to forEach
                const sourceIcons = chooseIconForTag(tag.sources);
                items.push(
                    <Badge size={badgeSize} className={styles.badgeSpacing} id={uuidv4()} key={uuidv4()}
                           style={{backgroundColor: tag.color}}>{sourceIcons}{tag.name}</Badge>
                );
            });
        }

        return items;
    }

    /**
     * Генерируется набор иконок для Badge в Summary
     * @param sourceType
     */
    const chooseIconForTag = (sourceType: SourceTypes[]): ReactElement[] => {
        let items: React.ReactElement[] = [];

        sourceType.forEach((elem) => { // Changed from map to forEach
            switch (elem) {
                case SourceTypes.Cookies:
                    items.push(<Cookies16Filled key={uuidv4()} alignmentBaseline={"auto"}/>);
                    break;
                case SourceTypes.Files:
                    // Handle SourceTypes.Files if needed
                    break;
                case SourceTypes.Passwords:
                    items.push(<KeyMultiple16Filled key={uuidv4()}/>);
                    break;
                default:
                    break;
            }
        });

        return items;
    };

    /**
     * Переменная хранящая все элементы Summary, если они сгенерированы
     */
    const itemsToDisplay = generateItems(props);

    if (itemsToDisplay && itemsToDisplay.length > 0) {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', overflow: 'hidden', margin: '10px' }}>
                {itemsToDisplay.map((el) => el)}
            </div>
        );
    }

    // Если нет никаких данных для отображения в "Summary",
    // Возвращаем "пустой" компонент
    return <></>;
}

/**
 * Имя отображаемое при отладке
 */
LogSummary.displayName = "LogSummary";

export default LogSummary;
