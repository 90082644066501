// AvStatusButton.tsx
import React, {useState} from "react";
import {Button, tokens} from "@fluentui/react-components";
import {AvStatusButtonProps} from "./AvStatusButtonProps";
import {AvList} from "../AvList";

/**
 * Кнопка отображающая статус проверки через АВ
 * По клику — показывает окно (AvList) со списком антивирусов
 */
const AvStatusButton: React.FC<AvStatusButtonProps> = (props) => {
    // Флаг, открыто ли окно со списком
    const [showList, setShowList] = useState(false);

    // Определяем, должна ли кнопка быть неактивной
    const isDisabled =
        (props.avTotalDetected === 0 && props.avTotal === 0) ||
        (props.brokerId === 0);

    // Обработчик клика по кнопке
    const onClick = () => {
        // Открываем AvList
        setShowList(true);
    };

    // Когда AvList (диалог) закроется, возвращаемся в false
    const handleListClose = () => {
        setShowList(false);
    };

    // Подбираем цвет кнопки
    const buttonColor = () => {
        // Если кнопка неактивна, делаем фон серым
        if (isDisabled) {
            return tokens.colorNeutralBackgroundDisabled;
        }

        // Далее логика для активной кнопки
        if (props.avTotalDetected > 0) {
            return tokens.colorPaletteDarkOrangeBackground2;
        }

        if (props.avTotalDetected === 0) {
            return tokens.colorPaletteGreenBackground2;
        }

        return tokens.colorNeutralBackground1;
    };

    return (
        <>
            <Button
                disabled={isDisabled}
                style={{background: buttonColor()}}
                size={props.size}
                onClick={onClick}
            >
                {props.avTotalDetected + " / " + props.avTotal}
            </Button>

            {/* Когда showList === true, показываем AvList */}
            {showList && (
                <AvList
                    onClose={handleListClose}
                    brokerId={props.brokerId}   // <- пробрасываем brokerId
                />
            )}
        </>
    );
};

AvStatusButton.displayName = "AvStatusButton";
export default AvStatusButton;
