import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Card, CardHeader, Label, Select, Spinner, Subtitle2} from "@fluentui/react-components";
import {useToast} from "../../utils/ToastProvider";
import {LoadingButton} from "../ButtonWithLoadingState";
import {useStore} from "../../hooks";
import tzData from "iana-tz-data"; // Импорт списка

/**
 * Виджет управления пользовательскими настройками
 * @constructor
 */
const UserSettings: React.FC = observer(() => {
    const {showToast} = useToast();
    const store = useStore();
    const [timeZones, setTimeZones] = useState<string[]>([]);

    /**
     * Загрузка списка временных зон
     */
    useEffect(() => {
        const zones: string[] = [];
        const zoneData = tzData.zoneData as Record<string, Record<string, unknown>>;

        // Проходим по регионам и зонам, чтобы собрать все зоны
        for (const region in zoneData) {
            if (zoneData.hasOwnProperty(region)) {
                const subZones = Object.keys(zoneData[region]);

                // Фильтрация зон, исключая те, которые содержат ненужные суффиксы
                const filteredZones = subZones.filter((subZone) => {
                    return !subZone.match(/(abbrs|offsets|isdsts|untils)$/); // Исключаем эти суффиксы
                });

                zones.push(...filteredZones.map((subZone) => `${region}/${subZone}`));
            }
        }

        setTimeZones(zones);
    }, []);


    /**
     * Загрузка параметров пользователя из хранилища при монтировании
     */
    useEffect(() => {
        store.userSettingsStore.loadSettings().catch((error: any) => {
            showToast("Error", `Failed to load settings: ${error.message}`, "error");
        });
    }, [showToast, store.userSettingsStore]);

    /**
     * Обновление временной зоны пользователя
     */
    const onUpdateSettings = async () => {
        try {
            if (!store.userSettingsStore.userSettings) {
                console.error("No user settings found!");
                return;
            }

            await store.userSettingsStore.updateSettings(store.userSettingsStore.userSettings);
            showToast("Success", "Settings updated successfully!", "success");
        } catch (error: any) {
            showToast("Error", `Failed to update settings: ${error.message}`, "error");
        }
    };

    /**
     * Обновление временной зоны в хранилище
     */
    const onTimeZoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newTimeZone = event.target.value;
        store.userSettingsStore.updateSettings({
            ...store.userSettingsStore.userSettings,
            timeZone: newTimeZone,
        });
    };

    if (store.userSettingsStore.isLoading) {
        return <div><Spinner/>Loading settings...</div>;
    }

    if (!store.userSettingsStore.userSettings) {
        return <div>No settings available.</div>;
    }

    return (
        <Card>
            <CardHeader header={<Subtitle2>Settings</Subtitle2>}/>
            <div>
                <div>
                    <Label>Time zone: </Label>
                    <Select
                        value={store.userSettingsStore.userSettings.timeZone} // Текущее значение из хранилища
                        onChange={onTimeZoneChange} // Обновление временной зоны
                    >
                        {timeZones.map((tz) => (
                            <option key={tz} value={tz}>
                                {tz}
                            </option>
                        ))}
                    </Select>
                </div>
            </div>

            <LoadingButton appearance={"primary"} onClick={onUpdateSettings} enabled={true} buttonText={"Save"}/>
        </Card>
    );
});

UserSettings.displayName = "UserSetting";

export default UserSettings;
