import {AxiosService} from "./axios";
import {DATA_BROKER_URL} from "../shared/constUrl";
import {DataBrokerDto, DataBrokerScanResult, DataBrokerShortScanResult} from "../models/common/dataBroker";

export class DataBrokerService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить результаты сканирования брокера
     * @param brokerId
     */
    async getAvResult(brokerId: number): Promise<DataBrokerScanResult[]> {
        try {
            const resp = await this.axios.get<DataBrokerScanResult[]>(`${DATA_BROKER_URL}/av-result/${brokerId}`);
            return Promise.resolve(resp.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * Получить краткую статистику сканирования брокера
     * @param brokerName
     */
    async getShortAvResult(brokerName: string): Promise<DataBrokerShortScanResult> {
        try {
            const resp = await this.axios.get<DataBrokerShortScanResult>(`${DATA_BROKER_URL}/av-result-short/${brokerName}`);
            return Promise.resolve(resp.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async getLastHealthCheck(brokerId: number): Promise<string> {
        try {
            const resp = await this.axios.get<string>(`${DATA_BROKER_URL}/last-health-check/${brokerId}`);
            return Promise.resolve(resp.data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async getDataBrokers(): Promise<DataBrokerDto[]> {
        try {
            const resp = await this.axios.get<DataBrokerDto[]>(DATA_BROKER_URL + '/list');
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}