import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    toolsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5em',
        margin: '0.3em',
        marginLeft: '0.6em'
    },
    tableHeaderCell: {
        textAlign: 'center',
        justifyContent: "center",
        verticalAlign: 'middle'
    },
    uriCell: {
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px'
    },
    buildCell: {
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100px'
    },
    domainNameCell: {
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px'
    },
    avScanCell: {
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100px'
    },
    healthCall: {
        textAlign: 'center',
        verticalAlign: 'middle',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100px'
    },
    actionsButtonContainer: {
        display: 'flex', gap: '0.5em'
    }
});