import React, {useCallback, useEffect, useState} from "react";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import {Button, Select, Switch, tokens} from "@fluentui/react-components";
import {DatePicker} from "@fluentui/react-datepicker-compat";
import {useServices, useStore} from "../../../hooks";
import UseStyle from "../LogListPage/LogListToolsStyles";
import {Search20Regular, Share20Filled} from "@fluentui/react-icons";
import {LoadingButton} from "../../../components/ButtonWithLoadingState";
import {useToast} from "../../../utils/ToastProvider";
import {observer} from "mobx-react-lite";
import {StatsShareLinkDialog} from "../../../components/StatsShareLinkDialog";
import {StatsManageLinkDialog} from "../../../components/StatsManageLinkDialog";

const StatisticPageTools: React.FC = () => {
    const [partnersOptions, setPartnersOptions] = useState<string[]>([]);
    const [buildTagOptions, setBuildTagOptions] = useState<string[]>([]);
    const {statisticService} = useServices();
    const styles = UseStyle();
    const {showToast} = useToast();
    const {statisticsStore} = useStore();
    const [enabledManageLink, setEnabledManageLink] = useState(false);
    const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
    const [isManageDialogOpen, setIsManageDialogOpen] = useState(false);

    /**
     * Загрузка опций для фильтрации
     */
    useEffect(() => {
        try {
            statisticService.getOptions().then((data) => {
                setPartnersOptions(data.partners);
                setBuildTagOptions(data.buildTags);
            });

            statisticService.isHaveLinks().then((data) => {
                setEnabledManageLink(data);
            })
        } catch (error: any) {
            showToast("Error!", error.message, "error");
        }
    }, [showToast, statisticService]);

    const onSearchClickHandler = useCallback(async () => {
        try {
            const body = {
                startDate: statisticsStore.startDate,
                endDate: statisticsStore.endDate,
                partnerName: statisticsStore.partnerName,
                buildTag: statisticsStore.buildTag,
                showEmpty: statisticsStore.showEmpty,
                showDup: statisticsStore.showDup
            };
            statisticsStore.data = await statisticService.getStatisticData(body);
            statisticsStore.dataByCountry = await statisticService.getTopThreeCountries(body)
        } catch (error: any) {
            showToast("Error!", error.message, "error");
        }
    }, [statisticService, statisticsStore, showToast]);

    return (
        <ToolsContainer>
            <div className={styles.controlContainer}>
                <DatePicker
                    value={statisticsStore.startDate}
                    onSelectDate={(date) => statisticsStore.startDate = date || new Date(0)}
                />
                <DatePicker
                    value={statisticsStore.endDate}
                    onSelectDate={(date) => statisticsStore.endDate = date || new Date()}
                />

                <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                    <label htmlFor="partner-select">Partner:</label>
                    <Select
                        id={"partner-select"}
                        value={statisticsStore.partnerName}
                        onChange={(event) => statisticsStore.partnerName = event.target.value}>
                        <option key={"all"} value={""}>None | All</option>
                        {partnersOptions.map((partner, index) => (
                            <option key={index} value={partner}>
                                {partner}
                            </option>
                        ))}
                    </Select>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                    <label htmlFor="build-tag-select">Build Tag:</label>
                    <Select id={"build-tag-select"}
                            value={statisticsStore.buildTag}
                            onChange={(event) => statisticsStore.buildTag = event.target.value}>
                        <option key={"all"} value={""}>None | All</option>
                        {buildTagOptions.map((tag, index) => (
                            <option key={index} value={tag}>
                                {tag}
                            </option>
                        ))}
                    </Select>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                    <label htmlFor="build-tag-select">Show empty</label>
                    <Switch checked={statisticsStore.showEmpty}
                            onChange={(event) => statisticsStore.showEmpty = event.target.checked}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                    <label htmlFor="build-tag-select">Show duplicates</label>
                    <Switch
                        checked={statisticsStore.showDup}
                        onChange={(event) => statisticsStore.showDup = event.target.checked}
                    />
                </div>

                <LoadingButton appearance={"primary"}
                               enabled={true}
                               icon={<Search20Regular/>}
                               onClick={onSearchClickHandler}
                               buttonText={"Search"}/>
            </div>
            <div>
                <Button
                    icon={<Share20Filled/>}
                    appearance={"primary"}
                    color={tokens.colorBrandBackgroundStatic}
                    title={"Share"}
                    onClick={() => setIsShareDialogOpen(true)}
                    style={{marginLeft: '1em', marginRight: '1em'}}
                >
                    Share
                </Button>
                <Button
                    appearance={"secondary"}
                    disabled={!enabledManageLink}
                    onClick={() => setIsManageDialogOpen(true)}>
                    Manage links
                </Button>
            </div>

            {isShareDialogOpen && (
                <StatsShareLinkDialog
                    isOpen={isShareDialogOpen}
                    onClose={() => setIsShareDialogOpen(false)}
                    partnersOptions={partnersOptions}
                    buildTagOptions={buildTagOptions}
                />
            )}
            {isManageDialogOpen && (
                <StatsManageLinkDialog
                    isOpen={isManageDialogOpen}
                    onClose={() => setIsManageDialogOpen(false)}
                />
            )}
        </ToolsContainer>
    );
}

StatisticPageTools.displayName = "StatisticPageTools";
export default observer(StatisticPageTools);
