import React from "react";
import {Button, Field, Label, ProgressBar, Spinner, Subtitle2, Text} from "@fluentui/react-components";
import {BuildRequestStatesDto} from "../../models/common";
import {observer} from "mobx-react-lite";
import {useServices, useStore} from "../../hooks";
import {BUILDER_URL} from "../../shared/constUrl";
import {ErrorCode} from "../../models/shared/ErrorCode";
import {GetMessageByCode} from "../../utils";
import {useToast} from "../../utils/ToastProvider";
import {CheckmarkCircleRegular, ErrorCircleRegular, HourglassRegular} from '@fluentui/react-icons';

/**
 * Компонент для отображения статуса запроса на билд
 * @constructor
 */
const BuilderRequestState: React.FC = () => {
    const store = useStore();
    const services = useServices();
    const {showToast} = useToast();

    /**
     * Обработчик кнопки "Скачать"
     * @param requestId
     */
    const handleDownload = async (requestId: string) => {
        try {
            const urlWithParams = `${BUILDER_URL}/download/${requestId}`;
            await services.fileDownloadService.downloadFile(urlWithParams);
        } catch (error: any) {
            const errorMessage = error.code === ErrorCode.CustomErrorMessage ? error.message : GetMessageByCode(error.code);
            showToast('Failed to fetch builder info'
                , errorMessage
                , "error");
        }
    };

    const handleReset = async () => {
        try {
            await services.builderService.deletedBadTask();
            store.builderStore.requestState.state = BuildRequestStatesDto.None;
        } catch (error: any) {
            const errorMessage = error.code === ErrorCode.CustomErrorMessage ? error.message : GetMessageByCode(error.code);
            showToast('Failed to fetch builder info'
                , errorMessage
                , "error");
        }
    }

    const showState = () => {
        switch (store.builderStore.requestState.state) {
            case BuildRequestStatesDto.Waiting:
                return <div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px'}}>
                        <HourglassRegular style={{fontSize: '64px'}}/>
                        <Text>Waiting in queue</Text>
                    </div>
                    <Field validationState="none">
                        <ProgressBar color={"warning"} thickness={"large"}/>
                    </Field>
                </div>
            case BuildRequestStatesDto.Processing:
                return <div
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px'}}>
                    <Spinner style={{color: 'orange', fontSize: '100px'}}/>
                    <Text>Processing</Text>
                </div>
            case BuildRequestStatesDto.Done:
                return <>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px'}}>
                        <CheckmarkCircleRegular style={{color: 'green', fontSize: '100px'}}/>
                    </div>

                    {/* Блок с размером файла */}
                    {store.builderStore.requestState.fileSize !== undefined && store.builderStore.requestState.fileSize > 0 && (
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                            <Label>File Size:</Label>
                            <Text>{store.builderStore.requestState.fileSize} bytes</Text>
                        </div>
                    )}

                    {/* Кнопка для скачивания */}
                    <div style={{display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px'}}>
                        <Button appearance={"primary"}
                                onClick={() => handleDownload(store.builderStore.requestState.requestId)}>
                            Download
                        </Button>
                    </div>
                </>
            case BuildRequestStatesDto.Error:
                return <div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px'}}>
                        <ErrorCircleRegular style={{fontSize: '64px'}} color={"red"}/>
                        <Text>Error</Text>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px'}}>
                        <Button appearance={"primary"} onClick={handleReset}>
                            Reset
                        </Button>
                    </div>
                </div>
        }
    }

    return (
        <div>
            <Subtitle2>Last request state:</Subtitle2>
            {showState()}
        </div>
    );
}

/**
 * Имя отображаемое при отладке
 */
BuilderRequestState.displayName = "BuilderRequestState";

export default observer(BuilderRequestState);
