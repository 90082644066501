import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    pageContainer: {
        display: "flex", // Используем flexbox для управления расположением блоков
        flexWrap: "wrap", // Разрешаем перенос элементов на следующую строку
        gap: "0.75em", // Добавляем отступы между элементами (по желанию)
        justifyContent: "flex-start", // Выравниваем элементы по началу строки
        width: "100%",
    }
});