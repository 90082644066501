import React from "react";
import {Layout} from "../../../containers/Layout";
import useStyle from "./PasswordPageStyle";
import {PasswordForm} from "../../../forms/PasswordForm";


/**
 * Компонент-страница "Пароли"
 * @constructor
 */
const PasswordPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Password"} anotherBackground={true}>
        <div className={styles.PasswordForm}>
            <PasswordForm></PasswordForm>
        </div>
    </Layout>);
}

PasswordPage.displayName = "PasswordPage";
export default PasswordPage;