import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Spinner, Title3} from '@fluentui/react-components';
import UseStyle from './StatsLogDataStyles';
import {useServices, useStore} from "../../hooks";
import {observer} from "mobx-react-lite";
import {StatsQueryDto} from "../../models/common";
import {useToast} from "../../utils/ToastProvider";
import {StatsLogDataProps} from "./StatsLogDataProps";
import {StatisticsQuerySharedDto} from "../../models/common/statistic/StatsQuerySharedDto";
import {DonutChart} from "@fluentui/react-charting";

/**
 * форма отображения статистики
 */
const StatsLogData: React.FC<StatsLogDataProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const styles = UseStyle();
    const {statisticSharedStore, statisticsStore} = useStore();
    const {statisticService, statisticSharedService} = useServices();
    const {showToast} = useToast();

    /**
     * Получение статистики
     */
    const fetchData = useCallback(async () => {
        if (props.shared) {
            statisticSharedStore.data = null;
        } else {
            statisticsStore.data = null;
        }

        try {
            setLoading(true);
            if (props.shared) {
                const body: StatisticsQuerySharedDto = {
                    startDate: statisticSharedStore.startDate,
                    endDate: statisticSharedStore.endDate,
                };

                const [statisticResult] = await Promise.all([
                    statisticSharedService.getSharedStatsData(
                        statisticSharedStore.id,
                        body
                    ),
                ]);

                statisticSharedStore.data = statisticResult;
                statisticSharedStore.startDate = new Date(statisticResult.startStatistic);
            } else {
                const body: StatsQueryDto = {
                    startDate: statisticsStore.startDate,
                    endDate: statisticsStore.endDate,
                    partnerName: statisticsStore.partnerName,
                    buildTag: statisticsStore.buildTag,
                    showDup: statisticsStore.showDup,
                    showEmpty: statisticsStore.showEmpty,
                };

                const [statisticResult] = await Promise.all([
                    statisticService.getStatisticData(body),
                ]);

                statisticsStore.data = statisticResult;
                statisticsStore.startDate = new Date(statisticResult.startStatistic);
            }
        } catch (error: any) {
            showToast('Error!', error.message, 'error');
        } finally {
            setLoading(false);
        }
    }, [statisticService, statisticsStore, statisticSharedService, statisticSharedStore, props.shared, showToast]);

    useEffect(() => {
        fetchData();
    }, [fetchData, props.shared]);

    /**
     * Расчёт данных графика с кэшированием
     */
    const chartData = useMemo(() => {
        const sourceData = props.shared ? statisticSharedStore.data : statisticsStore.data;

        const {emptyCount = 0, importantCount = 0, totalCount = 1} = sourceData ?? {
            emptyCount: 0,
            importantCount: 0,
            totalCount: 0
        };
        const normalCount = totalCount - emptyCount - importantCount;

        if (emptyCount === 0) {
            return {
                chartData: [
                    {legend: 'Normal', data: normalCount, color: '#00bcf2'},
                    {legend: 'Important', data: importantCount, color: '#ff8c00'}
                ],
            }
        }

        return {
            chartData: [
                {legend: 'Normal', data: normalCount, color: '#00bcf2'},
                {legend: 'Important', data: importantCount, color: '#ff8c00'},
                {legend: 'Empty', data: emptyCount, color: '#0078d4'},
            ],
        }
    }, [props.shared, statisticsStore.data, statisticSharedStore.data]);

    return (
        <Card className={styles.cardContainer}>
            {loading ? (
                <Spinner/>
            ) : (
                <>
                    {(props.shared
                        ? statisticSharedStore.data
                        : statisticsStore.data) && (
                        <div className={styles.logData}>
                            <Title3>Log data statistics</Title3>
                            {((props.shared
                                ? statisticSharedStore.data?.totalCount
                                : statisticsStore.data?.totalCount) || 0) === 0 ? (
                                <div className={styles.noDataMessage}>
                                    No data available for the selected period.
                                </div>
                            ) : (
                                <div className={styles.diagram}>
                                    <DonutChart
                                        data={chartData}
                                        innerRadius={50}
                                        valueInsideDonut={props.shared
                                            ? statisticSharedStore.data?.totalCount || 0
                                            : statisticsStore.data?.totalCount || 0}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

/**
 * Имя отображаемое при отладке
 */
StatsLogData.displayName = 'StatsLogData';
export default observer(StatsLogData);
