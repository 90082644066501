import React, {useEffect} from "react";
import {
    Button,
    Card,
    CardHeader,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogTitle,
    Input,
    Label,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow
} from "@fluentui/react-components";
import {observer} from "mobx-react-lite";
import {useServices, useStore} from "../../hooks";
import {Add20Filled, Delete20Filled, Info20Filled, Save20Filled} from "@fluentui/react-icons";
import {LoadingButton} from "../ButtonWithLoadingState";
import makeStyles from "./JsonProfileBrowserEditorStyle";
import {useToast} from "../../utils/ToastProvider";
import ActionDialog from "../ActionDialog";
import {BrowserWithTypeDto} from "../../models/common/dictionaries/BrowserWithTypeDto";
import {BrowserTypes} from "../../models/common/dictionaries/BrowserTypes";

const JsonProfileBrowserEditor: React.FC = () => {
    const {dictService} = useServices();
    const {showToast} = useToast();
    const [browsers, setBrowsers] = React.useState<BrowserWithTypeDto[]>([]);
    const {jsonProfileStore} = useStore();
    const style = makeStyles();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [newBrowserName, setNewBrowserName] = React.useState("");
    const [newBrowserType, setNewBrowserType] = React.useState<BrowserTypes>(
        BrowserTypes.Chromium
    );

    useEffect(() => {
        try {
            dictService.getBrowserDict().then((data) => {
                setBrowsers(data);
            });
        } catch (error: any) {
            showToast("Error", error.message, "error");
        }
    }, [dictService, showToast]);

    // Функция добавления нового браузера
    const handleAddNewBrowser = async () => {
        try {
            const newBrowser = await dictService.addNewBrowser(newBrowserName);

            const b = {
                id: newBrowser.id,
                publicName: newBrowser.publicName,
                type: 0
            }
            setBrowsers((prev) => [...prev, b]);
            setIsModalOpen(false);
            setNewBrowserName("");
        } catch (error: any) {
            showToast("Ошибка", error.message, "error");
        }
    };

    return (
        <Card>
            <div className={style.addToolContainer}>
                <Input
                    placeholder="Type URL"
                    value={""}
                />
                <Input
                    placeholder={"Process name"}
                    value={""}
                />
                {/* Селект с виртуальной опцией "add_new" */}
                <Select
                    value={""}
                    onChange={(e) => {
                        const {value} = e.target as HTMLSelectElement;
                        if (value === "add_new") {
                            setIsModalOpen(true);
                        }
                    }}
                >
                    {browsers.map((b) => (
                        <option key={b.id} value={b.id}>
                            {b.publicName}
                        </option>
                    ))}
                    <option value="add_new">+Add new browser name+</option>
                </Select>
                <Button appearance="primary" icon={<Add20Filled/>}>
                    Add
                </Button>
                <LoadingButton
                    appearance="primary"
                    enabled={true}
                    buttonText={"Update profile"}
                    icon={<Save20Filled/>}
                />
            </div>

            <CardHeader header={<h4>Browser list</h4>}/>
            <div>
                {jsonProfileStore.profile &&
                jsonProfileStore.profile.b &&
                jsonProfileStore.profile.b.length > 0 ? (
                    <Table style={{}}>
                        <TableHeader>
                            <TableCell>Name</TableCell>
                            <TableCell>Path</TableCell>
                            <TableCell>Process Name</TableCell>
                            <TableCell>Type</TableCell>
                        </TableHeader>
                        <TableBody>
                            {jsonProfileStore.profile.b.map((browser, index) => (
                                <TableRow key={index}>
                                    <TableCell>{browser.n} </TableCell>
                                    <TableCell>{browser.p} </TableCell>
                                    <TableCell>{browser.pn} </TableCell>
                                    <TableCell>{browser.t}</TableCell>
                                    <TableCell>
                                        <Button
                                            icon={<Delete20Filled/>}
                                            appearance="subtle"
                                            onClick={() => console.log(browser.n)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            color: "#666",
                        }}
                    >
                        <Info20Filled/> No files for loader
                    </p>
                )}
            </div>

            <ActionDialog isOpen={isModalOpen} content={<DialogBody>
                <DialogTitle>Add new browser name</DialogTitle>
                <DialogContent style={{display: "flex", flexDirection: "column", gap: "1em"}}>

                    <Label>Browser name:</Label>
                    <Input
                        style={{width: "100"}}
                        placeholder="Enter browser name"
                        value={newBrowserName}
                        onChange={(e) =>
                            setNewBrowserName((e.target as HTMLInputElement).value)
                        }
                    />
                    <Label>Browser type</Label>
                    <Select
                        value={newBrowserType.toString()}
                        onChange={(e) =>
                            setNewBrowserType(
                                Number((e.target as HTMLSelectElement).value) as BrowserTypes
                            )
                        }
                    >
                        <option value={BrowserTypes.Chromium}>
                            {BrowserTypes[BrowserTypes.Chromium]}
                        </option>
                        <option value={BrowserTypes.Gecko}>
                            {BrowserTypes[BrowserTypes.Gecko]}
                        </option>
                    </Select>

                </DialogContent>
            </DialogBody>} actions={<DialogActions>
                <Button
                    appearance="secondary"
                    onClick={() => setIsModalOpen(false)}
                >
                    Close
                </Button>
                <Button
                    appearance="primary"
                    onClick={handleAddNewBrowser}
                >
                    Add
                </Button>
            </DialogActions>} trigger={null}/>
        </Card>
    );
};

export default observer(JsonProfileBrowserEditor);
