import {AxiosService} from "./axios";
import {UserSettingsDto} from "../models/common/user/UserSettingsDto";
import {USER_SETTINGS_URL} from "../shared/constUrl";

export class UserSettingsService {
    /**
     * Экземпляр сервиса axios
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить информацию о настройках
     */
    async fetchSettings(): Promise<UserSettingsDto> {
        try {
            const response = await this.axios.get<UserSettingsDto>(USER_SETTINGS_URL);
            return Promise.resolve(response.data as UserSettingsDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async updateSettings(userSettingsDto: UserSettingsDto): Promise<void> {
        try {
            await this.axios.put(`${USER_SETTINGS_URL}/update`, userSettingsDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}