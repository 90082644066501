import React, {useEffect, useState} from "react";
import {IVerticalBarChartDataPoint, VerticalBarChart} from '@fluentui/react-charting';
import {Text} from "@fluentui/react-components";
import {useServices, useStore} from "../../hooks";
import {observer} from "mobx-react-lite";
import {useToast} from "../../utils/ToastProvider";

/**
 * Имена столбцов в графике
 */
const columnNames: Record<string, string> = {
    baseCount: 'Base',
    grabberCount: 'Grabber',
    browsersCount: 'Browsers',
    messengerCount: 'Messenger',
    softCount: 'Soft',
    walletsCount: 'Wallets',
}

/**
 * Интервал авто-обновления данных
 */
const FETCH_QUEUE_INTERVAL = 5 * 1000;

/**
 * Компонент графика очереди
 * @constructor
 */
const QueueStateChart: React.FC = () => {
    const service = useServices();
    const store = useStore();
    const {showToast} = useToast();
    const [chartData, setChartData] = useState<IVerticalBarChartDataPoint[]>()

    /**
     * Функция получения данных об очереди с сервера с поддержкой последовательных запросов
     */
    const fetchData = async () => {
        try {
            store.loaderStore.setLoading(true);

            const receivedQueue = await service.adminQueueState.getQueue();

            // Интерпретация полученных данных для график-компонента в нужном виде
            let updatedChartData: IVerticalBarChartDataPoint[] = []
            Object.entries(receivedQueue).map(([name, queue]) => updatedChartData.push({x: (columnNames[name] ?? name), y: queue}));

            setChartData(updatedChartData);
        } catch (error) {
            showToast('Error', `Failed to fetch queue state ${error}`, 'error')
        }
    }

    /**
     * Эффект получения очереди и автообновления
     */
    useEffect(() => {
        fetchData().then();
        const intervalId = setInterval(fetchData, FETCH_QUEUE_INTERVAL);
        return () => clearInterval(intervalId);
    }, []);

    /**
     * Вернуть страницу
     */
    return (
        <>
            <div style={{padding: "10px", width: '100%', display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
                {chartData?.some(item => item.y !== 0) ? (
                    <VerticalBarChart
                        data={chartData}
                        barWidth={40}
                        hideLegend={true}
                        roundCorners={true}
                    />
                ) : (
                    <Text size={500}>The queue is empty</Text>
                )}
            </div>
        </>
    );
}

/**
 * Имя отображаемое при отладке
 */
QueueStateChart.displayName = "QueueStateChart";

export default observer(QueueStateChart);
