import {CreateLinkDto, StatisticDataDto, StatsLogByCountry, StatsQueryDto} from "../models/common";
import {AxiosService} from "./axios";
import {StatsOptionsDto} from "../models/common/statistic/StatsOptionsDto";
import {STATISTIC_SHARED_URL, STATISTIC_URL} from "../shared/constUrl";
import {SharedLinksListDto} from "../models/common/statistic/SharedLinksListDto";

export class StatisticService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * запрос данных статистики если существует
     */
    async getStatisticData(body: StatsQueryDto): Promise<StatisticDataDto> {
        try {
            const resp = await this.axios.post<StatisticDataDto>("/statistic/get-data", body);
            return Promise.resolve(resp.data as StatisticDataDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить топ три страны
     */
    async getTopThreeCountries(body: StatsQueryDto): Promise<StatsLogByCountry[]> {
        try {
            const resp = await this.axios.post<StatsLogByCountry[]>("/statistic/get-top-3-countries", body);
            return Promise.resolve(resp.data as StatsLogByCountry[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить полный список стран отсортированный по количеству логов
     */
    async getAllCountriesSorted(body: StatsQueryDto): Promise<StatsLogByCountry[]> {
        try {
            const resp = await this.axios.post<StatsLogByCountry[]>("/statistic/get-all-top-countries", body);
            return Promise.resolve(resp.data as StatsLogByCountry[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Получение опций для фильтрации статистики
     */
    async getOptions(): Promise<StatsOptionsDto> {
        try {
            const resp = await this.axios.get<StatsOptionsDto>(STATISTIC_URL + '/options');
            return Promise.resolve(resp.data as StatsOptionsDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Проверка наличия ссылок
     */
    async isHaveLinks(): Promise<boolean> {
        try {
            const resp = await this.axios.get<boolean>(STATISTIC_SHARED_URL + '/is-have-links');
            return Promise.resolve(resp.data as boolean);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Создание ссылки
     */
    async createLink(linkParams: CreateLinkDto): Promise<string> {
        try {
            const resp = await this.axios.post<string>(STATISTIC_SHARED_URL + '/create', linkParams);
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удаление ссылки
     * @param id Идентификатор ссылки
     */
    async deleteLink(id: string): Promise<void> {
        try {
            await this.axios.delete(`${STATISTIC_SHARED_URL}/delete/${id}`);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Загрузка списка ссылок
     */
    async getLinks(): Promise<SharedLinksListDto> {
        try {
            const resp = await this.axios.get<SharedLinksListDto>(STATISTIC_SHARED_URL + '/manage');
            return resp.data as SharedLinksListDto;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}