import {makeAutoObservable} from "mobx";
import {WalletsDto} from "../models/common/wallets/WalletsDto";
import {Paging} from "../models/shared";

export class WalletsStore {
    /**
     * Кошельки
     * @private
     */
    private _wallets: WalletsDto[] = [];

    /**
     * Состояние пагинации на странице логов
     * @private
     */
    private _paging: Paging = {
        pageNumber: 1,
        pageSize: 50,
        totalItems: 0,
        totalPages: 1
    };

    /**
     * Список доступных кошельков для фильтрации
     * @private
     */
    private _options: string[] = [];

    /**
     * Конструктор хранилища кошельков
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Получить кошельки
     */
    public get wallets() {
        return this._wallets;
    }

    public set wallets(value: WalletsDto[]) {
        this._wallets = value;
    }

    /**
     * Получить параметры пагинации
     */
    public get paging() {
        return this._paging;
    }

    /**
     * Сохранить параметры пагинации
     * * @param value
     */
    public set paging(value: Paging) {
        this._paging = value;
    }

    /**
     * Получить список опций
     */
    public get options() {
        return this._options;
    }

    /**
     * Сохранить список опций
     */
    public set options(value: string[]) {
        this._options = value;
    }

    /**
     * Сброс данных в хранилище
     */
    public reset() {
        this._paging = {
            pageNumber: 1,
            pageSize: 50,
            totalItems: 0,
            totalPages: 1
        };
        this.wallets = [];
    }
}