/**
 * Типы браузеров
 */
export enum BrowserTypes {
    /**
     * Браузеры семейства Chromium
     */
    Chromium = 1,

    /**
     * Браузеры семейства Gecko
     */
    Gecko = 2
}