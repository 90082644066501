import {AxiosService} from "./axios";
import {AdminDashboardCountersDto} from "../models/admin/adminDashboard/dashboardCountersDto";

/**
 * Сервис работы с Аккаунтом пользователя
 */
export class AdminDashboardService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор аккаунт сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить счётчики для административных функция
     */
    async getDashboardCounters(): Promise<AdminDashboardCountersDto> {
        try {
            const response = await this.axios.get("/admin");
            return Promise.resolve(response.data as AdminDashboardCountersDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}