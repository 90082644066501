import {DateTime} from 'luxon';

/**
 * Проверить является ли строка представлением даты в формате ISO 8601.
 * @param value строка содержащая дату в формате ISO 8601.
 * @returns признак действительно ли строка является представление даты в формате ISO 8601.
 */
const isIsoDateFormatString = (value: string): boolean => {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
    return isoDateRegex.test(value);
};

/**
 * Рекурсивная функция для обработки всех дат в данных и их преобразования в строку с учётом часового пояса.
 * @param data данные для преобразования
 * @param targetTimeZone Часовой пояс для преобразования даты
 * @returns Преобразованные данные
 */
export const convertDatesToUserTimezone = (data: any, targetTimeZone: string): any => {
    if (data === null || data === undefined || typeof data !== 'object') {
        return data;
    }

    for (const key of Object.keys(data)) {
        const value = data[key];
        if (typeof value === 'string' && isIsoDateFormatString(value)) {
            let dateTime = DateTime.fromISO(value, {setZone: true, zone: 'UTC'}); // Создаём объект с учётом временной зоны

            if (dateTime.isValid) {
                data[key] = dateTime.setZone(targetTimeZone).toFormat('yyyy-MM-dd HH:mm:ss');
            } else {
                console.error('Invalid DateTime:', value);
            }
        } else if (typeof value === 'object') {
            convertDatesToUserTimezone(value, targetTimeZone);
        }
    }

    return data;
};

/**
 * Рекурсивная функция для обработки всех дат в данных и их преобразования из строк в формате ISO в UTC.
 * @param data данные для преобразования
 * @param sourceTimeZone Часовой пояс пользователя
 * @returns Преобразованные данные
 */
export const convertDatesToUTC = (data: any, sourceTimeZone: string): any => {
    if (data === null || data === undefined || typeof data !== 'object') {
        return data;
    }

    for (const key of Object.keys(data)) {
        const value = data[key];
        if (typeof value === 'string' && isIsoDateFormatString(value)) {
            let dateTime = DateTime.fromISO(value, {zone: sourceTimeZone}); // Создаём объект с учётом пользовательской временной зоны

            if (dateTime.isValid) {
                data[key] = dateTime.setZone('UTC').toISO(); // Преобразуем дату в UTC в формате ISO
            } else {
                console.error('Invalid DateTime:', value);
            }
        } else if (typeof value === 'object') {
            convertDatesToUTC(value, sourceTimeZone);
        }
    }

    return data;
};