import {IResettable} from '../interfaces';
import {makeAutoObservable} from "mobx";
import {JsonProfile} from "../models/common";

export class JsonProfileStore implements IResettable {
    /**
     * Конструктор хранилища логов пользователя
     */
    constructor() {
        makeAutoObservable(this);
    }

    // Храним идентификатор пользователя
    private _userID: string = "";

    /**
     * Получение userID
     */
    get userID(): string {
        return this._userID;
    }

    // Храним профиль
    private _profile: JsonProfile | null = null;

    /**
     * Получение профиля
     */
    get profile(): JsonProfile | null {
        return this._profile;
    }

    /**
     * Сброс хранилища
     */
    reset(): void {
        this._userID = "";
        this._profile = null;
    }

    /**
     * Установка userID
     */
    setUserID(userID: string): void {
        this._userID = userID;
    }

    /**
     * Установка профиля
     */
    setProfile(profile: JsonProfile): void {
        this._profile = profile;
    }
}
