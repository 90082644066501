import {makeStyles} from "@fluentui/react-components";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column', // Убедитесь, что элементы располагаются вертикально
        alignItems: 'center', // Горизонтальное центрирование
        justifyContent: 'center', // Вертикальное центрирование
        height: '100%', // Занимает всю высоту ячейки
    },
    green: {
        width: '32px', // Добавлены единицы измерения
        height: '32px',
        color: 'green',
        borderRadius: '50%',
    },
    yellow: {
        width: '32px',
        height: '32px',
        color: 'yellow',
    },
    red: {
        width: '32px',
        height: '32px',
        color: 'red',
    },
    gray: {
        width: '32px',
        height: '32px',
        color: 'gray',
    },
    errorText: {
        color: 'red',
        marginTop: '4px', // Изменено на marginTop для вертикального отступа
        fontSize: '0.875rem',
    },
});

export default useStyles;
