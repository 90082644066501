import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Title3,
} from "@fluentui/react-components";
import UseStyle from "./StatsTopCountriesStyles";
import {useServices, useStore} from "../../hooks";
import {useToast} from "../../utils/ToastProvider";
import Flag from "react-flagkit";
import {observer} from "mobx-react-lite";
import {StatsTopCountriesProps} from "./StatsTopCountriesProps";
import {StatisticsQuerySharedDto} from "../../models/common/statistic/StatsQuerySharedDto";
import {StatsQueryDto} from "../../models/common";

/**
 * Компонент для отображения статистики по странам
 * @param props
 * @constructor
 */
const StatsTopCountries: React.FC<StatsTopCountriesProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [showAllCountries, setShowAllCountries] = useState(false);
    const styles = UseStyle();
    const {statisticsStore, statisticSharedStore} = useStore();
    const {showToast} = useToast();
    const {statisticService, statisticSharedService} = useServices();

    // Определяем источник данных
    const sourceStore = props.shared ? statisticSharedStore : statisticsStore;

    /**
     * Загрузка данных по странам
     */
    useEffect(() => {
        const loadFunc = async () => {
            try {
                setLoading(true);

                const body: StatisticsQuerySharedDto | StatsQueryDto = props.shared
                    ? {
                        startDate: statisticSharedStore.startDate,
                        endDate: statisticSharedStore.endDate,
                    }
                    : {
                        startDate: statisticsStore.startDate,
                        endDate: statisticsStore.endDate,
                        partnerName: statisticsStore.partnerName,
                        buildTag: statisticsStore.buildTag,
                    };


                if (showAllCountries) {
                    sourceStore.dataByCountry = props.shared
                        ? await statisticSharedService.getSharedAllCountries(statisticSharedStore.id, body)
                        : await statisticService.getAllCountriesSorted(body as StatsQueryDto);
                } else {
                    sourceStore.dataByCountry = props.shared
                        ? await statisticSharedService.getSharedTopCountries(statisticSharedStore.id, body)
                        : await statisticService.getTopThreeCountries(body as StatsQueryDto);
                }
            } catch (error: any) {
                showToast("Error!", error.message, "error");
            } finally {
                setLoading(false);
            }
        };

        loadFunc();
    }, [
        props.shared,
        showAllCountries,
        showToast,
        statisticService,
        statisticSharedService,
        statisticsStore,
        sourceStore,
        statisticSharedStore,
    ]);

    return (
        <Card className={styles.cardContainer}>
            {loading ? (
                <Spinner/>
            ) : (
                <>
                    <div className={styles.titleWithButton}>
                        <Title3>Top Countries</Title3>
                        {
                            !showAllCountries ? (
                                <Button onClick={() => setShowAllCountries(true)}>Show All</Button>
                            ) : (
                                <Button onClick={() => setShowAllCountries(false)}>Show Top 3</Button>
                            )
                        }
                    </div>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableCell>Country</TableCell>
                                <TableCell>Logs</TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {sourceStore.dataByCountry.length > 0 ? (
                                sourceStore.dataByCountry.map((country, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <div className={styles.countryItem}>
                                                <Flag country={country.country} size={20}/>
                                                {country.country}
                                            </div>
                                        </TableCell>
                                        <TableCell>{country.count}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2}>No Data</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </>
            )}
        </Card>
    );
};

/**
 * Имя отображаемое при отладке
 */
StatsTopCountries.displayName = "StatsTopCountries";
export default observer(StatsTopCountries);
