import React from "react";
import {Layout} from "../../../containers/Layout";
import {DataBrokerTable} from "../../../components/DataBrokerTable";

/**
 * Страница управления брокерами
 * @constructor
 */
const DataBrokerPage: React.FunctionComponent = () => {
    return (
        <Layout pageTittle={"Data brokers"}>
            <DataBrokerTable/>
        </Layout>);
}

/**
 * Имя отображаемое при отладке
 */
DataBrokerPage.displayName = "DataBrokerPage";

export default DataBrokerPage;