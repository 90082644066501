import React, {useCallback, useState} from "react";
import {Button, Input, Select, Spinner, Switch} from "@fluentui/react-components";
import {useServices, useStore} from "../../hooks";
import {StatsShareLinkDialogProps} from "./StatsShareLinkProps";
import {LoadingButton} from "../ButtonWithLoadingState";
import {useToast} from "../../utils/ToastProvider";
import ActionDialog from "../ActionDialog/ActionDialog";
import {CreateLinkDto} from "../../models/common"; // Предположим, что ActionDialog импортируется отсюда

const StatsShareLinkDialog: React.FC<StatsShareLinkDialogProps> = (props) => {
    const {statisticsStore} = useStore();
    const {statisticService} = useServices();
    const {showToast} = useToast();
    const [loading, setLoading] = useState(false);
    const [sharedLink, setSharedLink] = useState<string | null>(null);

    /**
     * Обработчик создания ссылки для публичного доступа к статистике
     */
    const onShareClickHandler = useCallback(async () => {
        setLoading(true);
        setSharedLink(null);
        try {
            const params: CreateLinkDto = {
                partnerId: statisticsStore.partnerName,
                buildTag: statisticsStore.buildTag,
                showEmpty: statisticsStore.showEmpty,
                showDup: statisticsStore.showDup
            };

            const resp = await statisticService.createLink(params);
            const fullLink = `${window.location.origin}/statistic/share/${resp}`;
            setSharedLink(fullLink);
        } catch (error: any) {
            showToast("Error.", error.message, "error");
        } finally {
            setLoading(false);
        }
    }, [statisticService, statisticsStore, showToast]);

    /**
     * Обработчик копирования в буфер обмена
     */
    const onCopyClick = useCallback(async () => {
        if (sharedLink) {
            try {
                await navigator.clipboard.writeText(sharedLink);
                showToast("Copied!", "Link is copied to clipboard", "success");
            } catch (error: any) {
                showToast("Error", "Failed to copy", "error");
            }
        }
    }, [sharedLink, showToast]);

    // Формируем контент диалога в зависимости от состояния
    let dialogContent: React.ReactNode;
    if (loading) {
        dialogContent = (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "200px"}}>
                <Spinner size="large"/>
            </div>
        );
    } else if (sharedLink) {
        dialogContent = (
            <div>
                <p style={{marginBottom: "1em"}}>Your link:</p>
                <Input readOnly value={sharedLink} style={{width: "100%"}}/>
            </div>
        );
    } else {
        dialogContent = (
            <div>
                <p style={{marginBottom: "1em"}}>Params for statistics:</p>
                <div style={{display: "flex", flexDirection: "column", gap: "1em", width: "100%"}}>
                    <div style={{display: "flex", alignItems: "center", gap: "1em", width: "100%"}}>
                        <label
                            htmlFor="dialog-partner-select"
                            style={{flexBasis: "100px", flexShrink: 0, textAlign: "right"}}
                        >
                            <b>Partner:</b>
                        </label>
                        <Select
                            id="dialog-partner-select"
                            style={{flexGrow: 1, maxWidth: "400px"}}
                            value={statisticsStore.partnerName}
                            onChange={(event) => (statisticsStore.partnerName = event.target.value)}
                        >
                            <option key={"all"} value={""}>All</option>
                            {props.partnersOptions.map((partner, index) => (
                                <option key={index} value={partner}>{partner}</option>
                            ))}
                        </Select>
                    </div>

                    <div style={{display: "flex", alignItems: "center", gap: "1em", width: "100%"}}>
                        <label
                            htmlFor="dialog-build-tag-select"
                            style={{flexBasis: "100px", flexShrink: 0, textAlign: "right"}}
                        >
                            <b>Build tag:</b>
                        </label>
                        <Select
                            id="dialog-build-tag-select"
                            style={{flexGrow: 1, maxWidth: "400px"}}
                            value={statisticsStore.buildTag}
                            onChange={(event) => (statisticsStore.buildTag = event.target.value)}
                        >
                            <option key={"all"} value={""}>All</option>
                            {props.buildTagOptions.map((tag, index) => (
                                <option key={index} value={tag}>{tag}</option>
                            ))}
                        </Select>
                    </div>

                    <div style={{
                        display: "flex",
                        width: "100%"
                    }}>
                        <Switch checked={statisticsStore.showEmpty}
                                label={"Show empty"}
                                onChange={(event) => statisticsStore.showEmpty = event.target.checked}/>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1em",
                        width: "100%"
                    }}>

                        <Switch
                            checked={statisticsStore.showDup}
                            label={"Show duplicates"}
                            onChange={(event) => statisticsStore.showDup = event.target.checked}
                        />
                    </div>
                </div>
                <p style={{
                    marginTop: "1.5em",
                    marginBottom: "1.5em",
                    width: "100%",
                    textAlign: "justify",
                    lineHeight: "1.5",
                }}>
                    Are you sure you want to share the statistics with these parameters?
                </p>
            </div>
        );
    }

    let dialogActions: React.ReactNode;
    if (loading) {
        dialogActions = (
            <>
                <Button onClick={() => props.onClose()} appearance="secondary">Cancel</Button>
            </>
        );
    } else if (sharedLink) {
        dialogActions = (
            <>
                <Button appearance={"primary"} onClick={onCopyClick}>Copy</Button>
                <Button onClick={() => props.onClose()}>Close</Button>
            </>
        );
    } else {
        dialogActions = (
            <>
                <Button onClick={() => props.onClose()} appearance="secondary">Cancel</Button>
                <LoadingButton
                    enabled={!loading}
                    appearance="primary"
                    buttonText={"Share"}
                    onClick={onShareClickHandler}
                />
            </>
        );
    }

    return (
        <ActionDialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            title="Share Statistics"
            content={dialogContent}
            actions={dialogActions}
            trigger={<></>}
        />
    );
};

StatsShareLinkDialog.displayName = "StatsShareLinkDialog";
export default StatsShareLinkDialog;
