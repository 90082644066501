import React, {useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Title3
} from "@fluentui/react-components";
import {useServices} from "../../../hooks";
import {DictDto} from "../../../models/admin/adminDashboard/dictDto";

/**
 * Страница словарей
 * @constructor
 */
const DictionariesPage: React.FC = () => {
    const services = useServices();
    const [dict, setDict] = useState<DictDto[]>([]);

    /**
     * Загрузка словарей при открытии страницы
     */
    useEffect(() => {
        (async () => {
            try {
                const dictionaries = await services.dictService.getDict();
                setDict(dictionaries);
            } catch (error: any) {
                console.error(error);
            }
        })();
    }, [services.dictService]);

    if (!dict) return <>Loading...</>;

    return (
        <Layout pageTittle={'Dictionaries'} anotherBackground={true}>
            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                {dict.map((dictionary, index) => (
                    <Card key={index} style={{padding: '20px', marginBottom: '20px'}}>
                        <Title3>{dictionary.type}</Title3>
                        <Table aria-label={`${dictionary.type} Table`}>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>Id</TableHeaderCell>
                                    <TableHeaderCell>Public Name</TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {dictionary.dictList.map(entry => (
                                    <TableRow key={entry.id}>
                                        <TableCell>{entry.id}</TableCell>
                                        <TableCell>{entry.publicName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Card>
                ))}
            </div>
        </Layout>
    );
}

DictionariesPage.displayName = "DictionariesPage";
export default DictionariesPage;
