/**
 * Возможные типа данных с ошибкой
 */
export enum DataErrorTypes {
    Base,
    Grabber,
    Browsers,
    Messenger,
    Soft,
    Wallets,
    Errors
}

// Объект для сопоставления числовых значений с именами
export const DataErrorTypeNames = {
    [DataErrorTypes.Base]: "Base",
    [DataErrorTypes.Grabber]: "Grabber",
    [DataErrorTypes.Browsers]: "Browsers",
    [DataErrorTypes.Messenger]: "Messenger",
    [DataErrorTypes.Soft]: "Soft",
    [DataErrorTypes.Wallets]: "Wallets",
    [DataErrorTypes.Errors]: "Errors",
};