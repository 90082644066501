import {ApiResult} from "../models";
import {CookiesRestoreDto} from "../models/common";
import {AxiosService} from "./axios";

/**
 * Сервис для восстановления cookies
 */
export class CookiesRestoreService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Запрос на восстановление cookies
     * @param body
     */
    async cookiesRestore(body: CookiesRestoreDto): Promise<ApiResult<string>> {
        try {
            return await this.axios.post("/restore-cookie/restore", body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}