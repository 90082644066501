import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    gridContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "100%",  // Ограничить контейнер на 100% ширины
        height: "100%",  // Включает адаптацию по высоте
        overflow: "hidden",  // Предотвращает выход контента за пределы
    },
    dataGrid: {
        flexGrow: 1,  // Таблица займет доступное пространство в контейнере
        width: "100%",
        minWidth: '1175px',
        overflow: "auto",  // Добавляем прокрутку по таблице
        maxWidth: "100%",  // Таблица адаптируется по ширине
        tableLayout: "auto",
    },
    duplicate: {
        backgroundColor: "rgba(177,172,155,0.56)"
    },
    downloadedDuplicate: {
        backgroundColor: "rgba(177,172,155,0.56)",
        fontWeight: "normal"
    },
    downloaded: {
        fontWeight: "normal"
    },
    normalRow: {
        fontWeight: "bold",
        whiteSpace: "nowrap",  // Избегаем переноса текста
    },
    summaryBadges: {
        display: "flow",
        gap: "0.2em",
    },
    badgeDuplicate: {
        backgroundColor: "black",
        boxShadow: "0.1em"
    },
    badgeSpacing: {
        margin: "0.3em",
    },
    tableRow: {
        whiteSpace: "nowrap",  // Запрет переноса текста
    },
    tableCell: {
        padding: "0.5em",  // Пространство между содержимым и границей ячейки
        overflow: "hidden",  // Предотвращение переполнения
        textOverflow: "ellipsis",  // Усечение длинного текста
    },
});
