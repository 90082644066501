import React from "react";
import {Layout} from "../../../containers/Layout";
import {LogList} from "../../../components/LogsList";
import LogFilters from "../../../components/LogsList/LogFilters/LogFilters";
import LogListTools from "./LogListTools";

/**
 * Страница отображения списка логово пользователя
 * @constructor
 */
const LogListPage: React.FC = () => {
    return (
        <Layout pageTittle={"Log list"} anotherBackground={true}>
            <LogListTools/>
            <LogFilters/>
            <LogList/>
        </Layout>
    );
}

/**
 * Имя отображаемое для отладки
 */
LogListPage.displayName = "LogListPage";

export default LogListPage;