import {Button, Card, Input, Label, Spinner, Title2} from "@fluentui/react-components";
import {Key16Regular, PersonRegular} from "@fluentui/react-icons";
import {useFormik} from "formik";
import React, {useState} from "react";
import {validationSchema,} from "./AuthorizationForm.validation";
import useStyle from "./AuthorizationFromStyle";
import {useServices, useStore} from "../../../hooks";
import {useNavigate} from "react-router-dom";
import {GetMessageByCode} from "../../../utils";
import {ErrorCode} from "../../../models/shared/ErrorCode";
import {LoadingState} from "../../../models";
import {useToast} from "../../../utils/ToastProvider";

/**
 * Страница авторизации.
 */
const AuthorizationPage: React.FC = () => {
    const styles = useStyle();
    const navigate = useNavigate();
    const services = useServices();
    const store = useStore();
    const {showToast} = useToast();
    const [loadingState, setLoadingState] = useState<LoadingState>("initial");
    const [disableLoginButton, setDisableLoginButton] = useState(false);
    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        onSubmit: () => {
            
        },
        validationSchema,
    });

    /**
     * Выбора иконки для кнопки на основе состояния
     */
    const buttonIcon =
        loadingState === "loading" ? (
            <Spinner
                size="tiny"
                appearance="inverted"
            />
        ) : null;

    /**
     * Выбора текста кнопке на основе состояния
     */
    const buttonContent =
        loadingState === "loading"
            ? " "
            : loadingState === "loaded"
                ? "Login"
                : "Login";


    /**
     * Обработчик клика по кнопке "Login"
     * Обработчик клика по кнопке "Login"
     */
    const onLoginClick = async () => {
        try {
            setDisableLoginButton(true);
            setLoadingState("loading");
            store.loaderStore.setLoading(true);

            await services.authorizationService.signIn({
                Username: formik.values.username,
                Password: formik.values.password,
            });

            store.userStore.setAuthorization(true);

            navigate("/");
        } catch (error: any) {
            const message = error.code || ErrorCode.UnexpectedError;
            showToast('Authorization failed!', GetMessageByCode(message), "error");
        } finally {
            setLoadingState("loaded");
            setDisableLoginButton(false);
            store.loaderStore.setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <Title2 className={styles.titleAcr}>ACR</Title2>
            <Card className={styles.card} appearance="filled" size="large">
                <Label>Login</Label>
                <Input
                    contentBefore={<PersonRegular/>}
                    type="text"
                    id="username"
                    placeholder="example@user"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                ></Input>

                <Label>Password</Label>
                <Input
                    id="password"
                    contentBefore={<Key16Regular/>}
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                ></Input>

                <Button
                    type="submit"
                    appearance="primary"
                    onClick={onLoginClick}
                    icon={buttonIcon}
                    disabledFocusable={disableLoginButton}
                >
                    {buttonContent}
                </Button>
            </Card>
        </div>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
AuthorizationPage.displayName = "AuthorizationPage";

export default AuthorizationPage;