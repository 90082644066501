import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    listContainer: {
        display: "flex", gap: "1em", flexDirection: "column"
    },
    addToolContainer: {
        display: "flex", flexDirection: "row", gap: "1em"
    }
});
