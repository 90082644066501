import React from "react";
import {Layout} from "../../../containers/Layout";
import JsonProfilesList from "../../../components/JsonProfilesList";

/**
 * Страница управления профилями пользователей
 * @constructor
 */
const JsonProfilesPage: React.FC = () => {
    return (
        <Layout pageTittle={"JSON Profiles"}>
            <JsonProfilesList/>
        </Layout>
    );
};

/**
 * Имя отображаемое при отладке
 */
JsonProfilesPage.displayName = "JsonProfilePage";

export default JsonProfilesPage;