// src/services/adminFeatureService.ts

import {AxiosService} from "./axios";
import {FeatureDto} from "../models/admin/Features/FeatureDto";

export class AdminFeatureService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить все фичи
     */
    async getAll(): Promise<FeatureDto[]> {
        try {
            const response = await this.axios.get<FeatureDto[]>(`admin/features`);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Обновить состояние фичи (включить или отключить)
     * @param featureName Название фичи
     * @param state Новое состояние фичи (true - включить, false - отключить)
     */
    async updateFeatureState(featureName: string, state: boolean): Promise<void> {
        const endpoint = state ? 'admin/features/turn_on' : 'admin/features/turn_off';
        try {
            await this.axios.post(endpoint, {featureName});
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
