import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import StatsSharedTools from "./StatsSharedTools";
import StatsTopCountries from "../../../components/StatsTopCountries/StatsTopCountries";
import {StatsLogData} from "../../../components/StatsLogData";
import {useStore} from "../../../hooks";

const StatisticsSharePage: React.FC = () => {
    const {id} = useParams();
    const {statisticSharedStore} = useStore()
    const navigate = useNavigate();

    if (id != null) {
        statisticSharedStore.id = id;
    } else {
        navigate("/");
    }

    return (
        <div>
            <StatsSharedTools/>
            <div style={{margin: '1em', gap: "1em", display: 'flex', flexFlow: 'row'}}>
                <StatsTopCountries shared={true}/>
                <StatsLogData shared={true}/>
            </div>
        </div>
    );
};

export default StatisticsSharePage;
