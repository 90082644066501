import {AxiosService} from "./axios";
import {AV_SCAN_URL} from "../shared/constUrl";

export class AvScanService {

    /**
     * Сервис axios
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Запрос нового сканирования брокеров в АВ
     */
    async reScan(): Promise<void> {
        try {
            await this.axios.get(`${AV_SCAN_URL}/re-scan`)
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}