import {AxiosService} from "./axios";

/**
 * Сервис для работы с мониторингом очереди
 */
export class AdminQueueState {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получение данных об очереди
     */
    async getQueue() {
        try {
            const response = await this.axios.get("/admin/get-queue-state");
            return Promise.resolve(response.data as Record<string, number>);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
