import React from "react";
import {Layout} from "../../../containers/Layout";
import {WalletsList, WalletsTools} from "../../../components/wallets";

/**
 * Страница "Кошельки"
 * @constructor
 */
const WalletsPage: React.FC = () => {
    return (
        <Layout pageTittle={"Wallets"} anotherBackground={true}>
            <WalletsTools/>
            <WalletsList/>
        </Layout>
    );
}

/**
 * Имя отображаемое при отладке
 */
WalletsPage.displayName = 'WalletsPage';

export default WalletsPage;
