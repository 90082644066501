import React from "react";
import {Layout} from "../../../containers/Layout";
import useStyle from "./StatisticPageStyle";
import {StatsLogData} from "../../../components/StatsLogData";
import StatisticPageTools from "./StatisticPageTools";
import StatsTopCountries from "../../../components/StatsTopCountries/StatsTopCountries";

/**
 * Компонент-страница "Билдер"
 * @constructor
 */
const StatisticPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Statistic"} anotherBackground={true}>
        <StatisticPageTools/>
        <div className={styles.pageContainer}>
            <StatsTopCountries shared={false}/>
            <StatsLogData shared={false}/>
        </div>
    </Layout>);
}

StatisticPage.displayName = "StatisticPage";
export default StatisticPage;