import React, {useCallback} from "react";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerHeaderTitle,
    Field,
    Input,
    Switch
} from "@fluentui/react-components";
import {DatePicker} from "@fluentui/react-datepicker-compat";
import {TimePicker, TimeSelectionData, TimeSelectionEvents,} from "@fluentui/react-timepicker-compat";
import useStyle from './LogFiltersStyle';
import MultipleTagPicker from "../../TagPicker/TagPicker";
import {useServices, useStore} from "../../../hooks";
import {Dismiss24Regular, FilterDismiss20Regular, Search20Regular} from "@fluentui/react-icons";
import {observer} from "mobx-react-lite";
import {useToast} from "../../../utils/ToastProvider";

/**
 * Компонент фильтрации логов
 * @constructor
 */
const LogFilters: React.FC = () => {
    const style = useStyle();
    const store = useStore();
    const services = useServices();
    const {showToast} = useToast();

    /**
     * Форматирование даты/времени в строку HH:MM
     */
    const formatTime = useCallback((time: Date | null): string => {
        if (!time) return '';
        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }, []);

    /**
     * Обработчик нажатия кнопки "Поиск"
     */
    const searchClickHandler = useCallback(async () => {
        try {
            const response = await services.logsService.getLogsList(store.logsStore.params)
            store.logsStore.updateData(response);
            store.logsStore.isFilterOpen = false;
            store.logsStore.isFilterApplied = true;
        } catch (error: any) {
            showToast("Error", error.message, "error");
        }
    }, [services.logsService, store.logsStore, showToast]);

    /**
     * Обработчик изменения стартовой даты
     */
    const handleStartDateChange = useCallback((date: Date | undefined | null) => {
        store.logsStore.params.startDate = date;
    }, [store.logsStore]);

    /**
     * Обработчик изменения конечной даты
     */
    const handleEndDateChange = useCallback((date: Date | undefined | null) => {
        store.logsStore.params.endDate = date;
    }, [store.logsStore]);

    /**
     * Обработчик изменения начального времени
     */
    const handleStartTimeChange = useCallback((_: TimeSelectionEvents, data: TimeSelectionData) => {
        // if (data.selectedTime) {
        //     store.logsStore.startTime = data.selectedTime;    // синхронизируем
        //     if (store.logsStore.params.startDate) {
        //         const date = new Date(store.logsStore.params.startDate);
        //         date.setHours(data.selectedTime.getHours());
        //         date.setMinutes(data.selectedTime.getMinutes());
        //         store.logsStore.params.startDate = date;
        //     } else {
        //         store.logsStore.params.startDate = data.selectedTime;
        //     }
        // }

        if (data.selectedTime) {
            store.logsStore.startTime = data.selectedTime;
            store.logsStore.params.startDate = data.selectedTime;
        }
    }, [store.logsStore]);

    /**
     * Обработчик изменения конечного времени
     */
    const handleEndTimeChange = useCallback((_: TimeSelectionEvents, data: TimeSelectionData) => {
        // if (data.selectedTime) {
        //     store.logsStore.endTime = data.selectedTime;    // синхронизируем
        //     if (store.logsStore.params.endDate) {
        //         const date = new Date(store.logsStore.params.endDate);
        //         date.setHours(data.selectedTime.getHours());
        //         date.setMinutes(data.selectedTime.getMinutes());
        //         store.logsStore.params.endDate = date;
        //     } else {
        //         store.logsStore.params.endDate = data.selectedTime;
        //     }
        // }
        if (data.selectedTime) {
            store.logsStore.endTime = data.selectedTime;
            store.logsStore.params.endDate = data.selectedTime;
        }
    }, [store.logsStore]);

    // Если данные приходят в виде строки, преобразуем их в объект Date
    const handleOnEndTimeInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const timeString = ev.target.value;

        // Если строка не пустая и имеет корректный формат
        if (timeString && /^([0-1]?[0-9]|2[0-3]):([0-5]?[0-9])$/.test(timeString)) {
            // Преобразуем в объект Date
            const [hours, minutes] = timeString.split(':').map(Number);
            const time = new Date();
            time.setHours(hours, minutes, 0, 0); // Устанавливаем часы и минуты, секунд и миллисекунд в 0
            store.logsStore.endTime = time;
        }
    }, [store.logsStore]);

    /**
     * Обработчик кнопки сброса фильтров
     */
    const handleClearFilters = useCallback(async () => {
        store.logsStore.setDefaultParams();
        await searchClickHandler();
        store.logsStore.isFilterApplied = false;
    }, [store.logsStore, searchClickHandler]);

    return (
        <Drawer
            size={"medium"}
            type={"overlay"}
            separator
            open={store.logsStore.isFilterOpen}
            position={"end"}
        >
            <DrawerHeader style={{padding: "0.8em", scrollbarWidth: "thin"}}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular/>}
                            onClick={() => store.logsStore.isFilterOpen = false}
                        />
                    }
                >
                    Filters
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody>
                <div className={style.dateTimeItemContainer}>
                    <Field label="Select a start date">
                        <DatePicker onSelectDate={handleStartDateChange}
                                    placeholder={"Select a start date..."}
                                    showGoToToday={true}
                                    value={store.logsStore.params.startDate}/>
                    </Field>
                    <Field label="Select a start time">
                        <TimePicker hourCycle={"h23"}
                                    increment={1}
                                    placeholder="Select a start time..."
                                    onTimeChange={handleStartTimeChange}
                                    value={store.logsStore.startTime ? formatTime(store.logsStore.startTime) : ''}
                                    selectedTime={store.logsStore.params.startDate}
                        />
                    </Field>
                </div>
                <div className={style.dateTimeItemContainer}>
                    <Field label="Select a end date">
                        <DatePicker onSelectDate={handleEndDateChange}
                                    value={store.logsStore.params.endDate}
                                    placeholder="Select a end date..."
                                    showGoToToday={true}

                        />
                    </Field>
                    <Field label="Select a end time">
                        <TimePicker hourCycle={"h23"}
                                    increment={1}
                                    selectedTime={store.logsStore.endTime ? new Date(store.logsStore.endTime) : null}
                                    value={store.logsStore.endTime ? formatTime(store.logsStore.endTime) : ''}
                                    onTimeChange={handleEndTimeChange}
                                    placeholder="Select a end time..."
                                    clearable={true}
                                    onInput={handleOnEndTimeInput}
                        />
                    </Field>
                </div>
                <Field label="Log ID">
                    <div className={style.logRangeSelectContainer}>
                        <Input onChange={(_ev, data) => {
                            store.logsStore.params.startId = Number(data.value);
                        }}
                               min={0}
                               type={"number"}
                               placeholder={'Start ID'}
                               value={(store.logsStore.params.startId) ? store.logsStore.params.startId.toString() : ''}
                        />
                        <Input onChange={(_ev, data) => {
                            store.logsStore.params.endId = Number(data.value);
                        }}
                               type={"number"}
                               placeholder={'End ID'}
                               value={(store.logsStore.params.endId) ? store.logsStore.params.endId.toString() : ''}
                        />
                    </div>
                </Field>
                <Field label="IP">
                    <Input type={"text"}
                           placeholder={"IP"}
                           onChange={(_ev, data) => {
                               store.logsStore.params.ipAddress = data.value;
                           }}
                           value={store.logsStore.params.ipAddress}
                    />
                </Field>
                <Field label="Cookie keyword">
                    <Input type={"text"}
                           onChange={(_ev, data) => {
                               store.logsStore.params.cookieKeyword = data.value;
                           }}
                           value={store.logsStore.params.cookieKeyword}
                    />
                </Field>
                <Field label="Password keyword">
                    <Input type={"text"}
                           onChange={(_ev, data) => {
                               store.logsStore.params.passwordKeyword = data.value;
                           }}
                           value={store.logsStore.params.passwordKeyword}
                    />
                </Field>
                <MultipleTagPicker labelText={"By Tag"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.tagsList = values;
                                   }}
                                   options={store.logsStore.options.tagOptions}
                                   selected={store.logsStore.params.tagsList ?? []}
                />
                <MultipleTagPicker labelText={"By Country"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.countryList = values;
                                   }}
                                   options={store.logsStore.options.countryOptions}
                                   selected={store.logsStore.params.countryList ?? []}
                />
                <MultipleTagPicker labelText={"By Wallet"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.walletsList = values;
                                   }}
                                   options={store.logsStore.options.walletNames}
                                   selected={store.logsStore.params.walletsList ?? []}
                />
                <MultipleTagPicker labelText={"By ext-Wallet"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.walletExtensionsList = values;
                                   }}
                                   options={store.logsStore.options.extWalletNames}
                                   selected={store.logsStore.params.walletExtensionsList ?? []}
                />
                <MultipleTagPicker labelText={"By PM/2Fa ext"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.pm2FaExtensionsList = values;
                                   }}
                                   options={store.logsStore.options.pmExtNames}
                                   selected={store.logsStore.params.pm2FaExtensionsList ?? []}
                />
                <MultipleTagPicker labelText={"By build"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.buildTagList = values;
                                   }}
                                   options={store.logsStore.options.buildTags}
                                   selected={store.logsStore.params.buildTagList ?? []}
                />
                <MultipleTagPicker labelText={"By partner"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.partnerList = values;
                                   }} options={store.logsStore.options.referralUsers.map(x => x.username)}
                                   selected={store.logsStore.params.partnerList ?? []}
                />

                <Field label={"Required"}>
                    <Switch label={"Wallets"}
                            checked={store.logsStore.params.byWallets}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byWallets = data.checked
                            }}
                    />
                    <Switch label={"Ext-wallets"}
                            checked={store.logsStore.params.byWalletExtensions}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byWalletExtensions = data.checked
                            }}
                    />
                    <Switch label={"Soft"}
                            checked={store.logsStore.params.bySoft}
                            onChange={(_ev, data) => {
                                store.logsStore.params.bySoft = data.checked
                            }}
                    />
                    <Switch label={"Messenger"}
                            checked={store.logsStore.params.byMessenger}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byMessenger = data.checked
                            }}
                    />
                    <Switch label={"CC"}
                            checked={store.logsStore.params.byCreditCard}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byCreditCard = data.checked
                            }}
                    />
                    <Switch label={"Not downloaded"}
                            checked={store.logsStore.params.notDownloaded}
                            onChange={(_ev, data) => {
                                store.logsStore.params.notDownloaded = data.checked
                            }}
                    />
                    <Switch label={"With notes"}
                            checked={store.logsStore.params.byNotes}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byNotes = data.checked
                            }}
                    />
                </Field>
            </DrawerBody>
            <DrawerFooter style={{padding: "1em"}}>
                <Button appearance={"primary"}
                        icon={<Search20Regular/>}
                        onClick={searchClickHandler}>
                    Search
                </Button>
                <Button appearance={"secondary"}
                        icon={<FilterDismiss20Regular/>}
                        onClick={handleClearFilters}>
                    Clear
                </Button>
            </DrawerFooter>
        </Drawer>
    );
}

/**
 * Имя отображаемое при отладке
 */
LogFilters.displayName = "LogFilters";

export default observer(LogFilters);