import {makeAutoObservable, runInAction} from "mobx";
import {UserSettingsDto} from "../models/common/user/UserSettingsDto";
import {UserSettingsService} from "../services/userSettings";
import {USER_SETTINGS} from "../shared/constString";

export class UserSettingsStore {
    /**
     * Текущие настройки пользователя (глобально доступные)
     */
    userSettings: UserSettingsDto | null = null;
    /**
     * Загружаются ли настройки
     */
    isLoading: boolean = false;
    /**
     * Локальная переменная для хранения настроек в памяти
     * @private
     */
    private memoryCache: UserSettingsDto | null = null;

    /**
     * Сервис для взаимодействия с бэкендом
     * @private
     */
    private readonly service: UserSettingsService;

    constructor(service: UserSettingsService) {
        makeAutoObservable(this);
        this.service = service;
    }

    /**
     * Ленивая загрузка настроек
     */
    async loadSettings(): Promise<UserSettingsDto> {
        // 1. Проверяем память
        if (this.memoryCache) {
            return Promise.resolve(this.memoryCache);
        }

        // 2. Проверяем localStorage
        const cachedSettings = localStorage.getItem(USER_SETTINGS);
        if (cachedSettings) {
            const parsedSettings = JSON.parse(cachedSettings) as UserSettingsDto;

            // Сохраняем в память
            this.memoryCache = parsedSettings;

            runInAction(() => {
                this.userSettings = parsedSettings;
            });

            return Promise.resolve(parsedSettings);
        }

        // 3. Если данных нет, идем на сервер
        this.isLoading = true;
        try {
            const fetchedSettings = await this.service.fetchSettings();

            // Сохраняем в память и localStorage
            this.memoryCache = fetchedSettings;
            localStorage.setItem(USER_SETTINGS, JSON.stringify(fetchedSettings));

            runInAction(() => {
                this.userSettings = fetchedSettings;
            });

            return fetchedSettings;
        } catch (error) {
            console.error("Failed to load user settings:", error);
            throw error;
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    /**
     * Обновить настройки
     */
    async updateSettings(updatedSettings: UserSettingsDto): Promise<void> {
        try {
            await this.service.updateSettings(updatedSettings);

            // Обновляем в памяти, localStorage и состоянии
            this.memoryCache = updatedSettings;
            localStorage.setItem(USER_SETTINGS, JSON.stringify(updatedSettings));

            runInAction(() => {
                this.userSettings = updatedSettings;
            });
        } catch (error) {
            console.error("Failed to update user settings:", error);
            throw error;
        }
    }

    /**
     * Принудительно обновить настройки из сервера
     */
    async refreshSettings(): Promise<void> {
        try {
            const fetchedSettings = await this.service.fetchSettings();

            // Сохраняем в память и localStorage
            this.memoryCache = fetchedSettings;
            localStorage.setItem(USER_SETTINGS, JSON.stringify(fetchedSettings));

            runInAction(() => {
                this.userSettings = fetchedSettings;
            });
        } catch (error) {
            console.error("Failed to refresh user settings:", error);
            throw error;
        }
    }

    /**
     * Сбросить настройки (например, при выходе пользователя из системы)
     */
    clearSettings(): void {
        this.memoryCache = null;
        localStorage.removeItem(USER_SETTINGS);
        runInAction(() => {
            this.userSettings = null;
        });
    }
}
