import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    actionsButtonsContainer: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    profileTable: {
        minWidth: "620px"
    }
});