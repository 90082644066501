import React, {useCallback, useEffect, useRef, useState} from "react";
import {ArrowDownload20Regular} from "@fluentui/react-icons";
import {LoadingButton} from "../../ButtonWithLoadingState";
import {LogDownloadButtonProps} from "./LogDownloadButtonProp";
import {ApiError, LoadingState} from "../../../models";
import {Spinner, Toast, ToastBody, Toaster, ToastTitle, useId, useToastController,} from "@fluentui/react-components";
import {GetMessageByCode} from "../../../utils";
import {useServices} from "../../../hooks";

/**
 * Кнопка скачивания лога
 */
const LogDownloadButton: React.FC<LogDownloadButtonProps> = (props) => {
    const [loading, setLoading] = useState<LoadingState>("initial");
    const [requestId, setRequestId] = useState<string | null>(null);
    const toasterId = useId(`toaster-${props.logId}`);
    const {dispatchToast} = useToastController(toasterId);
    const {fileDownloadService} = useServices();

    // Используем useRef для хранения флага
    const isDownloadTriggered = useRef(false);

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        dispatchToast(
            <Toast>
                <ToastTitle>Error!</ToastTitle>
                <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
            </Toast>,
            {intent: "error"}
        );
    }, [dispatchToast])

    /**
     * Периодическая проверка статуса файла
     */
    useEffect(() => {
        if (!requestId) return;

        const intervalId = setInterval(async () => {
            try {
                const status = await fileDownloadService.checkFileStatus(requestId);
                if (status === "Done" && !isDownloadTriggered.current) {
                    isDownloadTriggered.current = true; // Устанавливаем флаг
                    clearInterval(intervalId); // Останавливаем интервал
                    setLoading("loaded");

                    dispatchToast(
                        <Toast>
                            <ToastTitle>File Ready!</ToastTitle>
                            <ToastBody>Download successfully.</ToastBody>
                        </Toast>,
                        {intent: "success"}
                    );

                    // Начать скачивание файла
                    fileDownloadService.downloadFile(`/downloads/get/${requestId}`);
                }
            } catch (error) {
                clearInterval(intervalId); // Завершаем интервал в случае ошибки
                setLoading("error");
                showErrorNotify(error as ApiError);
            }
        }, 5000); // Проверяем статус каждые 5 секунд

        return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
    }, [requestId, fileDownloadService, dispatchToast, showErrorNotify]);

    /**
     * Обработчик клика по кнопке
     */
    const onClickHandler = async () => {
        try {
            setLoading("loading");
            const id = await fileDownloadService.requestLogFiles(props.logId);
            setRequestId(id);
            isDownloadTriggered.current = false; // Сбрасываем флаг при новом запросе

            dispatchToast(
                <Toast>
                    <ToastTitle media={<Spinner size="tiny"/>}>
                        Generating ZIP archive...
                    </ToastTitle>
                    <ToastBody>Please wait while the file is being prepared.</ToastBody>
                </Toast>
            );
        } catch (error: any) {
            setLoading("error");
            showErrorNotify(error as ApiError);
        }
    };

    return (
        <>
            <Toaster toasterId={toasterId}/>
            <LoadingButton
                aria-label="Download"
                appearance="primary"
                icon={<ArrowDownload20Regular/>}
                state={loading}
                enabled={loading !== "loading"}
                onClick={onClickHandler}
            />
        </>
    );
};

/**
 * Имя отображаемое во время отладки
 */
LogDownloadButton.displayName = "LogDownloadButton";

export default LogDownloadButton;
