/**
 * Проверяет являться ли строка GUID
 * @param value
 */
export const isGuid = (value: string | null | undefined): boolean => {
    if (!value) return false;
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(value);
};

/**
 * Проверка валидности URL
 * @param url
 */
export const isUrlValid = (url: string) => {
    const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // Протокол
        "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // Домен
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // IP
        "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // Путь
        "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // Параметры
        "(\\#[-a-zA-Z\\d_]*)?$",
        "i"
    );
    return urlPattern.test(url);
};
