import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders,
    AxiosResponse,
    InternalAxiosRequestConfig
} from 'axios';
import {ApiResult, Errors} from '../../models';
import {UpdateAccessTokenRequestDto} from '../../models/';
import {convertDatesToUserTimezone, convertDatesToUTC} from "../../utils";
import {USER_SETTINGS} from "../../shared/constString";

/**
 * Сервис, отвечающий за обращение к API.
 */
class AxiosBase {
    /**
     * Экземпляр Axios
     */
    private readonly axios: AxiosInstance;

    /**
     * Обработка обновления токенов
     * @private
     */
    private refreshTokenPromise: Promise<void> | null = null;

    /**
     * Событие установки флага загрузки
     */
    private readonly onLoading?: (loading: boolean) => void;

    /**
     * Обратный вызов успешной аутентификации
     */
    private readonly onSignInSuccess: (access_token: string, refresh_token: string) => void;

    /**
     * Обратный вызов ошибки аутентификации
     */
    private readonly onSignInFailed: () => void;

    /**
     * Метод получения токена обновления
     */
    private readonly getRefreshToken: () => string;

    /**
     * Метод получения токена доступа
     */
    private readonly getAccessToken: () => string;

    /**
     * Метод получения идентификатора пользователя
     */
    private readonly getUserId: () => string;


    private readonly timezone: string;

    /**
     * Базовый URL
     */
    private readonly baseUrl: string;

    constructor(
        baseURL: string,
        onSignInSuccess: (access_token: string, refresh_token: string) => void,
        onSignInFailed: () => void,
        getRefreshToken: () => string,
        getAccessToken: () => string,
        getUserId: () => string,
        onLoading?: (loading: boolean) => void,
    ) {
        const config: AxiosRequestConfig = {baseURL};
        this.onSignInFailed = onSignInFailed;
        this.onSignInSuccess = onSignInSuccess;
        this.getRefreshToken = getRefreshToken;
        this.getAccessToken = getAccessToken;
        this.onLoading = onLoading;
        this.getUserId = getUserId;
        config.withCredentials = true
        this.baseUrl = baseURL;
        this.axios = axios.create(config);
        this.axios.interceptors.request.use(this.requestInterceptor.bind(this), (error) => Promise.reject(error));
        this.axios.interceptors.response.use(this.responseSuccess.bind(this), this.responseFailed.bind(this));
        const timezoneString = localStorage.getItem(USER_SETTINGS) || '{"timeZone":"UTC"}';
        const userSettings = JSON.parse(timezoneString);
        this.timezone = userSettings.timeZone || 'UTC';
    }

    responseSuccess(response: AxiosResponse<any, any>) {
        if (this.onLoading) this.onLoading(false);
        if (response.data.error) {
            return Promise.reject(response.data.error);
        }

        const contentDisposition = response.headers ? response.headers['content-disposition'] || response.headers['Content-Disposition'] : null;

        // Возвращаем весь объект ответа, если есть заголовок Content-Disposition
        if (contentDisposition) {
            return Promise.resolve(response);
        }
        // Преобразуем все даты в ответе
        convertDatesToUserTimezone(response.data, this.timezone);  // Преобразуем даты в ответе
        // В противном случае, возвращаем только данные
        return Promise.resolve(response.data);
    }

    /**
     * Функция обработки неудачного ответа
     * @param error
     */
    async responseFailed(error: AxiosError<Errors>) {
        if (this.onLoading) this.onLoading(false);

        if (error.code === AxiosError.ERR_BAD_REQUEST && error.response?.status === 401) {
            // Если есть текущий запрос на обновление токена, ждем его завершения
            if (this.refreshTokenPromise) {
                await this.refreshTokenPromise;
                return this.axios(error.config as AxiosRequestConfig); // Повторяем оригинальный запрос
            }

            // Иначе начинаем новый запрос на обновление токена
            this.refreshTokenPromise = this.refreshTokens();

            try {
                await this.refreshTokenPromise;
                this.refreshTokenPromise = null;
                return this.axios(error.config as AxiosRequestConfig); // Повторяем оригинальный запрос
            } catch (err) {
                this.refreshTokenPromise = null;
                this.onSignInFailed();
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }

    /**
     * Отправить POST запрос
     * @param url целевой адрес
     * @param body тело запроса
     * @param headers заголовки запроса
     */
    post<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        if (this.onLoading) this.onLoading(true);

        return headers ?
            this.axios.post(url, body, {headers: headers} as AxiosRequestConfig) :
            this.axios.post(url, body);
    }

    /**
     * Отправить PATCH запрос
     * @param url целевой адрес
     * @param body тело запроса
     * @param headers заголовки запроса
     */
    patch<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        if (this.onLoading) this.onLoading(true);

        return headers ?
            this.axios.patch(url, body, {headers: headers} as AxiosRequestConfig) :
            this.axios.patch(url, body);
    }

    /**
     * Отправить PUT запрос
     * @param url целевой адрес
     * @param body тело запроса
     * @param headers заголовки запроса
     */
    put<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        if (this.onLoading) this.onLoading(true);

        return headers ?
            this.axios.put(url, body, {headers: headers} as AxiosRequestConfig) :
            this.axios.put(url, body);
    }

    /**
     * Отправить GET запрос
     * @param url целевой адрес
     * @param queryParams параметры запроса
     * @param headers заголовки запроса
     */
    get<T>(url: string, queryParams: any = undefined, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        const config: AxiosRequestConfig = {
            params: queryParams,
            headers: headers
        };

        return this.axios.get(url, config);
    }

    /**
     * Метод для скачивания фалов. Использовать только для этого
     * @param url Адрес файла
     * @param queryParams Параметры адресной строки
     * @param headers Заголовки
     */
    getFile(url: string, queryParams: any = undefined, headers?: AxiosRequestHeaders): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            params: queryParams,
            headers: headers,
            responseType: 'blob'
        };

        return this.axios.get(url, config);
    }

    /**
     * Метод для выгрузки фалов. Использовать только для этого
     * @param url Адрес файла
     * @param formData данные для отправки
     * @param queryParams Параметры адресной строки
     */
    uploadFile(url: string, formData: FormData, queryParams: any = undefined): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            params: queryParams,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        return this.axios.post(url, formData, config);
    }

    /**
     * Отправить DELETE запрос
     * @param url целевой адрес
     * @param queryParams параметры запроса
     * @param headers заголовки запроса
     */
    delete<T>(url: string, queryParams: any = undefined, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        const config: AxiosRequestConfig = {
            params: queryParams,
            headers: headers
        };

        return this.axios.delete(url, config);
    }

    /**
     * Обновление токена
     * @private
     */
    private async refreshTokens(): Promise<void> {
        let refreshToken = this.getRefreshToken() || '';

        if (!refreshToken) {
            this.onSignInFailed();
            throw new Error('Refresh token is missing');
        }

        let userId = this.getUserId() || '';

        if (!userId) {
            this.onSignInFailed();
            throw new Error('User ID is missing');
        }

        const body: UpdateAccessTokenRequestDto = {refreshToken: refreshToken, userId: this.getUserId()};
        const options: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
            baseURL: this.baseUrl,
        };

        const tokenResponse = await axios.post('auth/refresh', body, options);

        if (tokenResponse.status === 200) {
            this.onSignInSuccess(tokenResponse.data.data.accessToken, tokenResponse.data.data.refreshToken);
        } else {
            throw new Error('Failed to refresh tokens');
        }
    }

    /**
     * Интерцептор подцепляет нужные заголовки к запросу
     * @param config
     * @returns
     */
    private requestInterceptor = async (config: InternalAxiosRequestConfig<any>) => {
        config.withCredentials = true;

        let accessToken = this.getAccessToken();

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (!config.headers['Content-Type']) {
            config.headers['Content-Type'] = 'application/json';
        }

        // Преобразование данных запроса из пользовательского часового пояса в UTC
        if (config.data && typeof config.data === 'object') {
            config.data = convertDatesToUTC(config.data, this.timezone);
        }

        return config;
    };
}

export default AxiosBase;
