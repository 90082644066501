import React from "react";
import {Layout} from "../../../containers/Layout";
import {ChangePasswordForm} from "../../../forms/ChangePasswordForm";
import useStyle from "./ChangePasswordPageStyle";

/**
 * Компонент-страница "Смена пароля"
 * @constructor
 */
const ChangePasswordPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Change password"} anotherBackground={true}>
        <div className={styles.changePasswordForm}>
            <ChangePasswordForm></ChangePasswordForm>
        </div>
    </Layout>);
}

ChangePasswordPage.displayName = "ChangePasswordPage";
export default ChangePasswordPage;