import React from "react";
import {Button, tokens} from "@fluentui/react-components";
import {ArrowClockwiseRegular} from "@fluentui/react-icons";
import {RestoreButtonProps} from "./RestoreButtonProps";

/**
 * Кнопка для действия восстановления, перезапуска
 * @constructor
 */
const RestoreButton: React.FC<RestoreButtonProps> = (props) => {

    const onClickHandler = () => {
        if (props.onClick) {
            props.onClick()
        } else {
            alert("No action assigned for this component");
        }
    }

    return (
        <Button appearance={"subtle"}
                style={{
                    color: tokens.colorBrandBackground
                }}
                icon={<ArrowClockwiseRegular/>}
                onClick={onClickHandler}/>
    )
}

/**
 * Имя отображаемое при отладке
 */
RestoreButton.displayName = "RestoreButton";

export default RestoreButton;