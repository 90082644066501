import React, {useCallback} from "react";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import {DatePicker} from "@fluentui/react-datepicker-compat";
import {useServices, useStore} from "../../../hooks";
import UseStyle from "./StatsSharedStyles";
import {Search20Regular} from "@fluentui/react-icons";
import {LoadingButton} from "../../../components/ButtonWithLoadingState";
import {useToast} from "../../../utils/ToastProvider";
import {observer} from "mobx-react-lite";

/**
 * Управление статистикой в публичной версии
 * @constructor
 */
const StatsSharedTools: React.FC = () => {
    const {statisticSharedService} = useServices();
    const styles = UseStyle();
    const {showToast} = useToast();
    const {statisticSharedStore} = useStore();

    /**
     * Фильтрация статистики
     */
    const onSearchClickHandler = useCallback(async () => {
        try {
            const body = {
                startDate: statisticSharedStore.startDate,
                endDate: statisticSharedStore.endDate
            }
            statisticSharedStore.data = await statisticSharedService.getSharedStatsData(statisticSharedStore.id, body);
            statisticSharedStore.dataByCountry = await statisticSharedService.getSharedTopCountries(statisticSharedStore.id, body)
        } catch (error: any) {
            showToast("Error!", error.message, "error");
        }
    }, [statisticSharedService, statisticSharedStore, showToast]);

    return (
        <ToolsContainer>
            <div className={styles.controlContainer}>
                <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                    <label htmlFor="start-date-picker">Start date:</label>
                    <DatePicker
                        value={statisticSharedStore.startDate}
                        onSelectDate={(date) => {
                            statisticSharedStore.startDate = date || new Date(0);
                        }}
                    />
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                    <label htmlFor="start-date-picker">End date:</label>
                    <DatePicker
                        value={statisticSharedStore.endDate}
                        onSelectDate={(date) => statisticSharedStore.endDate = date || new Date()}
                    />
                </div>
            </div>
            <div>
                <LoadingButton appearance={"primary"}
                               enabled={true}
                               icon={<Search20Regular/>}
                               onClick={onSearchClickHandler}
                               buttonText={"Search"}/>
            </div>
        </ToolsContainer>
    );
}

/**
 * Имя отображаемое для отладки
 */
StatsSharedTools.displayName = "StatsSharedTools";
export default observer(StatsSharedTools);