import React, {useCallback, useEffect, useState} from "react";
import {
    Input,
    Label,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import {useServices} from "../../hooks";
import {DataBrokerDto} from "../../models/admin/dataBroker";
import {LoadingButton} from "../ButtonWithLoadingState";
import {DeleteRegular, EditRegular} from "@fluentui/react-icons";
import {LoadingState} from "../../models";
import {store} from "../../store";
import {useToast} from "../../utils/ToastProvider";
import {isUrlValid} from "../../utils";
import {AvStatusButton} from "../AvStatusButton";
import {AvReScanButton} from "../AvReScanButton";
import HealthState from "../HealthState/HealthState";
import useStyles from "./DataBrokerTableStyles";

/**
 * Таблица отображения посредников данных ("прокладок")
 * @constructor
 */
const DataBrokerTable: React.FC = () => {
    const {adminDataBrokerService} = useServices();
    const [dataBrokers, setDataBrokers] = useState<DataBrokerDto[]>([]);
    const [addLoadingState, setAddLoadingState] = useState<LoadingState>("initial");
    const [newDataBroker, setNewDataBroker] = useState('');
    const [newDataUrl, setNewDataUrl] = useState("");
    const {showToast} = useToast();
    const styles = useStyles();

    const columns = [
        {columnKey: "url", label: "Url"},
        {columnKey: "buildCount", label: "Build Count"},
        {columnKey: "name", label: "Domain Name"},
        {columnKey: "AV scan", label: "AV scan"},
        {columnKey: "Health", label: "Health"},
        {columnKey: "actions", label: "Actions"},
    ];

    const updateBrokerList = useCallback(async () => {
        try {
            const response = await adminDataBrokerService.getList();
            setDataBrokers(response);
        } catch (error: any) {
            showToast("Error", error.message, "error");
        }
    }, [adminDataBrokerService, showToast]);

    useEffect(() => {
        (async () => {
            await updateBrokerList();
        })();
    }, [adminDataBrokerService, updateBrokerList]);

    const addNewBroker = useCallback(async () => {
        try {
            if (isUrlValid(newDataBroker)) {
                store.loaderStore.setLoading(true);
                setAddLoadingState("loading");
                console.log("Broker", newDataBroker);
                console.log("URL", newDataUrl);
                await adminDataBrokerService.add(newDataBroker, newDataUrl);
                await updateBrokerList();
                setAddLoadingState("loaded");
            } else {
                alert("Provide valid URL!");
            }
        } catch (error: any) {
            setAddLoadingState("error");
        } finally {
            store.loaderStore.setLoading(false);
        }
    }, [adminDataBrokerService, newDataBroker, updateBrokerList, newDataUrl]);

    const handleDelete = useCallback(async (id: number) => {
        try {
            store.loaderStore.setLoading(true);
            await adminDataBrokerService.delete(id);
            await updateBrokerList();
        } catch (error: any) {
            console.error(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    }, [adminDataBrokerService, updateBrokerList]);

    const handleEditName = useCallback((id: number, currentName: string) => {
        const newName = prompt("Enter new name:", currentName);
        if (newName && newName.trim() !== "") {
            (async () => {
                try {
                    store.loaderStore.setLoading(true);
                    await adminDataBrokerService.updateName(id, newName.trim());
                    await updateBrokerList();
                } catch (error: any) {
                    showToast("Error", error.message, "error");
                } finally {
                    store.loaderStore.setLoading(false);
                }
            })();
        }
    }, [adminDataBrokerService, updateBrokerList, showToast]);

    return (
        <div>
            <div className={styles.toolsContainer}>
                <Label>
                    Url
                </Label>
                <Input
                    type="url"
                    onChange={(_ev, data) => setNewDataBroker(data.value)}
                />
                <Label>
                    Domain name
                </Label>
                <Input
                    type="url"
                    onChange={(_ev, data) => setNewDataUrl(data.value)}
                />
                <LoadingButton
                    state={addLoadingState}
                    buttonText="Add"
                    appearance="primary"
                    enabled
                    onClick={addNewBroker}
                />
                <AvReScanButton/>
            </div>
            <Table
                role="grid"
                aria-label="Table with grid keyboard navigation"
                style={{minWidth: "620px"}}
            >
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell
                                key={column.columnKey}
                                className={styles.tableHeaderCell}>
                                {column.label}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {dataBrokers.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell className={styles.uriCell}>
                                {item.uri}
                            </TableCell>
                            <TableCell className={styles.buildCell}>
                                {item.buildCount}
                            </TableCell>
                            <TableCell className={styles.domainNameCell}>
                                <span>{item.name}</span>
                            </TableCell>
                            <TableCell className={styles.avScanCell}>
                                <AvStatusButton
                                    avTotalDetected={item.totalDetectAv}
                                    avTotal={item.totalAv}
                                    brokerId={item.id}
                                />
                            </TableCell>
                            <TableCell className={styles.tableHeaderCell}>
                                <HealthState brokerId={item.id}/>
                            </TableCell>
                            <TableCell className={styles.actionsButtonContainer}>
                                <LoadingButton
                                    style={{color: tokens.colorBrandBackground}}
                                    appearance="subtle"
                                    enabled
                                    onClick={() => handleEditName(item.id, item.name)}
                                    icon={<EditRegular/>}
                                />
                                <LoadingButton
                                    style={{color: tokens.colorPaletteDarkOrangeForeground1}}
                                    appearance="subtle"
                                    enabled
                                    onClick={() => handleDelete(item.id)}
                                    icon={<DeleteRegular/>}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

/**
 * Имя отображаемое при отладке
 */
DataBrokerTable.displayName = "DataBrokerTable";

export default DataBrokerTable;
