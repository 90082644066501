import React, {useEffect, useState} from 'react';
import {makeStyles, shorthands} from '@fluentui/react-components';
import {ArrowUp20Regular} from '@fluentui/react-icons';

// Хук makeStyles: объявляем стили в объекте
const useStyles = makeStyles({
    scrollToTopButton: {
        position: 'fixed',
        bottom: '48px', // Обязательно в виде строки
        right: '48px',
        zIndex: 1000,
        width: '56px',
        height: '48px',
        // Вместо borderRadius используем специальную функцию-шортхенд
        ...shorthands.borderRadius('8px'),
        backgroundColor: '#0078D4',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s, box-shadow 0.3s',

        ':hover': {
            backgroundColor: '#005A9E',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.3)',
        },
        ':active': {
            backgroundColor: '#004377',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        },
    },
});

/**
 * Компонент "Вернуться вверх страницы"
 */
const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    // Вызываем хук, который возвращает объект классов
    const styles = useStyles();

    const toggleVisibility = () => {
        const scrolled = window.scrollY;
        const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
        setIsVisible(scrolled > pageHeight * 0.25);
    };

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return isVisible ? (
        <div
            title="Вернуться наверх"
            className={styles.scrollToTopButton}
            onClick={scrollToTop}
        >
            <ArrowUp20Regular style={{fontSize: 24}}/>
        </div>
    ) : null;
};

ScrollToTopButton.displayName = 'ScrollToTopButton';
export default ScrollToTopButton;
