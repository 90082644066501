import {makeAutoObservable} from "mobx";
import {BuilderRequestInfoDto, BuildRequestStatesDto, UserDto} from "../models/common";

/**
 * Хранилище для Builder
 */
export class BuilderStore {

    /**
     * Конструктор хранилища cookies
     */
    constructor() {
        makeAutoObservable(this);
    }

    private _partners: UserDto[] = [];

    public set partners(users: UserDto[]) {
        this._partners = users;
    }

    public get partners() {
        return this._partners;
    }

    private _partnerId: string = '';

    public get partnerId() {
        return this._partnerId;
    }

    public set setPartnerId(id: string) {
        this._partnerId = id;
    }

    private _requestState: BuilderRequestInfoDto = {
        requestId: '',
        state: BuildRequestStatesDto.None,
        fileId: 0,
        fileSize: 0
    };

    public set requestState(state: BuilderRequestInfoDto) {
        this._requestState = state;
    }

    public get requestState() {
        return this._requestState;
    }

    private _remainingBuild: number = 0;

    public set remainingBuild(value: number) {
        this._remainingBuild = value;
    }

    public get remainingBuild() {
        return this._remainingBuild;
    }
}