import React, {useState} from "react";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import {PaginationButtons} from "../../PaginationButtons";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks";
import {Button, Select} from "@fluentui/react-components";
import {services} from "../../../services";
import {useSearchParams} from "react-router-dom";
import {WALLETS_URL} from "../../../shared/constUrl";
import {ArrowDownload20Regular} from "@fluentui/react-icons";

/**
 * Инструменты управления данными на странице "Кошельки"
 * @constructor
 */
const WalletsTools: React.FC = () => {
    const store = useStore();
    const [searchParams] = useSearchParams();
    const [selectedOption, setSelectedOption] = useState<string>("");

    /**
     * Обработчик изменения выбора в выпадающем списке
     */
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedOption(value); // Сохраняем выбранное значение
    };

    /**
     * Логика скачивания файла
     */
    const handleDownload = async () => {
        const logId = searchParams.get("botId") || "0";
        const params = new URLSearchParams({botId: logId});

        // Формируем полный URL с параметрами
        const urlWithParams = `${WALLETS_URL}/download?${params.toString()}`;
        await services.fileDownloadService.downloadFile(urlWithParams);
    };

    /**
     * Обработчик кнопки "Следующая страница"
     */
    const onNextClick = async () => {
        try {
            const response = await services.walletsService.getList();
            store.walletsStore.wallets = response.wallets;
        } catch (error: any) {
            console.error("Error loading next page:", error);
        }
    };

    /**
     * Обработчик кнопки "Предыдущая страница"
     */
    const onPrevClick = async () => {
        try {
            const response = await services.walletsService.getList();
            store.walletsStore.wallets = response.wallets;
        } catch (error: any) {
            console.error("Error loading previous page:", error);
        }
    };

    return (
        <ToolsContainer>
            {/* Контейнер для всей строки */}
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                {/* Левый блок (фильтры и кнопки управления кошельком) */}
                <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
                    {/* Обёртка для выбора с лейблом */}
                    <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                        <label htmlFor="wallet-select">Wallet name:</label>
                        <Select id="wallet-select" value={selectedOption} onChange={handleSelectChange}>
                            <option key="all" value="">
                                All
                            </option>
                            {store.walletsStore.options.map((val) => (
                                <option key={val} value={val}>
                                    {val}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <Button icon={<ArrowDownload20Regular/>} appearance="primary" onClick={handleDownload}
                            style={{marginRight: "1em"}}>
                        Download
                    </Button>
                </div>

                {/* Правый блок (пагинация) */}
                <div style={{display: "flex", alignItems: "center", gap: "1em", marginLeft: "auto"}}>
                    <PaginationButtons
                        currentPage={store.walletsStore.paging.pageNumber}
                        totalCount={store.walletsStore.paging.totalItems}
                        totalPages={store.walletsStore.paging.totalPages}
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                        isLoading={store.loaderStore.loading}
                    />
                </div>

            </div>
        </ToolsContainer>
    );
};

/**
 * Имя, отображаемое при отладке
 */
WalletsTools.displayName = "WalletsTools";

export default observer(WalletsTools);
