import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input, Label, Select, SelectOnChangeData, Subtitle2,} from '@fluentui/react-components';
import {useServices, useStore} from '../../hooks';
import UseStyle from './BuilderFormStyles';
import {BuilderOptionsDto, BuildRequestStatesDto} from '../../models/common';
import {ErrorCode} from '../../models/shared/ErrorCode';
import {useToast} from "../../utils/ToastProvider";
import {GetMessageByCode} from "../../utils";
import {AvStatusButton} from "../../components/AvStatusButton";
import {observer} from "mobx-react-lite";
import {DataBrokerDto, DataBrokerShortScanResult} from "../../models/common/dataBroker";
import {AvReScanButton} from "../../components/AvReScanButton";
import HealthState from "../../components/HealthState/HealthState";

/**
 * Форма создания запроса на генерацию билда
 * @constructor
 */
const BuilderForm: React.FC = () => {
    const styles = UseStyle();
    const [buildTag, setBuildTag] = useState('');
    const {builderService, dataBrokerService} = useServices();
    const {showToast} = useToast();
    const store = useStore();
    const [dataBrokers, setDataBrokers] = useState<DataBrokerDto[]>([]);
    const [selectedBroker, setSelectedBroker] = useState<DataBrokerDto>({id: 0, name: ''});
    const [detect, setDetect] = useState<DataBrokerShortScanResult>({totalDetectAv: 0, totalAv: 0, brokerId: 0});

    /**
     * Получение списка рефералов
     */
    const fetchReferrals = useCallback(async () => {
        try {
            store.builderStore.partners = await builderService.getRefList();
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [builderService, showToast, store.builderStore]);

    /**
     * Получение списка брокеров данных
     */
    const fetchDataBroker = useCallback(async () => {
        try {
            setDataBrokers(await dataBrokerService.getDataBrokers());
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [showToast, setDataBrokers, dataBrokerService]);


    /**
     * Получение списка партнёров
     */
    useEffect(() => {
        (async () => {
            await fetchReferrals();
            await fetchDataBroker();
        })();
    }, [builderService, fetchReferrals, fetchDataBroker]);

    /**
     * отправка запроса на генерацию билда
     */
    const handleRequestNewBuild = useCallback(async () => {
        try {
            const body: BuilderOptionsDto = {
                partnerId: store.builderStore.partnerId || '',
                buildTag: buildTag,
                brokerUrl: selectedBroker.name
            };
            await builderService.newRequest(body);
            store.builderStore.requestState.state = BuildRequestStatesDto.Waiting;
        } catch (error: any) {
            const errorMessage =
                error.code === ErrorCode.CustomErrorMessage
                    ? error.message
                    : GetMessageByCode(error.code)

            showToast('Error!', errorMessage, "error");
        }
    }, [buildTag, showToast, builderService, store.builderStore, selectedBroker])

    /**
     * Обработчик выбора партнёра
     * @param _ev событие
     * @param data данные
     */
    const partnerChangeHandler = useCallback((_ev: any, data: SelectOnChangeData) => {
        store.builderStore.setPartnerId = data.value;
    }, [store.builderStore]);

    const dataBrokerChangeHandler = useCallback(async (_ev: any, data: SelectOnChangeData) => {

        // Найти объект брокера данных по имени
        const broker = dataBrokers.find(broker => broker.name === data.value);

        // Установить найденного брокера или сбросить состояние, если имя не указано
        setSelectedBroker(broker || {id: 0, name: ''});

        if (data.value !== '') {
            try {
                const result = await dataBrokerService.getShortAvResult(data.value);
                setDetect(result);
            } catch (error: any) {
                const errorMessage =
                    error.code === ErrorCode.CustomErrorMessage
                        ? error.message
                        : GetMessageByCode(error.code)

                showToast('Error!', errorMessage, "error");
            }
        } else {
            setDetect({brokerId: 0, totalDetectAv: 0, totalAv: 0})
        }
    }, [showToast, dataBrokerService, dataBrokers]);

    /**
     * Вывод списка партнёров при наличии
     */
    const showPartnerChooseInput = useCallback(() => {
        if (store.builderStore.partners.length > 0) {
            return (
                <div className={styles.controlContainer}>
                    <Label className={styles.labels}>Partner</Label>
                    <Select className={styles.inputs} onChange={partnerChangeHandler}>
                        <option key={'no_choose'} value={''}>
                            No partner selected
                        </option>
                        {store.builderStore.partners.map((referral) => (
                            <option key={referral.id} value={referral.id}>
                                {String(referral.userName)}
                            </option>
                        ))}
                    </Select>
                </div>
            );
        }
        return null;
    }, [store.builderStore, partnerChangeHandler, styles.inputs, styles.labels, styles.controlContainer]);

    /**
     *  Показ выбора брокера
     */
    const showDataBrokerSelect = useCallback(() => {
        return (
            <div className={styles.controlContainer}>
                <Label className={styles.labels}>Data broker</Label>
                <Select className={styles.inputs} onChange={dataBrokerChangeHandler}>
                    <option key={'Automatic'} value={''}>
                        Automatic assignment
                    </option>
                    {Array.isArray(dataBrokers) && dataBrokers.length > 0 ? (
                        dataBrokers.map((brokerUrl) => (
                            <option key={brokerUrl.id} value={brokerUrl.name}>
                                {brokerUrl.name}
                            </option>
                        ))
                    ) : (
                        <option disabled key={'no-brokers'}>
                            No brokers available
                        </option>
                    )}
                </Select>
                <HealthState brokerId={selectedBroker.id}/>
                <AvStatusButton
                    avTotal={detect.totalAv}
                    avTotalDetected={detect.totalDetectAv}
                    brokerId={detect.brokerId}
                    size={"medium"}
                    style={{marginRight: 8}}
                />

                <AvReScanButton/>
            </div>
        );
    }, [dataBrokers, dataBrokerChangeHandler, detect.brokerId, detect.totalAv, detect.totalDetectAv, selectedBroker.id, styles.inputs, styles.labels, styles.controlContainer]);

    return (
        <div>
            <Subtitle2>Create New Build</Subtitle2>
            <form className={styles.form}>
                {showDataBrokerSelect()}
                <div className={styles.controlContainer}>
                    <Label className={styles.labels}>Build tag</Label>
                    <Input className={styles.inputs} placeholder="Enter build tag"
                           value={buildTag}
                           onChange={(event) => setBuildTag(event.target.value)}
                    />
                </div>
                {showPartnerChooseInput()}
                <Button appearance={"primary"} onClick={handleRequestNewBuild}>Request new build</Button>
            </form>
        </div>
    );
};

BuilderForm.displayName = "BuilderForm";

export default observer(BuilderForm);