import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Spinner,
    Subtitle2
} from "@fluentui/react-components";
import useStyles from './ProfileSecurityWidgetStyle';
import {GetMessageByCode} from "../../utils";
import {useServices} from "../../hooks";
import UserSession from "../../models/common/sessions/UserSession";
import SessionRecord from "./SessionRecord"
import {store} from "../../store";
import {LoadingState} from "../../models";
import {useNavigate} from "react-router-dom";
import {CHANGE_PASSWORD_URL} from "../../shared/constUrl";
import {useToast} from "../../utils/ToastProvider";

const ProfileSecurityWidget: React.FC = () => {
    const {showToast} = useToast();
    const styles = useStyles();
    const services = useServices();
    const [sessions, setSessions] = useState<UserSession[]>([]);
    const [sessionCount, setSessionCount] = useState(0);
    const [terminateSessionStart, setTerminateSessionStart] = useState(false);
    const [terminateLoadingState, setTerminateLoadingState] = useState<LoadingState>("initial");
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const response = await services.sessionsService.getSessions();
                setSessionCount(response.data.length);
                setSessions(response.data);
            } catch (error: any) {
                showToast('Error!', GetMessageByCode(error.code), 'error');
            }
        })();
    }, [showToast, services.sessionsService, setSessionCount, setSessions]);

    const terminateButtonIcon =
        terminateLoadingState === "loading" ? (
            <Spinner
                size="tiny"
                appearance="inverted"
            />
        ) : null;

    const terminateButtonContent =
        terminateLoadingState === "loading"
            ? " "
            : terminateLoadingState === "loaded"
                ? "Terminate all"
                : "Terminate all";

    async function onTerminateClickHandler() {
        try {
            store.loaderStore.setLoading(true);
            setTerminateLoadingState("loading")
            await services.sessionsService.terminateAll(services.tokenService.refreshToken);
            setTerminateLoadingState("loaded");
            setSessionCount(1);
            showToast('Done!', 'Sessions are terminated', 'success');
        } catch (error: any) {
            showToast('Error!', GetMessageByCode(error.code), 'error');
        } finally {
            setTerminateLoadingState("initial");
            setTerminateSessionStart(false);
        }
    }

    function changePasswordClickHandler() {
        navigate(CHANGE_PASSWORD_URL);
    }

    return (
        <Card className={styles.card}>
            <CardHeader
                header={<Subtitle2>Security</Subtitle2>}>
            </CardHeader>
            <div>
                <div className={styles.cardSessions}>
                    <p>Current active session: <b>{sessionCount}</b></p>

                    <div className={styles.buttonContainer}>
                        <Dialog modalType="modal">
                            <DialogTrigger disableButtonEnhancement>
                                <Button className={styles.defButton}>Show
                                    sessions</Button>
                            </DialogTrigger>
                            <DialogSurface>
                                <DialogBody>
                                    <DialogTitle action={null} as={"h6"}>
                                        Active sessions
                                    </DialogTitle>
                                    <DialogContent>
                                        <SessionRecord userSessions={sessions}></SessionRecord>
                                    </DialogContent>
                                    <DialogActions>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button appearance="primary">Close</Button>
                                        </DialogTrigger>
                                    </DialogActions>
                                </DialogBody>
                            </DialogSurface>
                        </Dialog>

                        <Button icon={terminateButtonIcon} className={styles.defButton} disabled={terminateSessionStart}
                                onClick={onTerminateClickHandler}>{terminateButtonContent}</Button>

                        <Button className={styles.defButton} onClick={changePasswordClickHandler}>Change
                            password</Button>
                    </div>
                </div>
            </div>
        </Card>)
};

/**
 * Имя отображающееся при отладке
 */
ProfileSecurityWidget.displayName = "ProfileSecurityWidget";

export default ProfileSecurityWidget;
