import React, {useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import {isGuid} from "../../../utils";
import {useNavigate, useParams} from "react-router-dom";
import {useToast} from "../../../utils/ToastProvider";
import {useServices, useStore} from "../../../hooks";
import {JsonProfileLoaderEditor} from "../../../components/JsonProfileLoaderEditor";
import {JsonProfileBrowserEditor} from "../../../components/JsonProfileBrowserEditor";
import makeStyles from "./JsonProfileEditStyle"
import {observer} from "mobx-react-lite";
import {JsonProfileChromeExtEditor} from "../../../components/JsonProfileChromeExtEditor";
import {JsonProfileGeckoExtEditor} from "../../../components/JsonProfileGeckoExtEditor";
import {Tab, TabList, TabListProps} from "@fluentui/react-components";
import {ToolsContainer} from "../../../containers/ToolsContainer";

/**
 * Страница отображения и редактирования профиля
 * @constructor
 */
const JsonProfileEditPage: React.FC = () => {
    const {userId} = useParams();
    const {showToast} = useToast();
    const navigate = useNavigate();
    const services = useServices();
    const style = makeStyles();
    const {jsonProfileStore} = useStore();
    const [selectedTab, setSelectedTab] = useState<string>("tab1");

    useEffect(() => {
        if (!isGuid(userId)) {
            navigate("/");
            return;
        }

        jsonProfileStore.setUserID(userId!);

        try {
            services.adminJsonProfilesService.loadProfile(userId!).then((data) => {
                jsonProfileStore.setProfile(data);
            });
        } catch (error: any) {
            showToast("Error", error.message || "Unknown error occurred", "error");
        }
    }, [userId, showToast, services.adminJsonProfilesService, navigate, jsonProfileStore]);

    // Обработчик изменения вкладок
    const onTabSelect: TabListProps["onTabSelect"] = (event, data) => {
        setSelectedTab(data.value as string);
    };

    return (
        <Layout className={style.dataCardContainer} pageTittle={"Edit profile"} anotherBackground={true}>
            <ToolsContainer>
                <TabList
                    className={style.tabs}
                    selectedValue={selectedTab}
                    onTabSelect={onTabSelect}
                >
                    <Tab value="tab1">Loader Editor</Tab>
                    <Tab value="tab2">Browser Editor</Tab>
                    <Tab value="tab3">Chrome Ext Editor</Tab>
                    <Tab value="tab4">Gecko Ext Editor</Tab>
                </TabList>
            </ToolsContainer>

            <div>
                {selectedTab === "tab1" && <JsonProfileLoaderEditor/>}
                {selectedTab === "tab2" && <JsonProfileBrowserEditor/>}
                {selectedTab === "tab3" && <JsonProfileChromeExtEditor/>}
                {selectedTab === "tab4" && <JsonProfileGeckoExtEditor/>}
            </div>
        </Layout>
    );
};

export default observer(JsonProfileEditPage);
