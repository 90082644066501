import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    cardContainer: {
        marginTop: "1em",
        minWidth: "80%",
        maxWidth: "80%",
        minHeight: "fit-content",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1.2em",
        gap: "10px"
    },
    error: {
        color: "red",
        fontSize: "14px",
        marginTop: "10px",
    },
    labels: {
        width: "80px",
        display: "inline-block"
    },
    inputs: {
        flex: "1"
    },
    controlContainer: {
        display: "flex",
        alignItems: "center", // Выравнивание по вертикали
        gap: "10px", // Промежуток между элементами
        marginBottom: "10px", // Отступ снизу между секциями
    }
});