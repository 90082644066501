import {makeStyles} from '@fluentui/react-components';

export default makeStyles({
    cardContainer: {
        minWidth: '200px',
        minHeight: '200px',
        maxHeight: "fit-content",
        margin: '0.1em',
        flex: "0 0 auto",
        maxWidth: "400px", // Задаём максимальную ширину
        overflowY: "auto", // Если содержимое превышает высоту, добавляем скроллинг
    },
    logData: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    statisticDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    countriesContainer: {
        marginTop: '1px',
    },
    diagram: {
        width: '300px',
        height: '300px',
        marginTop: '15px',
    },
    totalLogs: {
        textAlign: "center",
        marginTop: "16px",
        fontSize: "14px",
        color: "#666"
    },
    noDataMessage: {
        textAlign: "center",
        fontSize: "16px",
        color: "#666",
        marginTop: "20px"
    }
});
