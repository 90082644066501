import React, {useCallback} from "react";
import {LoadingButton} from "../ButtonWithLoadingState";
import {useToast} from "../../utils/ToastProvider";
import {useServices} from "../../hooks";

/**
 * Кнопка для запроса нового сканирования брокеров
 * @constructor
 */
const AvReScanButton: React.FC = () => {
    const {showToast} = useToast();
    const services = useServices();

    const requestReScan = useCallback(async () => {
        try {
            await services.avScanService.reScan();
            showToast("Successfully", "Re-open AV results to see new data", "success");
        } catch (e: any) {
            showToast("Error!", e.message, "error");
        }
    }, [showToast, services.avScanService]);

    return <LoadingButton
        enabled={true}
        buttonText="Re-scan all"
        onClick={requestReScan}
    />
}

AvReScanButton.displayName = "AvReScanButton";
export default AvReScanButton;