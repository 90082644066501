import React, {useEffect, useState} from "react";
import {
    Button,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import {DialogActions, DialogContent} from "@fluentui/react-dialog";
import {useServices} from "../../hooks";
import {useToast} from "../../utils/ToastProvider";
import {SharedLinksListDto} from "../../models/common/statistic/SharedLinksListDto";
import {SharedLinksDto} from "../../models/common";
import {DataUsageSettings20Regular} from "@fluentui/react-icons";
import {LoadingButton} from "../ButtonWithLoadingState";
import ActionDialog from "../ActionDialog";

interface StatsManageLinkDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const StatsManageLinkDialog: React.FC<StatsManageLinkDialogProps> = ({isOpen, onClose}) => {
    const {statisticService} = useServices();
    const {showToast} = useToast();
    const [links, setLinks] = useState<SharedLinksDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const domain = window.location.origin; // или указать статически, например: const domain = "https://example.com";

    /**
     * Загрузка списка ссылок
     */
    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            statisticService.getLinks()
                .then((data: SharedLinksListDto) => {
                    setLinks(data.sharedLinks);
                })
                .catch((error: any) => {
                    console.error(error);
                    showToast("Error!", error.message, "error");
                })
                .finally(() => setIsLoading(false));
        }
    }, [isOpen, statisticService, showToast]);

    /**
     * Обработка удаления ссылки
     * @param id
     */
    const handleDelete = async (id: string) => {
        try {
            await statisticService.deleteLink(id);
            setLinks((prev) => prev.filter((link) => link.id !== id));
            showToast("Success", "Link deleted successfully", "success");
        } catch (error: any) {
            showToast("Error!", error.message, "error");
        }
    }

    //ToDo: Нужно доделать конфиг для каждый ссылки и как-то в целом по красивее.

    /**
     * Управление публичными ссылками
     */
    const managerContent = () => {
        return <DialogContent>
            {isLoading ? (
                <div>Loading...</div>
            ) : links.length === 0 ? (
                <div>No links available</div>
            ) : (
                <Table style={{width: '100%', tableLayout: 'auto'}}>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Link</TableHeaderCell>
                            <TableHeaderCell>Last Use</TableHeaderCell>
                            <TableHeaderCell>Config</TableHeaderCell>
                            <TableHeaderCell>Actions</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {links.map((link) => {
                            const shareUrl = `${domain}/statistic/share/${link.id}`;
                            return (
                                <TableRow key={link.id}>
                                    <TableCell
                                        style={{
                                            padding: '8px',
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-all'
                                        }}
                                    >
                                        <a
                                            href={shareUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                                color: 'black',
                                                textDecoration: 'none',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {shareUrl}
                                        </a>
                                    </TableCell>
                                    <TableCell>{link.lastUse?.toString()}</TableCell>
                                    <TableCell>
                                        <Button icon={<DataUsageSettings20Regular/>}/>
                                    </TableCell>
                                    <TableCell>
                                        <LoadingButton
                                            enabled={true}
                                            style={{background: tokens.colorPaletteDarkOrangeBackground3}}
                                            appearance="primary"
                                            buttonText={"Delete"}
                                            onClick={() => handleDelete(link.id)}/>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </DialogContent>
    }

    /**
     * Управление публичными ссылками
     */
    const settingsContent = () => {
        return <div>
            <Switch label={"Show empty"}/>
            <Switch label={"Show duplicate"}/>
        </div>

    }

    return (
        <ActionDialog isOpen={isOpen} title={"Manage links"}
                      trigger={null}
                      actions={<DialogActions>
                          <Button appearance={"primary"} onClick={onClose}>Close</Button>
                      </DialogActions>}
                      content={managerContent()}
        />
    );
};

export default StatsManageLinkDialog;
