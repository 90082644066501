import {ApiResult} from "../models";
import {FileRequestsVm} from "../models/common";
import {AxiosService} from "./axios";

export class DownloadsService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;


    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * запрос списка файлов на скачивание
     */
    async getLoadsList(): Promise<ApiResult<FileRequestsVm>> {
        try {
            const resp = await this.axios.get<FileRequestsVm>("/downloads");
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * запросить удаление файла
     */
    async deleteFile(body: string): Promise<void> {
        try {
            await this.axios.delete("/downloads/delete/"+body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}