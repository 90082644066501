import React, {useEffect} from "react";
import {Card, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "@fluentui/react-components";
import {LoadingButton} from "../ButtonWithLoadingState";
import {ArrowDownload20Regular} from "@fluentui/react-icons";
import {useServices} from "../../hooks";
import {ADMIN_APP_LOGS_URL} from "../../shared/constUrl";
import {useToast} from "../../utils/ToastProvider";
import {AppLogsDto} from "../../models/admin";

const AppLogsList: React.FC = () => {
    const services = useServices();
    const [logs, setLogs] = React.useState<AppLogsDto[]>([]);
    const {showToast} = useToast();

    useEffect(() => {
        services.adminAppLogService.load()
            .then(setLogs)
            .catch(() => {
                showToast("Error", "Problem during loading app logs", "error");
            });
    }, [services.adminAppLogService, showToast]);

    const downloadItem = async (path: string) => {
        try {
            const urlWithParams = `${ADMIN_APP_LOGS_URL}/download?path=${path}`;
            await services.fileDownloadService.downloadFile(urlWithParams);
        } catch {
            showToast("Error", "Something went wrong...", "error");
        }
    };

    return (
        <Card>
            <Table as="table">
                <TableHeader as="thead">
                    <TableRow as="tr">
                        <TableHeaderCell as="th" style={{width: "90%"}}>Log name</TableHeaderCell>
                        <TableHeaderCell as="th" style={{width: "10%", textAlign: "right"}}>Actions</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {logs.length > 0 ? (
                        logs.map((item, index) => (
                            <TableRow as="tr" key={index}>
                                <TableCell as="td" style={{
                                    width: "90%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.name}</TableCell>
                                <TableCell as="td" style={{width: "10%", textAlign: "right"}}>
                                    <LoadingButton
                                        enabled={true}
                                        onClick={() => downloadItem(item.path)}
                                        appearance="primary"
                                        icon={<ArrowDownload20Regular/>}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow as="tr">
                            <TableCell as="td" colSpan={2} style={{textAlign: "center"}}>
                                No logs available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    );
};

AppLogsList.displayName = "AppLogsList";
export default AppLogsList;
