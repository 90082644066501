import {ChangePasswordDto} from "../../models/common";

/**
 * Правила валидации данных форма "Смена паролей"
 * @param values
 */
export const validate = (values: ChangePasswordDto) => {

    const errors = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    };

    if (values.newPassword !== values.confirmNewPassword) {
        errors.confirmNewPassword = "Passwords do not match.";
    }

    if (values.oldPassword === values.newPassword) {
        errors.newPassword = "New password must not match to old."
    }

    if (!values.oldPassword) {
        errors.oldPassword = "Required.";
    }

    if (!values.newPassword) {
        errors.newPassword = "Required.";
    }

    if (values.newPassword.length < 8) {
        errors.newPassword += " Password length must be =>8 characters."
    }

    if (!values.confirmNewPassword) {
        errors.confirmNewPassword = "Required.";
    }

    if (values.confirmNewPassword.length < 8) {
        errors.confirmNewPassword += " Password length must be =>8 characters."
    }

    if (errors.newPassword || errors.confirmNewPassword || errors.oldPassword) {
        return errors;
    }
}