import {AxiosService} from "./axios";
import {ApiResult} from "../models";
import {
    TelegramNotificationsDto,
    TelegramNotificationsUsersDto,
    TelegramOptionsStateDto
} from "../models/common/telegramNotifications";
import {TG_NOTIFICATION_SETTINGS_URL} from "../shared/constUrl";
import {TelegramBotLinkOptionDto} from "../models/common/telegramNotifications/TelegramBotLinkOptionDto";

/**
 * Сервис работы с ботами телеграм
 */
export class TelegramBotService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор аккаунт сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить статус телеграм бота
     */
    async getTelegramBotStatus(): Promise<ApiResult<TelegramNotificationsDto>> {
        try {
            return await this.axios.get<TelegramNotificationsDto>("/tg-bot/status");
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * создать телеграм бота
     */
    async startBot(body: String): Promise<ApiResult<any>> {
        try {
            return await this.axios.post("/tg-bot/create", body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * остановить телеграм бота
     */
    async stopBot(): Promise<ApiResult<any>> {
        try {
            return await this.axios.post("/tg-bot/stop");
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить список пользователей бота
     */
    async getTelegramUser(): Promise<ApiResult<TelegramNotificationsUsersDto[]>> {
        try {
            return await this.axios.get<TelegramNotificationsUsersDto[]>("/tg-bot-users");
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удаление пользователя бота
     */
    async deleteTelegramUser(body: number): Promise<ApiResult<any>> {
        try {
            return await this.axios.delete("/tg-bot-users/delete/" + body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * включение оповещений на пользователя
     */
    async enableNotification(body: TelegramNotificationsUsersDto): Promise<ApiResult<void>> {
        try {
            return await this.axios.put("/tg-bot-users/notification-state/", body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }


    /**
     * получение списка настройки оповещений пользователя
     */
    async getOptions(body: number): Promise<TelegramOptionsStateDto> {
        try {
            const response = await this.axios.get<TelegramOptionsStateDto>(`${TG_NOTIFICATION_SETTINGS_URL}/show/${body}`);
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * включение опции ссылки на скачивание
     */
    async enableOptionsLink(body: TelegramBotLinkOptionDto): Promise<ApiResult<void>> {
        try {
            return await this.axios.post(`${TG_NOTIFICATION_SETTINGS_URL}/enable/option/links/`, body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * отключение опции ссылки на скачивание
     */
    async disableOptionsLink(body: TelegramBotLinkOptionDto): Promise<ApiResult<void>> {
        try {
            return await this.axios.post(`${TG_NOTIFICATION_SETTINGS_URL}/disable/option/links/`, body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Включение или отключения правила для только кошельков
     * @param telegramId Телеграм идентификатор пользователя
     * @param state Состояние
     */
    async changeOnlyWallets(telegramId: number, state: boolean): Promise<ApiResult<void>> {
        try {
            return await this.axios.patch(`${TG_NOTIFICATION_SETTINGS_URL}/update/only-wallets/${telegramId}`,
                state
            );
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Включение или отключения правила для только кошельков
     * @param telegramId Телеграм идентификатор пользователя
     * @param state Состояние
     */
    async changeOnlyExtWallets(telegramId: number, state: boolean): Promise<ApiResult<void>> {
        try {
            return await this.axios.patch(`${TG_NOTIFICATION_SETTINGS_URL}/update/only-ext-wallets/${telegramId}`,
                state
            );
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}