import {LogDeleteButtonProps} from "./LogDeleteButtonProp";
import {tokens} from "@fluentui/react-components";
import {DeleteRegular} from "@fluentui/react-icons";
import React, {useState} from "react";
import {LoadingButton} from "../../ButtonWithLoadingState";
import {ApiError, LoadingState} from "../../../models";
import {useServices, useStore} from "../../../hooks";
import {GetMessageByCode} from "../../../utils";
import {observer} from "mobx-react-lite";
import {useToast} from "../../../utils/ToastProvider";

/**
 * Кнопка перемещения лога в корзину
 * @param props параметры кнопки
 * @constructor
 */
const LogDeleteButton: React.FC<LogDeleteButtonProps> = (props) => {
    const [currentState, setCurrentState] = useState<LoadingState>("initial");
    const services = useServices();
    const store = useStore();
    const {showToast} = useToast();

    /**
     * Обработчик нажатия по кнопке удаления
     */
    const onClickHandler = () => {
        (async () => {
            try {
                setCurrentState("loading");
                await services.logsService.moveToBin(props.logId);
                setCurrentState("loaded");
                store.logsStore.removeItemById(props.logId);
            } catch (error: any) {
                console.error(error);
                setCurrentState("error");
                showErrorNotify(error);
            }
        })();
    };

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = (error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }

    return (<LoadingButton aria-label="Delete"
                           style={{color: tokens.colorPaletteDarkOrangeForeground1}}
                           appearance={"subtle"}
                           state={currentState}
                           enabled={true}
                           onClick={onClickHandler}
                           icon={<DeleteRegular/>}/>);
}

/**
 * Имя отображаемое при отладке
 */
LogDeleteButton.displayName = "LogDeleteButton";

export default observer(LogDeleteButton);