import React, {useCallback, useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import {useServices} from "../../../hooks";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import {LoadingButton} from "../../../components/ButtonWithLoadingState";
import {DeleteRegular} from "@fluentui/react-icons";
import {UserInfoAdminDto} from "../../../models/common/user/userInfoAdminDto";
import {useNavigate} from "react-router-dom";
import {ADMIN_USER_CREATE_URL} from "../../../shared/constUrl";

/**
 * Страница управления пользователями
 * @constructor
 */
const UsersPage: React.FC = () => {
    const services = useServices();
    const [users, setUsers] = useState<UserInfoAdminDto[]>([]);
    const navigate = useNavigate();

    /**
     * Колонки таблицы
     */
    const columns = [
        {columnKey: "url", label: "Id"},
        {columnKey: "buildCount", label: "Username"},
        {columnKey: "roles", label: "Roles"},
        {columnKey: "logCount", label: "Logs"},
        {columnKey: "note", label: "Note"},
    ];

    /**
     * Получение списка пользователей
     */
    const getUserList = useCallback(async () => {
        try {
            const response = await services.adminUserService.getUsers();
            setUsers(response);
        } catch (error: any) {
            console.error(error);
        }
    }, [services.adminUserService]);

    /**
     * Удаление пользователя
     * @param id ИД пользователя
     */
    const handleDelete = async (id: number) => {
        try {
            await services.adminUserService.delete(id);
            await getUserList();
        } catch (error: any) {
            console.error(error);
        }
    }

    /**
     * Подгрузка пользователей
     */
    useEffect(() => {
        getUserList();
    }, [getUserList]);

    return (
        <Layout pageTittle={"Users"}>
            <div style={{margin: '0.4em'}}>
                <Button appearance={"primary"} onClick={() => navigate(ADMIN_USER_CREATE_URL)}>Add</Button>
            </div>
            <Table
                role="grid"
                aria-label="Table with grid keyboard navigation"
                style={{minWidth: "620px"}}>
                <TableHeader>
                    <TableRow>
                        {columns.map((column) => (
                            <TableHeaderCell key={column.columnKey}>
                                {column.label}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {users.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                {item.id}
                            </TableCell>
                            <TableCell>
                                {item.userName}
                            </TableCell>
                            <TableCell>
                                {item.roles}
                            </TableCell>
                            <TableCell>
                                {item.botCount}
                            </TableCell>
                            <TableCell>
                                {item.note}
                            </TableCell>
                            <TableCell>
                                <LoadingButton style={{color: tokens.colorPaletteDarkOrangeForeground1}}
                                               appearance={"subtle"}
                                               enabled={true}
                                               onClick={() => handleDelete(item.id)}
                                               icon={<DeleteRegular/>}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Layout>
    );
}

/**
 * Имя отображаемое для отладки
 */
UsersPage.displayName = "UsersPage";

export default UsersPage;