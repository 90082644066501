import {makeAutoObservable} from "mobx";
import {StatisticDataDto, StatsLogByCountry} from "../models/common";

/**
 * Хранилище данных для статистики
 */
export class StatisticsSharedStore {
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Данные из статистики
     */
    private _data: StatisticDataDto | null = null;

    /**
     * Получить данные статистки
     */
    get data() {
        return this._data;
    }

    /**
     * Сохранить данные статистики
     * @param statsData
     */
    set data(statsData: StatisticDataDto | null) {
        this._data = statsData;
    }

    /**
     * Данные сгруппированные по стране
     * @private
     */
    private _dataByCountry: StatsLogByCountry[] = []; // Хранение данных

    public get dataByCountry() {
        return this._dataByCountry; // Возвращаем внутреннее свойство
    }

    public set dataByCountry(country: StatsLogByCountry[]) {
        this._dataByCountry = country; // Обновляем внутреннее свойство
    }

    /**
     * Дата начала отбора статистики
     * @private
     */
    private _startDate: Date = new Date('2024');

    public get startDate() {
        return this._startDate;
    }

    public set startDate(startDate: Date) {
        this._startDate = startDate;
    }

    /**
     * Конечная дата отбора статистики
     * @private
     */
    private _endDate: Date = new Date();

    public get endDate() {
        return this._endDate;
    }

    public set endDate(endDate: Date) {
        this._endDate = endDate;
    }

    /**
     * Конечная дата отбора статистики
     * @private
     */
    private _id: string = '';

    public get id() {
        return this._id;
    }

    public set id(userId: string) {
        this._id = userId;
    }
}
