import React from "react";
import {Layout} from "../../../containers/Layout";
import {QueueStateChart} from "../../../components/QueueStateChart";

/**
 * Страница мониторинга очереди в системе
 * @constructor
 */
const QueueState: React.FC = () => {
    return (
        <Layout pageTittle={"Queue State"}>
            <QueueStateChart />
        </Layout>
    )
}

/**
 * Имя отображаемое при отладке
 */
QueueState.displayName = "QueueStatePage";

export default QueueState
