import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    "filterContainer": {
        display: "grid",
        margin: "0.25em",
        rowGap: "0.5em",
        columnGap: "0.5em",
        maxWidth: '100%',
    },
    "dateTimeItemContainer": {
        display: "grid",
        gridTemplateColumns: "0.5fr 0.5fr",
        columnGap: "0.5em"
    },
    "switchesContainer": {
        display: "flex",
        flexDirection: "column",
        gridColumnStart: 3,
        gridColumnEnd: 3,
        gridRowStart: 1,
        gridRowEnd: 1,
    },
    "logRangeSelectContainer": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "0.3em"
    },
    "tagsSelectorsContainer": {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 1,
    },
    "controls": {
        gridColumnStart: 3,
        gridColumnEnd: 3,
        gridRowStart: 2,
        gridRowEnd: 2,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    }
});