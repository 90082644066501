import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {BASE_URL, STATISTIC_PUBLIC_SHARED_URL} from "../shared/constUrl";
import {StatisticsQuerySharedDto} from "../models/common/statistic/StatsQuerySharedDto";
import {StatisticDataDto, StatsLogByCountry} from "../models/common";

export class StatisticSharedService {

    /**
     * Экземпляр Axios
     */
    private readonly axios: AxiosInstance;

    /**
     * Конструктор сервиса axios
     */
    constructor() {
        const config: AxiosRequestConfig = {baseURL: BASE_URL}; // Используем baseURL
        this.axios = axios.create(config);
    }

    async getSharedStatsData(id: string, body: StatisticsQuerySharedDto): Promise<StatisticDataDto> {
        try {
            const resp = await this.axios.get(STATISTIC_PUBLIC_SHARED_URL + `/${id}`, {
                params: body,
            });
            return Promise.resolve(resp.data.data as StatisticDataDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async getSharedAllCountries(id: string, body: StatisticsQuerySharedDto): Promise<StatsLogByCountry[]> {
        try {
            const resp = await this.axios.get(STATISTIC_PUBLIC_SHARED_URL + `/${id}/get-all-countries`, {
                params: body,
            });
            return Promise.resolve(resp.data.data as StatsLogByCountry[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async getSharedTopCountries(id: string, body: StatisticsQuerySharedDto): Promise<StatsLogByCountry[]> {
        try {
            const resp = await this.axios.get(STATISTIC_PUBLIC_SHARED_URL + `/${id}/get-top-countries`, {
                params: body,
            });
            return Promise.resolve(resp.data.data as StatsLogByCountry[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
