import React from "react";
import {Layout} from "../../../containers/Layout";
import {ErrorsList} from "../../../components/ErrorsList";

/**
 * Страница с ошибками
 * @constructor
 */
const ErrorsPage: React.FC = () => {
    return <Layout pageTittle={"Errors Page"} anotherBackground={true}>
        <ErrorsList></ErrorsList>
    </Layout>
}


/**
 * Имя отображаемое при отладке
 */
ErrorsPage.displayName = "ErrorsPage"
export default ErrorsPage