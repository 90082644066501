import {AxiosService} from "./axios";
import {LogsRequestDto} from "../models/common/logs/LogRequestDto";

/**
 * Сервис для создания запросов на генерацию файлов для скачивания
 */
export class FileDownloadService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     *   Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    async checkFileStatus(requestId: string): Promise<string> {
        try {
            const requestStatus = await this.axios.get<string>(`/api/file/status/${requestId}`);
            return requestStatus.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить все файлы для одного лога
     */
    async requestLogFiles(logId: number): Promise<string> {
        try {
            const requestId = await this.axios.get<string>(`/api/file/bot?id=${logId}`);
            return requestId.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить все файлы для нескольких логов
     */
    async requestLogsFiles(logId: number[]): Promise<void> {
        try {
            await this.axios.post('api/file/multiple', logId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить все файлы для всех логов пользователя
     */
    async requestAllLogFiles(): Promise<void> {
        try {
            await this.axios.get('api/file/all');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить файлы логов согласно фильтру
     * @param filter
     */
    async requestLogByFilter(filter: LogsRequestDto): Promise<void> {
        try {
            const p = this.buildQueryString(filter);
            await this.axios.get(`/api/file/all?${p}`);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Скачивание файла
     * @param targetUrl адрес файла
     */
    async downloadFile(targetUrl: string): Promise<void> {
        try {
            await this.axios.downloadFile(targetUrl);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    buildQueryString(params: LogsRequestDto): string {
        const queryParams = new URLSearchParams();
        Object.keys(params).forEach((key) => {
            const value = (params as any)[key];
            if (value !== undefined && value !== null) {
                if (value instanceof Date) {
                    // Преобразуем объект Date в строку формата ISO 8601
                    queryParams.append(key, value.toISOString());
                } else {
                    queryParams.append(key, value.toString());
                }
            }
        });
        return queryParams.toString();
    }
}