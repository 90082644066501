import {Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle} from "@fluentui/react-components";
import React from "react";
import {ActionDialogProps} from "./ActionDialogProps";

/**
 * Компонент для отображения вариантов действия или запроса подтверждения на действия
 * @param props
 * @constructor
 */
const ActionDialog: React.FC<ActionDialogProps> = (props) => {
    return (
        <Dialog open={props.isOpen} onOpenChange={(e, data) => {
            if (!data.open && props.onClose) {
                props.onClose();
            }
        }}>
            <DialogSurface>
                <DialogBody>
                    {props.title && <DialogTitle as={"h4"}>{props.title}</DialogTitle>}
                    <DialogContent>
                        {props.content}
                    </DialogContent>
                    <DialogActions>
                        {props.actions}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

ActionDialog.displayName = "ActionDialog";
export default ActionDialog;
