import React, {useCallback, useEffect, useState} from 'react';
import {HealthStateProps} from './HealthStateProps';
import useStyles from "./HealthStateStyles";
import {useServices} from "../../hooks";
import {useToast} from "../../utils/ToastProvider";
import {Circle20Filled} from "@fluentui/react-icons";


const HealthState: React.FC<HealthStateProps> = ({brokerId}) => {
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [error, setError] = useState<string | null>(null);
    const styles = useStyles();
    const {dataBrokerService} = useServices();
    const {showToast} = useToast();

    // Функция для получения даты по brokerId
    const fetchBrokerDate = useCallback(async (brokerId: number): Promise<Date> => {
        try {
            const response: string = await dataBrokerService.getLastHealthCheck(brokerId);
            return new Date(response);
        } catch (e: any) {
            showToast("Error!", e.message, "error");
            throw e; // Позволяет обработать ошибку в updateDate или другом месте вызова
        }
    }, [dataBrokerService, showToast])

    // Функция для обновления даты
    const updateDate = useCallback(async () => {
        try {
            if (brokerId <= 0) {
                setLastUpdate(null);
                return;
            }

            const date = await fetchBrokerDate(brokerId);
            setLastUpdate(date);
            setError(null);
        } catch (err: any) {
            setError("Can't load data");
            setLastUpdate(null); // Сбрасываем дату при ошибке
        }
    }, [brokerId, fetchBrokerDate]);

    useEffect(() => {
        updateDate();
        const intervalId = setInterval(updateDate, 60 * 1000);
        return () => clearInterval(intervalId);
    }, [updateDate]);

    const getColor = (): string => {
        if (error || !lastUpdate) {
            return styles.gray;
        }

        const now = new Date();
        const diffMs = now.getTime() - lastUpdate.getTime();
        const diffMinutes = diffMs / (1000 * 60);

        if (diffMinutes <= 15) {
            return styles.green;
        } else if (diffMinutes > 15 && diffMinutes <= 60) {
            return styles.yellow;
        } else if (diffMinutes > 60 && diffMinutes <= 720) { // 12 часов = 720 минут
            return styles.red;
        } else {
            return styles.gray;
        }
    };

    return (
        <div className={styles.container}>
            <div title={lastUpdate ? `Last update: ${lastUpdate}` : 'No data'}>
                <Circle20Filled className={getColor()}/>
            </div>
            {error && <span className={styles.errorText}>{error}</span>}
        </div>
    );
};

export default HealthState;
