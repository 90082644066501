import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    dataCardContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "1em"
    },
    tabs: {
        padding: "0"
    }
});
