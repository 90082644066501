import React, {useEffect, useState} from "react";
import {Layout} from "../../../containers/Layout";
import InfoButton from "../../../components/InfoButton/InfoButton";
import {
    ADMIN_DATA_BROKER_URL,
    ADMIN_DATA_ERRORS_URL,
    ADMIN_DICTIONARIES_URL,
    ADMIN_FEATURES_URL, ADMIN_QUEUE_STATE,
    ADMIN_USERS_URL
} from "../../../shared/constUrl";
import {useServices, useStore} from "../../../hooks";
import {AdminDashboardCountersDto} from "../../../models/admin";
import {useToast} from "../../../utils/ToastProvider";

const defaultValues: AdminDashboardCountersDto = {
    stealerErrors: 0,
    brokersCount: 0,
    userCount: 0,
    botCount: 0,
    errorCount: 0
}

/**
 * Страница административного Дашборда
 * @constructor
 */
const Dashboard: React.FC = () => {
    const service = useServices();
    const store = useStore();
    const [counters, setCounters] = useState<AdminDashboardCountersDto>(defaultValues);
    const [dataBrokerTitle, setDataBrokerTitle] = useState("Data brokers");
    const [botTitle, setBotTitle] = useState("Logs");
    const [errorsTitle, setErrorsTitle] = useState("Errors");
    const [usersTitle, setUsersTitle] = useState("Users");
    const [stealerErrorTitle, setStealerErrorTitle] = useState("Stealer error");
    const {showToast} = useToast();

    useEffect(() => {
        const fetchCounters = async () => {
            try {
                store.loaderStore.setLoading(true);
                const counters = await service.adminDashboardService.getDashboardCounters();
                setCounters(counters);
            } catch (error) {
                showToast('Error', 'Failed to fetch dashboard counters' + error, 'error')
            }
        };
        fetchCounters();
    }, [service, store.loaderStore, showToast, botTitle]);

    useEffect(() => {
        if (dataBrokerTitle && counters.brokersCount > 0) {
            setDataBrokerTitle(`Data brokers (${counters.brokersCount})`)
        }
    }, [dataBrokerTitle, counters.brokersCount]);

    useEffect(() => {
        if (botTitle && counters.botCount > 0) {
            setBotTitle(`Logs (${counters.botCount})`)
        }
    }, [botTitle, counters.botCount]);

    useEffect(() => {
        if (botTitle && counters.errorCount > 0) {
            setErrorsTitle(`Errors (${counters.errorCount})`)
        }
    }, [errorsTitle, counters.errorCount, botTitle]);

    useEffect(() => {
        if (botTitle && counters.userCount > 0) {
            setUsersTitle(`Users (${counters.userCount})`)
        }
    }, [errorsTitle, counters.userCount, botTitle]);

    useEffect(() => {
        if (errorsTitle && counters.stealerErrors > 0) {
            setStealerErrorTitle(`Stealer Errors (${counters.stealerErrors})`)
        }
    }, [errorsTitle, counters.stealerErrors]);

    return (<Layout pageTittle="Dashboard" anotherBackground={true}>
        <div style={{display: "flex", flexDirection: "column", gap: '2em'}}>
            <InfoButton title={'Queue state'} target={ADMIN_QUEUE_STATE}/>
            <InfoButton title={dataBrokerTitle} target={ADMIN_DATA_BROKER_URL}/>
            <InfoButton title={usersTitle} target={ADMIN_USERS_URL}/>
            <InfoButton title={'Dictionaries'} target={ADMIN_DICTIONARIES_URL}/>
            <InfoButton title={botTitle} target={""}/>
            <InfoButton title={errorsTitle} target={ADMIN_DATA_ERRORS_URL}/>
            <InfoButton title={stealerErrorTitle} target={""}/>
            <InfoButton title={"Features"} target={ADMIN_FEATURES_URL}/>
        </div>
    </Layout>);
}

/**
 * Имя отображаемое при отладке
 */
Dashboard.displayName = "Dashboard";

export default Dashboard;
