import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableSelectionCell
} from "@fluentui/react-components";
import {observer} from "mobx-react-lite";
import useStyles from "./LogsListStyle";
import React, {useCallback, useEffect, useRef} from "react";
import Log from "../../models/common/logs/Log";
import {useServices, useStore} from "../../hooks";
import {GetMessageByCode} from "../../utils";
import {ApiError} from "../../models";
import NoItems from "../NoItems/NoItems";
import Flag from "react-flagkit";
import {LogSummary} from "../LogSummary";
import {LogDownloadButton} from "./LogDownloadButton";
import {LogOpenDetailsButton} from "../LogOpenDetailsButton";
import {LogNoteButton} from "./LogNoteButton";
import {LogDeleteButton} from "./LogDeleteButton";
import {useToast} from "../../utils/ToastProvider";

/**
 * Компонент отображения списка логов
 * @constructor
 */
const LogsList: React.FC = () => {
    const {showToast} = useToast();
    const styles = useStyles();
    const services = useServices();
    const store = useStore();
    const selectAllRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (selectAllRef.current) {
            selectAllRef.current.indeterminate =
                store.logsStore.selectedLogs.length > 0 &&
                store.logsStore.selectedLogs.length < store.logsStore.logs.length;
        }
    }, [store.logsStore.selectedLogs.length, store.logsStore.logs.length]);

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    /**
     * Загрузка опций для фильтрации
     */
    useEffect(() => {
        (async () => {
            try {
                store.logsStore.options = await services.logsService.getOptions();
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
            }
        })();
    }, [store.logsStore, services.logsService, store.loaderStore, showErrorNotify]);

    /**
     * Эффект для перовоначальной загрузки логов, измении параметров пагинации
     */
    useEffect(() => {
        (async () => {
            try {
                store.loaderStore.setLoading(true);
                await store.logsStore.fetchData()
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.logsStore.params.pageSize,
        store.logsStore.params.pageNumber,
        showErrorNotify]);

    /**
     * Выбор стиля в зависимости от характеристик лога
     * Например: дубликат, скачан или нет
     * @param item
     */
    const chooseStyle = useCallback((item: Log) => {
        if (item.isDuplicate && item.isDownloaded) {
            return styles.downloadedDuplicate;
        }

        if (item.isDownloaded && !item.isDuplicate) {
            return styles.downloaded;
        }

        return styles.normalRow;
    }, [styles]);

    /**
     * Обработчик для выбора/снятия выделения строки
     * @param item Log
     */
    const handleRowSelect = useCallback((item: Log) => {
        // Получаем текущие выбранные логи
        const prevSelectedIds = store.logsStore.selectedLogs;

        // Обновляем список выбранных логов
        store.logsStore.selectedLogs = prevSelectedIds.includes(item.id)
            ? prevSelectedIds.filter((id: number) => id !== item.id)
            : [...prevSelectedIds, item.id];
    }, [store.logsStore]);

    /**
     * Обработчик для выбора/снятия выделения всех строк
     */
    const handleSelectAll = useCallback(() => {
        if (store.logsStore.selectedLogs.length === store.logsStore.logs.length) {
            // Снять выделение со всех
            store.logsStore.selectedLogs = [];
        } else {
            // Выделить все
            store.logsStore.selectedLogs = store.logsStore.logs.map((log) => log.id);
        }
    }, [store.logsStore]);

    return (
        <Card style={{width: '100%', padding: "0.3em"}}>
            <Table size={"small"}>
                <TableHeader>
                    <TableRow>
                        <TableSelectionCell
                            checkboxIndicator={{"aria-label": "Select all rows"}}
                            checked={store.logsStore.selectedLogs.length === store.logsStore.logs.length}
                            onClick={handleSelectAll} // Обработчик для выбора всех строк
                        />

                        {columns.map((column) => (
                            <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {(store.logsStore.paging.totalItems === 0)
                        ? <TableRow>
                            <TableCell colSpan={columns.length + 1}>
                                <NoItems/>
                            </TableCell>
                        </TableRow>
                        : store.logsStore.logs.map((item) => (
                            <TableRow className={chooseStyle(item)} key={item.id}>
                                <TableSelectionCell
                                    checkboxIndicator={{"aria-label": "Select row"}}
                                    checked={store.logsStore.selectedLogs.includes(item.id)} // Проверка, выбран ли этот лог
                                    onClick={() => handleRowSelect(item)} // Обработчик выбора строки
                                />

                                <TableCell>
                                    <TableCellLayout>
                                        {item.id}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {item.ip}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout media={<Flag country={item.country} size={20}/>}>
                                        {item.country}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {item.city}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {item.zipCode}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        <LogSummary walletCount={item.walletCount}
                                                    isDuplicate={item.isDuplicate}
                                                    extWalletCount={item.extWalletCount}
                                                    cookiesCount={item.cookiesCount}
                                                    passwordsCount={item.passwordsCount}
                                                    creditCardsCount={item.creditCardsCount}
                                                    pmExtCount={item.pmExtCount} messengersCount={item.messengersCount}
                                                    softCount={item.softCount} tags={item.tags}/>
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {item.builderTag}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {item.createdOnUtc}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        <LogDownloadButton logId={item.id}/>
                                        <LogOpenDetailsButton logId={item.id}/>
                                        <LogNoteButton logId={item.id} noteId={item.noteId}
                                                       noteValue={item.noteText}/>
                                        <LogDeleteButton logId={item.id}/>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Card>
    );
};

const columns = [
    {columnKey: "id", label: "Id"},
    {columnKey: "ip", label: "IP"},
    {columnKey: "country", label: "Country"},
    {columnKey: "city", label: "City"},
    {columnKey: "zipCode", label: "ZipCode"},
    {columnKey: "summary", label: "Summary"},
    {columnKey: "buildTag", label: "Build Tag"},
    {columnKey: "created", label: "Created"},
    {columnKey: "actions", label: "Actions"},
];

/**
 * Имя отображаемое при отладке
 */
LogsList.displayName = 'LogsList';

export default observer(LogsList);
