import {ApiResult} from "../models";
import {TagCreateVm, TagFilteringDto, TagType, TagVm} from "../models/common/tags";
import {AxiosService} from "./axios";

export class TagsService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * получить все теги
     */
    async getTagsInfo(): Promise<ApiResult<TagVm>> {
        try {
            const resp = await this.axios.get<TagVm>("/tags/show");
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить все теги по фильтрам
     */
    async getTagsFilterInfo(filter: TagFilteringDto): Promise<ApiResult<TagVm>> {
        try {
            const resp = await this.axios.post<TagVm>("/tags/list/filter",filter);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * создать тег
     */
    async createTags(create: TagCreateVm): Promise<ApiResult<any>> {
        try {
            const resp = await this.axios.post("/tags/create",create);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удалить тег по ид
     */
    async deleteTag(id: string): Promise<void> {
        try {
            await this.axios.post("/tags/delete/" + id);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удалить несколько тегов
     */
    async deleteManyTags(filter: TagFilteringDto): Promise<void> {
        try {
            await this.axios.post("/tags/delete-many");
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * загрузить теги из файла
     */
    async uploadTagsFromFile(file: FormData ,type: TagType): Promise<void> {
        try {
            await this.axios.uploadFile("/tags/upload-import-file/" + type, file);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}