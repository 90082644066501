import {AxiosService} from "./axios";
import {BUILD_PROFILES_URL} from "../shared/constUrl";
import {JsonProfile} from "../models/common";

export class JsonProfilesService {

    /**
     * Axios сервис
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Запрос профиля пользователя
     */
    async loadProfile(): Promise<JsonProfile> {
        try {
            const response = await this.axios.get<JsonProfile>(BUILD_PROFILES_URL);
            return Promise.resolve(response.data as JsonProfile);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async saveProfile(profile: JsonProfile): Promise<void> {
        try {
            await this.axios.post(`${BUILD_PROFILES_URL}/update`, profile);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}