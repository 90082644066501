import React, {useEffect, useState} from "react";
import {useServices} from "../../hooks";
import {observer} from "mobx-react-lite";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import {DataErrorsViewDto} from "../../models/admin";
import {LoadingButton} from "../ButtonWithLoadingState";
import {ArrowDownload20Regular, BinRecycle20Regular, Replay20Regular} from "@fluentui/react-icons";
import {DataErrorTypeNames} from "../../models/admin/dataErrors/dataErrorTypes";
import {useToast} from "../../utils/ToastProvider";
import {ADMIN_DATA_ERRORS_URL} from "../../shared/constUrl";

/**
 * Список ошибок
 * @constructor
 */
const ErrorsList: React.FC = () => {
    const service = useServices();
    const [errors, setErrors] = useState<DataErrorsViewDto>({
        errors: [],
        paging: {totalPages: 0, pageNumber: 1, pageSize: 50, totalItems: 0}
    });
    const {showToast} = useToast();

    /**
     * Загрузка списка ошибок
     */
    useEffect(() => {
        try {
            service.adminDataErrorService.getList().then((data) => {
                setErrors(data);
            });
        } catch (error: any) {
            showToast("Error", "Problem during retrieving error list.", "error");
        }
    }, [service.adminDataErrorService, showToast]);

    const reloadToQueue = async (errorId: number) => {
        try {
            await service.adminDataErrorService.reloadToQueue(errorId);
            showToast("Done!", "Item reloaded to queue", "success");
        } catch (error: any) {
            showToast("Error", "Something went wrong...", "error");
        }
    }

    /**
     * Удаление ошибки
     * @param errorId
     */
    const deleteItem = async (errorId: number) => {
        try {
            await service.adminDataErrorService.deleteItem(errorId);

            setErrors({
                ...errors,
                errors: errors?.errors.filter(x => x.errorId !== errorId)
            });
            showToast("Done!", "Error files deleted successfully", "success");
        } catch (error: any) {
            showToast("Error", "Something went wrong...", "error");
        }
    }

    const downloadItem = async (errorId: number) => {
        try {
            const urlWithParams = `${ADMIN_DATA_ERRORS_URL}/download/${errorId}`;
            await service.fileDownloadService.downloadFile(urlWithParams);
        } catch (error: any) {
            showToast("Error", "Something went wrong...", "error");
        }
    }

    return <Card style={{width: '100%'}}>
        <Table as={"table"}>
            <TableHeader as={"thead"}>
                <TableRow as={"tr"}>
                    <TableHeaderCell as={"th"} style={{width: "5%"}}>Id</TableHeaderCell>
                    <TableHeaderCell as={"th"} style={{width: "8%"}}>Type</TableHeaderCell>
                    <TableHeaderCell as={"th"} style={{width: "10%"}}>IP</TableHeaderCell>
                    <TableHeaderCell as={"th"} style={{
                        minWidth: "20%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                    }}>Details</TableHeaderCell>
                    <TableHeaderCell as={"th"} style={{
                        minWidth: "20%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                    }}>Path to file</TableHeaderCell>
                    <TableHeaderCell as={"th"} style={{width: "12%"}}>Occurred at</TableHeaderCell>
                    <TableHeaderCell as={"th"} style={{width: "10%"}}>Actions</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {errors?.errors.map((item) => (
                    <TableRow as={"tr"} key={item.errorId}>
                        <TableCell as={"td"} style={{width: "5%"}}>{item.errorId}</TableCell>
                        <TableCell as={"td"}
                                   style={{width: "8%"}}> {DataErrorTypeNames[item.type] || "Unknown"}</TableCell>
                        <TableCell as={"td"} style={{width: "10%"}}>{item.ip}</TableCell>
                        <TableCell as={"td"} style={{
                            minWidth: "20%",
                            maxWidth: "30%",
                            whiteSpace: "wrap",
                            overflow: "auto",
                            textOverflow: "ellipsis"
                        }}>{item.details}</TableCell>
                        <TableCell as={"td"} style={{
                            minWidth: "20%",
                            maxWidth: "30%",
                            whiteSpace: "wrap",
                            overflow: "auto",
                            textOverflow: "initial",
                            scrollbarWidth: "thin"
                        }}>{item.pathToFile}</TableCell>
                        <TableCell as={"td"}
                                   style={{width: "12%"}}>{item.createdAt}</TableCell>
                        <TableCell as={"td"} style={{width: "10%"}}>
                            <div style={{display: "flex", gap: "5px"}}>
                                <LoadingButton enabled={true}
                                               onClick={async () => {
                                                   await downloadItem(item.errorId)
                                               }}
                                               appearance={"primary"} icon={<ArrowDownload20Regular/>}/>
                                <LoadingButton enabled={true}
                                               icon={<Replay20Regular/>}
                                               onClick={() => reloadToQueue(item.errorId)}
                                />
                                <LoadingButton enabled={true}
                                               style={{
                                                   background: tokens.colorPaletteDarkOrangeBackground1
                                               }}
                                               onClick={() => deleteItem(item.errorId)}
                                               icon={<BinRecycle20Regular/>}/>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Card>
}

/**
 * Имя отображаемое при отладке
 */
ErrorsList.displayName = "ErrorsList";
export default observer(ErrorsList);