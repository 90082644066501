import {SubscriptionInfoDto} from "../models/common";
import {ApiResult} from "../models";
import {AxiosService} from "./axios";

export class SubscriptionService {

    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить информацию о подписке пользователя
     */
    async GetSubscriptionInfo(): Promise<ApiResult<SubscriptionInfoDto>> {
        try {
            const response = await this.axios.get<SubscriptionInfoDto>("/account/subscription-info");
            return Promise.resolve(response);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}