import {AxiosService} from "./axios";
import {LogPageDto} from "../models/common/logs/LogPageDto";
import {LogsRequestDto} from "../models/common/logs/LogRequestDto";
import {LogFilteringOptions} from "../models/common/logs/LogFilteringOptions";

/**
 * Сервис работы с логами
 */
export class LogsService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список логов пользователя
     * @param options Опции для фильтрации. Если пусто отберёт данные по умолчанию.
     */
    async getLogsList(options?: LogsRequestDto): Promise<LogPageDto> {
        try {
            const response = await this.axios.post<LogPageDto>("/log/list", options);
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Перенести лог в корзину
     * @param logId Идентификатор лога
     */
    async moveToBin(logId: number): Promise<void> {
        try {
            await this.axios.delete(`/log/delete/${logId}`);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить пустые логи
     */
    async deleteEmpty(): Promise<void> {
        try {
            await this.axios.post('/log/delete-empty');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async getOptions(): Promise<LogFilteringOptions> {
        try {
            const response = await this.axios.get('/log/filtering-options')
            return response.data as LogFilteringOptions;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Множественное удаление логов по id
     * @param ids Массив из ID логов для удаления
     */
    async deleteMany(ids: number[]): Promise<void> {
        try {
            await this.axios.post('/log/delete-many/', ids);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
