import React from "react";
import {Layout} from "../../../containers/Layout";
import useStyle from "./TagsPageStyle";
import {TagsForm} from "../../../forms/TagsForm";


/**
 * Компонент-страница "Теги"
 * @constructor
 */
const TagsPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Tags"} anotherBackground={true}>
        <div className={styles.TagsForm}>
            <TagsForm></TagsForm>
        </div>
    </Layout>);
}

TagsPage.displayName = "TagsPage";
export default TagsPage;