import React from "react";
import {Card, CardFooter, Field, Input, Label, Select,} from "@fluentui/react-components";
import {LoadingButton} from "../../components/ButtonWithLoadingState";
import {LoadingState} from "../../models";
import {useServices} from "../../hooks";
import {GetMessageByCode} from "../../utils";
import {UserRole} from "../../models/common";
import {useToast} from "../../utils/ToastProvider";

/**
 * Форма создания пользователя
 * @constructor
 */
const CreateUserForm: React.FC = () => {
    const services = useServices();
    const [loading, setLoading] = React.useState<LoadingState>("initial");
    const [newUsername, setUsername] = React.useState("");
    const [newPassword, setPassword] = React.useState("");
    const [newRole, setRole] = React.useState<UserRole>(UserRole.PartnerRole);
    const {showToast} = useToast();

    /**
     * Обработчик изменения имени пользователя
     * @param _ev
     * @param data
     */
    const usernameChangeHandler = (_ev: any, data: any) => {
        setUsername(data.value);
    };

    /**
     * Обработчик выбора роли пользователя
     * @param event
     */
    const handleSelectChange = (event: any) => {
        setRole(event.target.value);
    };

    /**
     * Обработчик кнопки создать пользователя
     */
    const submitHandler = async () => {
        try {
            setLoading("loading");
            const response = await services.adminUserService.create({
                username: newUsername,
                password: newPassword,
                role: newRole
            });
            showToast('Done', 'New user has been created. ID:' + {response}, 'success');
            setLoading("loaded");
        } catch (error: any) {
            setLoading("error");
            showToast('Error!', GetMessageByCode(error.code), 'error');
        }
    };

    return (
        <Card style={{maxWidth: "50%"}}>
            <div>
                <Label>Username</Label>
                <Field>
                    <Input type={"text"} onChange={usernameChangeHandler}></Input>
                </Field>
            </div>
            <div>
                <Label>Password</Label>
                <Field>
                    <Input onChange={(_ev, data) => {
                        setPassword(data.value);
                    }} type={"text"}></Input>
                </Field>
            </div>

            <div>
                <Label>Role</Label>
                <Field>
                    <Select value={newRole} onChange={handleSelectChange}>
                        <option value={UserRole.PartnerRole}>Traffer</option>
                        <option value={UserRole.UserRole}>User</option>
                    </Select>
                </Field>
            </div>
            <CardFooter>
                <LoadingButton appearance={"primary"} buttonText={"Create"} enabled={true} state={loading}
                               onClick={() => submitHandler()}/>
            </CardFooter>
        </Card>
    );
}

/**
 * Имя отображаемое при отладке
 */
CreateUserForm.displayName = "CreateUserForm";

export default CreateUserForm;