import {AxiosService} from "./axios";
import {AppLogsDto} from "../models/admin";

/**
 * Сервис для работы с логами приложения
 */
export class AppLogService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Загрузка списка файлов с логами
     */
    async load(): Promise<AppLogsDto[]> {
        try {
            const response = await this.axios.get<AppLogsDto[]>("admin/logs");
            return Promise.resolve(response.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}