import React from "react";
import {Card} from "@fluentui/react-components";
import {InfoButtonProps} from "./InfoButtonProps";
import {useNavigate} from "react-router-dom";

const InfoButton: React.FC<InfoButtonProps> = (props) => {
    const navigate = useNavigate();

    const onCardClick = () => {
        if (props.target) {
            navigate(props.target);
        }
    }

    return (
        <Card onClick={onCardClick} size={'large'} style={{"minWidth": "150px"}}>
            <div>{props.title}</div>
        </Card>);
}


InfoButton.displayName = "InfoButton";

export default InfoButton;